// src/TopNavBar.js

import React, { useState, useEffect, useContext } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Box,
  useMediaQuery,
  useTheme,
  InputBase,
  Badge,
  CircularProgress,
  Typography,
  Slide,
  BottomNavigation,
  BottomNavigationAction,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Menu,
  MenuItem,
  InputAdornment, // Import InputAdornment
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import ShopIcon from '@mui/icons-material/Shop';
import ListAltIcon from '@mui/icons-material/ListAlt';
import InventoryIcon from '@mui/icons-material/Inventory';
import ListIcon from '@mui/icons-material/List';
import LogoutIcon from '@mui/icons-material/Logout';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoImage from './assets/FF-Logo-Icon-NT-White-NB.webp';
import SmallLogoImage from './assets/FF-Cart-Icon-White-NB.webp';
import { CartContext } from './CartContext';

// Transition for Drawer
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

// Styled Components for Search Bar
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius * 5,
  border: `1px solid ${alpha('#FFFFFF', 0.5)}`,
  backgroundColor: alpha('#FFFFFF', 0.15),
  '&:hover': {
    backgroundColor: alpha('#FFFFFF', 0.25),
  },
  width: '100%',
  maxWidth: 500,
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  flexGrow: 1,
  boxSizing: 'border-box',
  zIndex: 1,
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  color: '#FFFFFF',
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  pointerEvents: 'none',
  zIndex: 2
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: '#FFFFFF',
  fontFamily: theme.typography.fontFamily,
  width: '100%',
  position: 'relative',
  zIndex: 1,
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: theme.spacing(6),
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  '& .MuiInputAdornment-root': {
    position: 'absolute',
    right: theme.spacing(1),
    height: '100%',
    maxHeight: 'none',
    zIndex: 2
  },
  // Make sure the entire input area is clickable
  '& .MuiInputBase-root': {
    width: '100%',
    height: '100%'
  }
}));

function TopNavBar({ onSearch, initialSearchTerm = '' }) {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchDrawerOpen, setSearchDrawerOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState(initialSearchTerm);
  const [bottomNavValue, setBottomNavValue] = useState('home');
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);

  // New State for Lists Menu
  const [listsMenuAnchorEl, setListsMenuAnchorEl] = useState(null);
  const isListsMenuOpen = Boolean(listsMenuAnchorEl);

  // Access CartContext
  const { cartItems, loading: cartLoading, error: cartError } = useContext(CartContext);

  // Update searchQuery when initialSearchTerm changes
  useEffect(() => {
    setSearchQuery(initialSearchTerm);
  }, [initialSearchTerm]);

  // Sync Bottom Navigation value with current route
  useEffect(() => {
    const path = location.pathname.split('/')[1] || 'home';
    const validPaths = ['home', 'shop', 'inventory', 'orders', 'lists', 'purchase-history', 'guides'];
    if (validPaths.includes(path)) {
      setBottomNavValue(path);
    } else {
      setBottomNavValue('home');
    }
  }, [location.pathname]);

  // Determine if the current page is the Home page
  const isHomePage = location.pathname === '/home' || location.pathname === '/';

  // Toggle Side Drawer for Desktop Navigation
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  // Toggle Search Drawer for Mobile
  const handleSearchDrawerOpen = () => {
    setSearchDrawerOpen(true);
  };

  const handleSearchDrawerClose = () => {
    setSearchDrawerOpen(false);
  };

  // Handle Logout (Confirm)
  const handleLogout = () => {
    // Clear user data from localStorage
    localStorage.removeItem('user');
    // Navigate to login page
    navigate('/login');
  };

  // Calculate cartAmount and itemCount from CartContext
  const cartAmount = cartItems.reduce(
    (acc, item) => acc + (item.total_price || 0) + (item.sales_tax_added || 0),
    0
  );
  const itemCount = cartItems.reduce((acc, item) => acc + (item.quantity || 0), 0);

  // Handle Search Input Change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Handle Search Submission
  const handleSearchSubmit = (event) => {
    if (event.type === 'click' || event.key === 'Enter') {
      event.preventDefault();
      if (searchQuery.trim()) {
        if (onSearch) {
          onSearch(searchQuery);
        } else {
          navigate(`/shop?query=${encodeURIComponent(searchQuery)}`);
        }
        setSearchQuery('');
        setSearchDrawerOpen(false);
      }
    }
  };

  // Handle Clear Search
  const handleClearSearch = () => {
    setSearchQuery('');
    navigate('/shop'); // Navigate to '/shop' without search params
  };

  // Navigation Items
  const navItems = [
    { label: 'Home', path: 'home', icon: <HomeIcon /> },
    { label: 'Shop', path: 'shop', icon: <ShopIcon /> },
    { label: 'Inventory', path: 'inventory', icon: <InventoryIcon /> },
    { label: 'Orders', path: 'orders', icon: <ListAltIcon /> },
  ];

  // Handle Bottom Navigation Change (Mobile)
  const handleBottomNavChange = (event, newValue) => {
    if (newValue !== 'lists') {
      setBottomNavValue(newValue);
      navigate(`/${newValue}`);
    }
    // If newValue is 'lists', the onClick handler will manage the menu
  };

  // Open/Close Lists Menu
  const handleListsMenuOpen = (event) => {
    setListsMenuAnchorEl(event.currentTarget);
  };

  const handleListsMenuClose = () => {
    setListsMenuAnchorEl(null);
  };

  // Open Logout Confirmation Dialog (Mobile)
  const openLogoutDialog = () => {
    setLogoutDialogOpen(true);
  };

  // Close Logout Confirmation Dialog (Mobile)
  const closeLogoutDialog = () => {
    setLogoutDialogOpen(false);
  };

  // Handle navigation to Settings page
  const handleSettingsClick = () => {
    navigate('/settings');
  };

  return (
    <>
      {/* AppBar */}
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          backgroundColor: '#0E9C9B',
          boxShadow: 'none !important',
          zIndex: theme.zIndex.drawer + 1,
        }}
      >
        {/* Primary Toolbar */}
        <Toolbar>
          {/* Conditionally render Back Button on Mobile, only if not on Home page */}
          {isMobile && !isHomePage && (
            <IconButton
              color="inherit"
              aria-label="go back"
              onClick={() => navigate(-1)}
              sx={{
                color: '#FFFFFF',
                marginRight: theme.spacing(1),
              }}
            >
              <ArrowBackIcon />
            </IconButton>
          )}

          {/* Logo */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              zIndex: 2,
            }}
            onClick={() => navigate('/home')}
          >
            <img
              src={isMobile ? SmallLogoImage : LogoImage}
              alt="FareFood Logo"
              style={{ height: '30px' }}
            />
          </Box>

          {/* Mobile View: Search Icon on Right */}
          {isMobile && (
            <IconButton
              color="inherit"
              aria-label="open search"
              onClick={handleSearchDrawerOpen}
              sx={{ marginLeft: 1, color: '#FFFFFF' }}
            >
              <SearchIcon />
            </IconButton>
          )}

          {/* Desktop View: Search Bar */}
          {!isMobile && (
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search Shop..."
                inputProps={{ 'aria-label': 'search' }}
                value={searchQuery}
                onChange={handleSearchChange}
                onKeyPress={handleSearchSubmit}
                endAdornment={
                  searchQuery && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClearSearch}
                        aria-label="clear search"
                        edge="end"
                        size="small"
                        sx={{ color: '#FFFFFF' }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              />
            </Search>
          )}

          {/* Spacer to push Cart and Settings to the right */}
          <Box sx={{ flexGrow: 1 }} />

          {/* Cart and Settings Area */}
          <Box sx={{ display: 'flex', alignItems: 'center', zIndex: 2 }}>
            {/* Cart Area */}
            <Box
              onClick={() => navigate('/cart')}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                cursor: 'pointer',
                padding: theme.spacing(1.5, 2),
                backgroundColor: 'transparent',
                transition: 'background-color 0.3s, border-radius 0.3s',
                borderRadius: '100px',
                '&:hover': {
                  backgroundColor: alpha('#FFFFFF', 0.1),
                },
              }}
            >
              {/* Cart Icon with Inverted Badge */}
              <Box sx={{ position: 'relative', marginBottom: theme.spacing(0.2) }}>
                <IconButton
                  aria-label="cart"
                  sx={{
                    color: '#FFFFFF',
                    borderRadius: '50%',
                    padding: theme.spacing(0.5),
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                  }}
                >
                  <Badge
                    badgeContent={
                      cartLoading ? (
                        <CircularProgress size={10} color="inherit" />
                      ) : (
                        itemCount
                      )
                    }
                    overlap="circular"
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    sx={{
                      '& .MuiBadge-badge': {
                        backgroundColor: '#F87906',
                        color: '#FFFFFF',
                        fontWeight: 'bold',
                        minWidth: '20px',
                        height: '20px',
                        padding: '0 6px',
                      },
                    }}
                  >
                    <ShoppingCartIcon />
                  </Badge>
                </IconButton>
                {cartError && (
                  <Typography
                    variant="caption"
                    color="error"
                    sx={{
                      position: 'absolute',
                      top: '100%',
                      left: '50%',
                      transform: 'translateX(-50%)',
                    }}
                  >
                    !
                  </Typography>
                )}
              </Box>

              {/* Cart Amount */}
              <Typography
                variant="body2"
                sx={{
                  color: '#FFFFFF',
                  fontFamily: theme.typography.fontFamily,
                  fontSize: theme.typography.body2.fontSize,
                  fontWeight: theme.typography.body2.fontWeight,
                  cursor: 'pointer',
                }}
              >
                ${cartAmount.toFixed(2)}
              </Typography>
            </Box>

            {/* Settings Button */}
            <IconButton
              color="inherit"
              aria-label="settings"
              onClick={handleSettingsClick}
              sx={{
                marginLeft: 1,
                color: '#FFFFFF',
              }}
            >
              <SettingsIcon />
            </IconButton>

            {/* Mobile View: Logout Icon */}
            {/* You can add a Logout icon here if needed */}
          </Box>
        </Toolbar>

        {/* Secondary Navigation Links: Visible only on Desktop */}
        {!isMobile && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: '#068986',
              paddingY: theme.spacing(0.5),
              borderTop: `1px solid ${alpha('#FFFFFF', 0.3)}`,
              borderBottom: `1px solid ${alpha('#FFFFFF', 0.3)}`,
            }}
          >
            {/* Navigation Items on the Left */}
            <Box sx={{ display: 'flex' }}>
              {navItems.map((item) => (
                <Button
                  key={item.label}
                  startIcon={item.icon}
                  onClick={() => navigate(`/${item.path}`)}
                  sx={{
                    color:
                      location.pathname === `/${item.path}`
                        ? '#FFFFFF'
                        : alpha('#FFFFFF', 0.7),
                    fontFamily: theme.typography.fontFamily,
                    fontSize: theme.typography.body2.fontSize,
                    fontWeight: theme.typography.button.fontWeight,
                    textTransform: 'none',
                    marginLeft: theme.spacing(1),
                    paddingY: theme.spacing(0.5),
                    '&:hover': {
                      backgroundColor: alpha('#FFFFFF', 0.2),
                      color: '#FFFFFF',
                    },
                  }}
                >
                  {item.label}
                </Button>
              ))}

              {/* Lists Dropdown Menu */}
              <Button
                startIcon={<ListIcon />}
                onClick={handleListsMenuOpen} // Corrected handler name
                sx={{
                  color:
                    ['/purchase-history', '/guides', '/lists'].includes(location.pathname)
                      ? '#FFFFFF'
                      : alpha('#FFFFFF', 0.7),
                  fontFamily: theme.typography.fontFamily,
                  fontSize: theme.typography.body2.fontSize,
                  fontWeight: theme.typography.button.fontWeight,
                  textTransform: 'none',
                  marginLeft: theme.spacing(1),
                  paddingY: theme.spacing(0.5),
                  '&:hover': {
                    backgroundColor: alpha('#FFFFFF', 0.2),
                    color: '#FFFFFF',
                  },
                }}
              >
                Lists
              </Button>
              <Menu
                anchorEl={listsMenuAnchorEl}
                open={isListsMenuOpen}
                onClose={handleListsMenuClose}
                MenuListProps={{
                  'aria-labelledby': 'lists-button',
                }}
                PaperProps={{
                  style: {
                    backgroundColor: '#068986',
                    color: '#FFFFFF',
                  },
                }}
              >
                <MenuItem
                  onClick={() => {
                    navigate('/purchase-history');
                    setBottomNavValue('purchase-history');
                    handleListsMenuClose();
                  }}
                  sx={{
                    '&:hover': {
                      backgroundColor: alpha('#FFFFFF', 0.1),
                    },
                  }}
                >
                  Purchase History
                </MenuItem>
                
                <MenuItem
                  onClick={() => {
                    navigate('/lists');
                    setBottomNavValue('lists');
                    handleListsMenuClose();
                  }}
                  sx={{
                    '&:hover': {
                      backgroundColor: alpha('#FFFFFF', 0.1),
                    },
                  }}
                >
                  Lists
                </MenuItem>
              </Menu>
            </Box>

            {/* Logout Button: Visible only on Desktop */}
            {/* You can add a Logout button here if needed */}
          </Box>
        )}
      </AppBar>

      {/* Spacer to prevent content from being hidden under the fixed AppBar */}
      <Toolbar />

      {/* Side Drawer: Visible only on Desktop */}
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          '& .MuiDrawer-paper': {
            width: 240,
            boxSizing: 'border-box',
            backgroundColor: '#E9C9B9',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            paddingBottom: theme.spacing(2),
          },
        }}
      >
        {/* Navigation Items */}
        <Box>
          {/* Optional: Logo inside Drawer */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: theme.spacing(2),
              justifyContent: 'center',
            }}
          >
            <img
              src={LogoImage}
              alt="FareFood Logo"
              style={{ height: '30px', cursor: 'pointer' }}
              onClick={() => {
                navigate('/home');
                handleDrawerToggle();
              }}
            />
          </Box>
          <Divider sx={{ backgroundColor: alpha('#FFFFFF', 0.3) }} />
          <List>
            {navItems.map((item) => (
              <ListItem
                button
                key={item.label}
                onClick={() => {
                  navigate(`/${item.path}`);
                  handleDrawerToggle();
                }}
                selected={location.pathname === `/${item.path}`}
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: alpha('#FFFFFF', 0.2),
                    color: '#FFFFFF',
                    '& .MuiListItemIcon-root': {
                      color: '#FFFFFF',
                    },
                  },
                  '& .MuiListItemText-primary': {
                    fontFamily: theme.typography.fontFamily,
                    fontSize: theme.typography.body2.fontSize,
                    fontWeight: theme.typography.button.fontWeight,
                    color: '#FFFFFF',
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    color:
                      location.pathname === `/${item.path}`
                        ? '#FFFFFF'
                        : alpha('#FFFFFF', 0.7),
                    minWidth: '40px',
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItem>
            ))}

            {/* Lists with Sub-items */}
            <ListItem
              button
              onClick={() => {
                navigate('/purchase-history');
                handleDrawerToggle();
              }}
              selected={location.pathname === '/purchase-history'}
              sx={{
                '&.Mui-selected': {
                  backgroundColor: alpha('#FFFFFF', 0.2),
                  color: '#FFFFFF',
                  '& .MuiListItemIcon-root': {
                    color: '#FFFFFF',
                  },
                },
                '& .MuiListItemText-primary': {
                  fontFamily: theme.typography.fontFamily,
                  fontSize: theme.typography.body2.fontSize,
                  fontWeight: theme.typography.button.fontWeight,
                  color: '#FFFFFF',
                },
              }}
            >
              <ListItemIcon
                sx={{
                  color:
                    location.pathname === '/purchase-history'
                      ? '#FFFFFF'
                      : alpha('#FFFFFF', 0.7),
                  minWidth: '40px',
                }}
              >
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary="Purchase History" />
            </ListItem>

            <ListItem
              button
              onClick={() => {
                navigate('/guides');
                handleDrawerToggle();
              }}
              selected={location.pathname === '/guides'}
              sx={{
                '&.Mui-selected': {
                  backgroundColor: alpha('#FFFFFF', 0.2),
                  color: '#FFFFFF',
                  '& .MuiListItemIcon-root': {
                    color: '#FFFFFF',
                  },
                },
                '& .MuiListItemText-primary': {
                  fontFamily: theme.typography.fontFamily,
                  fontSize: theme.typography.body2.fontSize,
                  fontWeight: theme.typography.button.fontWeight,
                  color: '#FFFFFF',
                },
              }}
            >
              <ListItemIcon
                sx={{
                  color:
                    location.pathname === '/guides'
                      ? '#FFFFFF'
                      : alpha('#FFFFFF', 0.7),
                  minWidth: '40px',
                }}
              >
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary="Guides" />
            </ListItem>

            <ListItem
              button
              onClick={() => {
                navigate('/lists');
                handleDrawerToggle();
              }}
              selected={location.pathname === '/lists'}
              sx={{
                '&.Mui-selected': {
                  backgroundColor: alpha('#FFFFFF', 0.2),
                  color: '#FFFFFF',
                  '& .MuiListItemIcon-root': {
                    color: '#FFFFFF',
                  },
                },
                '& .MuiListItemText-primary': {
                  fontFamily: theme.typography.fontFamily,
                  fontSize: theme.typography.body2.fontSize,
                  fontWeight: theme.typography.button.fontWeight,
                  color: '#FFFFFF',
                },
              }}
            >
              <ListItemIcon
                sx={{
                  color:
                    location.pathname === '/lists'
                      ? '#FFFFFF'
                      : alpha('#FFFFFF', 0.7),
                  minWidth: '40px',
                }}
              >
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary="Lists" />
            </ListItem>
          </List>
        </Box>

        {/* Logout Button: Positioned at the bottom of the Drawer */}
        <Box sx={{ padding: theme.spacing(2) }}>
          <Divider sx={{ backgroundColor: alpha('#FFFFFF', 0.3) }} />
          <ListItem
            button
            onClick={handleLogout}
            sx={{
              marginTop: theme.spacing(1),
              '& .MuiListItemText-primary': {
                fontFamily: theme.typography.fontFamily,
                fontSize: theme.typography.body2.fontSize,
                fontWeight: theme.typography.button.fontWeight,
                color: '#FFFFFF',
              },
            }}
          >
            <ListItemIcon sx={{ minWidth: '40px', color: '#FFFFFF' }}>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </Box>
      </Drawer>

      {/* Mobile View: Bottom Navigation */}
      {isMobile && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: theme.zIndex.appBar + 1,
            borderTop: `1px solid ${alpha('#FFFFFF', 0.3)}`,
            backgroundColor: '#068986',
          }}
        >
          <BottomNavigation
            value={bottomNavValue}
            onChange={handleBottomNavChange}
            showLabels
            sx={{
              backgroundColor: 'transparent',
            }}
          >
            {navItems.map((item) => (
              <BottomNavigationAction
                key={item.label}
                label={item.label}
                value={item.path}
                icon={React.cloneElement(item.icon, { style: { color: '#FFFFFF' } })}
                sx={{
                  '& .MuiBottomNavigationAction-label': {
                    color:
                      bottomNavValue === item.path
                        ? '#FFFFFF'
                        : alpha('#FFFFFF', 0.7),
                  },
                }}
              />
            ))}

            {/* Custom Lists Button */}
            <BottomNavigationAction
              label="Lists"
              value="lists"
              icon={<ListIcon style={{ color: '#FFFFFF' }} />}
              onClick={handleListsMenuOpen} // Corrected handler
              sx={{
                '& .MuiBottomNavigationAction-label': {
                  color:
                    ['lists', 'purchase-history', 'guides'].includes(bottomNavValue)
                      ? '#FFFFFF'
                      : alpha('#FFFFFF', 0.7),
                },
              }}
            />
          </BottomNavigation>

          {/* Lists Menu */}
          <Menu
            anchorEl={listsMenuAnchorEl}
            open={isListsMenuOpen}
            onClose={handleListsMenuClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            keepMounted
            PaperProps={{
              style: {
                backgroundColor: '#068986',
                color: '#FFFFFF',
              },
            }}
          >
            <MenuItem
              onClick={() => {
                navigate('/lists');
                setBottomNavValue('lists');
                handleListsMenuClose();
              }}
              sx={{
                '&:hover': {
                  backgroundColor: alpha('#FFFFFF', 0.1),
                },
              }}
            >
              Lists
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate('/purchase-history');
                setBottomNavValue('purchase-history');
                handleListsMenuClose();
              }}
              sx={{
                '&:hover': {
                  backgroundColor: alpha('#FFFFFF', 0.1),
                },
              }}
            >
              Purchase History
            </MenuItem>
            
          </Menu>
        </Box>
      )}

      {/* Mobile View: Search Drawer */}
      <Drawer
        anchor="top"
        open={searchDrawerOpen}
        onClose={handleSearchDrawerClose}
        TransitionComponent={Transition}
        sx={{
          '& .MuiDrawer-paper': {
            height: 'auto',
            maxHeight: '50%',
            padding: theme.spacing(2),
            boxSizing: 'border-box',
            backgroundColor: '#068986',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(2),
          }}
        >
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, fontFamily: theme.typography.fontFamily, color: '#FFFFFF' }}
          >
            Search
          </Typography>
          <IconButton aria-label="close search" onClick={handleSearchDrawerClose} sx={{ color: '#FFFFFF' }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Search sx={{ width: '100%', maxWidth: '100%', margin: '0' }}>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search Product Catalog..."
            inputProps={{ 'aria-label': 'search' }}
            value={searchQuery}
            onChange={handleSearchChange}
            onKeyPress={handleSearchSubmit}
            endAdornment={
              searchQuery && (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClearSearch}
                    aria-label="clear search"
                    edge="end"
                    size="small"
                    sx={{ color: '#FFFFFF' }}
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              )
            }
          />
        </Search>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: theme.spacing(2) }}>
          <Button
            onClick={handleSearchDrawerClose}
            color="inherit"
            sx={{ fontFamily: theme.typography.fontFamily, color: '#FFFFFF' }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSearchSubmit}
            color="inherit"
            disabled={!searchQuery.trim()}
            sx={{ fontFamily: theme.typography.fontFamily, color: '#FFFFFF' }}
          >
            Search
          </Button>
        </Box>
      </Drawer>

      {/* Mobile View: Logout Confirmation Dialog */}
      <Dialog
        open={logoutDialogOpen}
        onClose={closeLogoutDialog}
        aria-labelledby="logout-dialog-title"
        aria-describedby="logout-dialog-description"
      >
        <DialogTitle id="logout-dialog-title">Confirm Logout</DialogTitle>
        <DialogContent>
          <DialogContentText id="logout-dialog-description">
            Are you sure you want to log out?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeLogoutDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleLogout} color="primary" autoFocus>
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default TopNavBar;
