// src/components/ProductListItem.js

import React from 'react';
import {
  Box,
  Typography,
  IconButton,
  Tooltip,
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  WarningAmber as WarningAmberIcon,
} from '@mui/icons-material';
import { useTheme, alpha } from '@mui/material/styles';
import AddToCartButton from './AddToCartButton';
import { useNavigate } from 'react-router-dom';

function ProductListItem({ product, multiplier, canExpand = true, onExpand }) {
  const theme = useTheme();
  const navigate = useNavigate();

  // State to manage expansion per product
  const isExpanded = product.isExpanded;

  // Function to handle expand/collapse
  const handleExpandClick = (e) => {
    e.stopPropagation(); // Prevent row's onClick
    if (onExpand) {
      onExpand(product._product.id);
    }
  };

  // Decide whether to show the best deal banner
  const showBestDealBanner = product.isBestDeal;
  const showAlternateBanner = product.isAlternate && !showBestDealBanner;

  // Use hasAlternates from product
  const hasAlternateProducts = product.hasAlternates;

  // Function to calculate Price Per Unit
  const priceData = calculatePricePerUnit(product, multiplier);
  const purchasePrice = Number(product.purchase_price) * multiplier;
  const isPriceTooLow = priceData && priceData.unitPriceValue < 0.04;
  const hasPrice = purchasePrice > 0;

  // Function to calculate Price Per Unit
  function calculatePricePerUnit(product, multiplier) {
    const { purchase_price, _product } = product || {};
    const { product_volume, priced_by } = _product || {};
    const adjusted_purchase_price = purchase_price * multiplier;

    if (
      adjusted_purchase_price &&
      product_volume > 0 &&
      priced_by &&
      !isNaN(adjusted_purchase_price)
    ) {
      const pricePerUnitValue = adjusted_purchase_price / product_volume;
      return {
        pricePerUnit: `$${pricePerUnitValue.toFixed(2)}/${priced_by}`,
        unitPriceValue: pricePerUnitValue,
      };
    } else {
      return null;
    }
  }

  // Apply border style when expanded or when the product is an alternate
  const rowBorderStyle =
    isExpanded || product.isAlternate
      ? `2px solid ${theme.palette.grey[500]}`
      : 'none';

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: 1,
          borderBottom: `1px solid ${theme.palette.divider}`,
          backgroundColor: showBestDealBanner
            ? alpha(theme.palette.success.light, 0.2)
            : product.isAlternate
            ? theme.palette.grey[200]
            : 'transparent',
          '&:hover': {
            backgroundColor: alpha(theme.palette.primary.light, 0.1),
          },
          position: 'relative',
          overflow: 'hidden', // To contain the banner within bounds
          border: rowBorderStyle, // Apply the border conditionally
        }}
      >
        {/* Expand More Icon */}
        {canExpand && hasAlternateProducts && (
          <IconButton
            onClick={handleExpandClick}
            size="small"
            sx={{ marginRight: theme.spacing(1) }}
          >
            <ExpandMoreIcon
              sx={{
                transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: theme.transitions.create('transform', {
                  duration: theme.transitions.duration.shortest,
                }),
              }}
            />
          </IconButton>
        )}

        {/* Image */}
        <Box
          sx={{
            width: '10%',
            textAlign: 'center',
            position: 'relative',
          }}
        >
          {product._product?.Image_URL && (
            <Box
              component="img"
              src={product._product.Image_URL}
              alt={product._product.short_description}
              sx={{
                width: 60,
                height: 60,
                objectFit: 'cover',
                borderRadius: 1,
              }}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = '/path/to/default/image.png'; // Update with actual default image path
              }}
            />
          )}
          {/* Best Deal Ribbon */}
          {showBestDealBanner && (
            <Box
              sx={{
                backgroundColor: '#F87906',
                display: 'inline-flex',
                alignItems: 'center',
                padding: '2px 8px',
                borderRadius: '4px',
                marginBottom: '4px',
                position: 'absolute',
                top: 0,
                left: 0,
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  color: 'white',
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  fontSize: '0.75rem',
                }}
              >
                Best Deal
              </Typography>
            </Box>
          )}
          {/* Alternate Banner */}
          {showAlternateBanner && (
            <Box
              sx={{
                backgroundColor: theme.palette.primary.main, // FareFood teal
                display: 'inline-flex',
                alignItems: 'center',
                padding: '2px 8px',
                borderRadius: '4px',
                marginBottom: '4px',
                position: 'absolute',
                top: 0,
                left: 0,
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  color: 'white',
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  fontSize: '0.75rem',
                }}
              >
                Alternate
              </Typography>
            </Box>
          )}
        </Box>

        {/* Product Info */}
        <Box
          sx={{ width: '40%', paddingLeft: theme.spacing(1) }}
          onClick={() => navigate(`/product/${product._product?.id}`)}
        >
          <Typography
            variant="body2"
            sx={{
              fontWeight: 'normal',
              color: 'black',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            {product._product?.short_description || ''}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {product._product?.brand} | {product._product?.FF_Catalog_Number}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {product._product?.size}
          </Typography>
          {/* Lead Time */}
          {product._product?.Lead_Time > 0 && (
            <Typography
              variant="body2"
              sx={{
                color: theme.palette.warning.main,
                fontWeight: 'bold',
              }}
            >
              {`${product._product.Lead_Time} days Lead Time`}
            </Typography>
          )}
          {/* Special Notes */}
          {product._product?.special_notes &&
            !/no/i.test(product._product.special_notes) && (
              <Typography
                variant="body2"
                sx={{
                  color: theme.palette.text.secondary,
                  fontSize: '0.8rem',
                }}
              >
                {product._product.special_notes}
              </Typography>
            )}
        </Box>

        {/* Add to Cart Button */}
        <Box
          sx={{ width: '15%', textAlign: 'center' }}
          onClick={(e) => e.stopPropagation()} // Prevent row click
        >
          {hasPrice ? (
            <AddToCartButton
              product={product._product}
              multiplier={multiplier}
              restaurantPrice={product.purchase_price}
            />
          ) : null}
        </Box>

        {/* Price per Case */}
        <Box sx={{ width: '15%', textAlign: 'center' }}>
          {hasPrice ? (
            <Typography
              variant="body1"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              ${purchasePrice.toFixed(2)}
              {isPriceTooLow && (
                <Tooltip
                  title="This price seems unusually low and might be per unit rather than per case. The final price will be confirmed before purchase."
                  placement="top"
                >
                  <WarningAmberIcon
                    sx={{
                      color: theme.palette.warning.main,
                      marginLeft: theme.spacing(0.5),
                    }}
                  />
                </Tooltip>
              )}
            </Typography>
          ) : (
            <Typography variant="body1">Contact Us</Typography>
          )}
        </Box>

        {/* Price per Unit */}
        <Box sx={{ width: '20%', textAlign: 'center' }}>
          {priceData && hasPrice ? (
            <Typography variant="body2">{priceData.pricePerUnit}</Typography>
          ) : (
            <Typography variant="body2">N/A</Typography>
          )}
        </Box>
      </Box>
    </>
  );
}

export default ProductListItem;
