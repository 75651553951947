import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Card, 
  CardContent, 
  Typography, 
  Button, 
  Box,
  useTheme,
  IconButton
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import DescriptionIcon from '@mui/icons-material/Description';
import StoreIcon from '@mui/icons-material/Store';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';

const GettingStartedGuide = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const userData = localStorage.getItem('user');
    if (userData) {
      const user = JSON.parse(userData);
      setShowBanner(user.showonboardbanner || false);
    }
  }, []);

  const handleDismiss = async () => {
    try {
      const userData = localStorage.getItem('user');
      if (!userData) return;

      const user = JSON.parse(userData);
      const userId = user.id;

      const response = await axios.patch(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/dismissonboard',
        { userid: userId }
      );

      if (response.status === 200) {
        // Update local storage
        const updatedUser = { ...user, showonboardbanner: false };
        localStorage.setItem('user', JSON.stringify(updatedUser));
        setShowBanner(false);
        
        // Refresh the page
        window.location.reload();
      }
    } catch (error) {
      console.error('Error dismissing onboarding banner:', error);
    }
  };

  const steps = [
    {
      label: "Connect Your Accounts",
      description: "Head to Settings to connect your distributor accounts (US Foods, Sysco, etc.). We need your purchase history to ensure we pull the correct items and pricing for your business.",
      icon: <SettingsIcon sx={{ width: 20, height: 20 }} />,
      action: "Go to Settings"
    },
    {
      label: "Submit Tax Certificate",
      description: "Please provide your resale tax certificate in Settings. This is required to process your orders with accurate tax calculations.",
      icon: <DescriptionIcon sx={{ width: 20, height: 20 }} />,
      action: "Upload Certificate"
    },
    {
      label: "Shop Setup in Progress",
      description: "Once you have completed the above steps, we will begin populating your shop with your specific items and pricing. This typically takes 1-2 business days.",
      icon: <StoreIcon sx={{ width: 20, height: 20 }} />,
      action: null
    }
  ];

  const handleNavigateToSettings = () => {
    navigate('/settings');
  };

  if (!showBanner) {
    return null;
  }

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.background.paper,
        boxShadow: 0,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(2),
        minHeight: '300px',
        border: '1px solid #ccc',
      }}
    >
      <CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
          <Typography
            variant="h5"
            component="div"
            sx={{
              fontWeight: theme.typography.h5.fontWeight,
              textTransform: 'capitalize',
            }}
          >
            Getting Started
          </Typography>
          <IconButton 
            onClick={handleDismiss}
            size="small"
            sx={{ 
              color: 'text.secondary',
              '&:hover': {
                color: 'text.primary',
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        
        <Box sx={{ mt: 3 }}>
          {steps.map((step, index) => (
            <Box key={index} sx={{ mb: 3, '&:last-child': { mb: 0 } }}>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Box 
                  sx={{ 
                    width: 40, 
                    height: 40, 
                    borderRadius: '50%', 
                    bgcolor: 'primary.light', 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center',
                    color: 'primary.main',
                    flexShrink: 0
                  }}
                >
                  {step.icon}
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 500, mb: 0.5 }}>
                    {step.label}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 1.5 }}>
                    {step.description}
                  </Typography>
                  {step.action && (
                    <Button
                      variant="outlined"
                      onClick={handleNavigateToSettings}
                      endIcon={<ArrowRightIcon />}
                      size="small"
                    >
                      {step.action}
                    </Button>
                  )}
                </Box>
              </Box>
              {index < steps.length - 1 && (
                <Box 
                  sx={{ 
                    ml: 2.5, 
                    pl: 2.5, 
                    borderLeft: '2px solid',
                    borderColor: 'divider',
                    height: 24 
                  }} 
                />
              )}
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

export default GettingStartedGuide;