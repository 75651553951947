// src/Cart.js

import React, { useContext, useState, useEffect } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  Divider,
  Grid,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputBase,
  useMediaQuery,
  useTheme,
  styled,
  alpha,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { Search as SearchIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import TopNavBar from './TopNavBar';
import { CartContext } from './CartContext';
import axios from 'axios';
import MOQTracker from './MOQTracker';
import { Helmet } from 'react-helmet';
import CartItem from './CartItem';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius * 5,
  border: `1px solid ${theme.palette.primary.main}`,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.25),
  },
  width: '100%',
  maxWidth: 400,
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(0),
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  pointerEvents: 'none',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontFamily: theme.typography.fontFamily,
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

// Helper function to pad strings to a fixed width
function padString(str, length) {
  if (str.length > length) {
    return str.substring(0, length - 3) + '...';
  } else {
    return str.padEnd(length, ' ');
  }
}

// Helper function to format a table with given rows and column widths
function formatTable(rows, columnWidths, headers) {
  let table = '';
  const separatorLine = columnWidths.map((width) => '-'.repeat(width)).join('-+-') + '\n';

  // Header row
  const headerRow = headers
    .map((header, index) => padString(header, columnWidths[index]))
    .join(' | ');
  table += separatorLine;
  table += headerRow + '\n';
  table += separatorLine;

  // Data rows
  rows.forEach((row) => {
    const dataRow = row.map((cell, index) => padString(cell, columnWidths[index])).join(' | ');
    table += dataRow + '\n';
  });

  table += separatorLine;
  return table;
}

function Cart() {
  const {
    cartItems,
    loading,
    fetchCartItems,
    handleDeleteItem,
    updateItemQuantity,
  } = useContext(CartContext);
  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredItems, setFilteredItems] = useState(cartItems);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const [purchaseComplete, setPurchaseComplete] = useState(false);
  const [checkoutError, setCheckoutError] = useState(null);

  const [multiplier, setMultiplier] = useState(1);

  const [tier, setTier] = useState(null);
  const [restaurantName, setRestaurantName] = useState('Unknown Restaurant');
  const [restaurantLocation, setRestaurantLocation] = useState('Unknown Location');
  const [restaurantId, setRestaurantId] = useState(null);
  const [vendors, setVendors] = useState([]);

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const [expandedCategories, setExpandedCategories] = useState({});

  const handleAccordionChange = (categoryName) => (event, isExpanded) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [categoryName]: isExpanded,
    }));
  };

  // Fetch multiplier, Tier, and Restaurant Name on component mount
  useEffect(() => {
    const fetchUserData = async () => {
      const userData = localStorage.getItem('user');
      if (userData) {
        try {
          const parsedUser = JSON.parse(userData);
          const storedMultiplier = parseFloat(parsedUser.multiplier) || 1;
          setMultiplier(storedMultiplier);

          const restId = parsedUser.restaurant_id;
          setRestaurantId(restId);

          if (restId) {
            try {
              const response = await axios.get(
                'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/restaurant',
                {
                  params: {
                    restaurantidfilter: restId,
                  },
                  headers: {
                    'Content-Type': 'application/json',
                  },
                }
              );

              if (Array.isArray(response.data) && response.data.length > 0) {
                const restaurantData = response.data[0];
                setTier(restaurantData.Tier);
                setRestaurantName(restaurantData.name || 'Unknown Restaurant');
                setRestaurantLocation(restaurantData.location || 'Unknown Location');
              } else {
                setTier(1);
                setRestaurantName('Unknown Restaurant');
                setRestaurantLocation('Unknown Location');
              }

              // Fetch Vendors
              fetchVendorEmails(restId);
            } catch (error) {
              console.error('Error fetching restaurant Tier and Name:', error);
              setTier(1);
              setRestaurantName('Unknown Restaurant');
              setRestaurantLocation('Unknown Location');
            }
          } else {
            console.error('Restaurant ID not found in user data.');
            setTier(1);
            setRestaurantName('Unknown Restaurant');
            setRestaurantLocation('Unknown Location');
          }
        } catch (e) {
          console.error('Error parsing user data from local storage:', e);
          setMultiplier(1);
          setTier(1);
          setRestaurantName('Unknown Restaurant');
          setRestaurantLocation('Unknown Location');
        }
      } else {
        console.error('User data not found in localStorage.');
        setMultiplier(1);
        setTier(1);
        setRestaurantName('Unknown Restaurant');
        setRestaurantLocation('Unknown Location');
      }
    };

    fetchUserData();
  }, []);

  const fetchVendorEmails = async (restaurantId) => {
    try {
      const response = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/restaurant_vendors',
        {
          params: { restaurant_id: restaurantId },
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (Array.isArray(response.data)) {
        setVendors(response.data);
      } else {
        console.error('Invalid data received for vendors.');
      }
    } catch (error) {
      console.error('Error fetching vendors:', error);
    }
  };

  useEffect(() => {
    // Client-side search filtering based on product name
    if (searchTerm.trim() === '') {
      setFilteredItems(cartItems);
    } else {
      const lowerSearch = searchTerm.toLowerCase();
      const filtered = cartItems.filter((item) =>
        item._product?.short_description.toLowerCase().includes(lowerSearch)
      );
      setFilteredItems(filtered);
    }
  }, [searchTerm, cartItems]);

  // Group the filteredItems by category name
  const groupedItems = React.useMemo(() => {
    return filteredItems.reduce((groups, item) => {
      const categoryName = item._product?._category?.name || 'Uncategorized';
      if (!groups[categoryName]) {
        groups[categoryName] = [];
      }
      groups[categoryName].push(item);
      return groups;
    }, {});
  }, [filteredItems]);

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  // Function to calculate totals
  const calculateTotals = () => {
    const totalQuantityCS = cartItems.reduce((acc, item) => acc + (item.quantity || 0), 0);
    const totalLineItems = cartItems.length;
    const estimatedItemCost = cartItems.reduce(
      (acc, item) => acc + (item.total_price || 0),
      0
    );
    const totalSalesTax = cartItems.reduce(
      (acc, item) => acc + (item.sales_tax_added || 0),
      0
    );
    const estimatedTotal = parseFloat((estimatedItemCost + totalSalesTax).toFixed(2));

    return {
      totalQuantityCS,
      totalLineItems,
      estimatedItemCost,
      totalSalesTax,
      estimatedTotal,
    };
  };

  const totals = calculateTotals();

  // Function to handle complete purchase
  const handleCompletePurchase = () => {
    setIsConfirmModalOpen(true);
  };

  const handleConfirmOrder = async () => {
    setIsConfirmModalOpen(false);

    try {
      // Get the user data from local storage
      const userData = localStorage.getItem('user');
      if (!userData) {
        setCheckoutError('User not found. Please log in.');
        return;
      }
      const parsedUser = JSON.parse(userData);
      const restId = parsedUser.restaurant_id;

      if (!vendors || vendors.length === 0) {
        setSnackbar({
          open: true,
          message: 'Vendor information not loaded. Please try again later.',
          severity: 'error',
        });
        return;
      }

      // Group cart items by vendor
      const itemsByVendor = {};
      cartItems.forEach((item) => {
        const vendorId = item._product?._vendor?.id;
        if (vendorId) {
          if (!itemsByVendor[vendorId]) {
            itemsByVendor[vendorId] = [];
          }
          itemsByVendor[vendorId].push(item);
        } else {
          console.error('Vendor ID not found for product', item._product);
        }
      });

      // Always CC Vincent and Michael
      const ccEmails = 'vincent@fare.food,michael@fare.food';

      // Send emails to vendors
      for (const vendorId in itemsByVendor) {
        const vendorItems = itemsByVendor[vendorId];

        // Calculate total cases for the vendor
        const totalVendorCases = vendorItems.reduce(
          (acc, item) => acc + (item.quantity || 0),
          0
        );

        // Find vendor email and name
        const vendor = vendors.find((v) => v.vendor_id === parseInt(vendorId));
        if (vendor) {
          const vendorEmail = vendor.vendor_email;
          const vendorName = vendor._vendor?.Short_Name || 'Vendor';

          // Prepare email subject
          const subjectHeader = `${restaurantName} has placed an order with ${vendorName}!`;

          // Prepare vendor email body
          let emailBody = `Dear ${vendorName},\n\n`;
          emailBody += `${restaurantName} has placed a new order totaling ${totalVendorCases} case(s) through FareFood.\n\n`;
          emailBody += `Restaurant Details:\n`;
          emailBody += `Name: ${restaurantName}\n`;
          emailBody += `Location: ${restaurantLocation}\n\n`;
          emailBody += 'Order Details:\n';

          // Prepare table data for vendor
          const tableRows = vendorItems.map((item) => {
            const catalogNumber =
              item._product?.catalog_number ||
              item._product?.FF_Catalog_Number ||
              'N/A';
            const shortDescription =
              item._product?.short_description || 'Product Description';
            const quantity = `${item.quantity} Case(s)`;
            return [catalogNumber, shortDescription, quantity];
          });

          const vendorColumnWidths = [17, 30, 12]; 
          const vendorHeaders = ['Catalog Number', 'Description', 'Quantity'];
          emailBody += formatTable(tableRows, vendorColumnWidths, vendorHeaders);

          // Include user's email in the contact line
          emailBody += `\nIf there are any issues with fulfilling this order or if any items are out of stock, please contact ${parsedUser.email}, michael@fare.food, vincent@fare.food, or suppliers@fare.food.\n\n`;
          emailBody += 'Thank you for partnering with FareFood!\n\n';
          emailBody += 'Best regards,\nThe FareFood Team';

          // Send email to vendor
          try {
            const emailResponse = await axios.post(
              'https://xawe-auye-zrgm.n7d.xano.io/api:E1YzybDk/order_notification',
              {
                admin_email: vendorEmail,
                subject_header: subjectHeader,
                email_body: emailBody,
                cc_email: ccEmails,
              },
              {
                headers: {
                  'Content-Type': 'application/json',
                },
              }
            );

            console.log('Email sent to', vendorEmail, 'Response:', emailResponse.data);
          } catch (error) {
            console.error('Error sending email to', vendorEmail, error);
            setSnackbar({
              open: true,
              message: `Failed to send email to ${vendorEmail}.`,
              severity: 'error',
            });
          }
        } else {
          console.error('Vendor not found for vendorId', vendorId);
        }
      }

      // Prepare internal notification for restaurants@fare.food
      // Include all items in a single table with Vendor, Catalog Number, Description, Quantity
      const allItemsTableRows = cartItems.map((item) => {
        const vendorId = item._product?._vendor?.id;
        const vendor = vendors.find((v) => v.vendor_id === parseInt(vendorId));
        const vendorName = vendor?._vendor?.Short_Name || 'Unknown Vendor';
        const catalogNumber =
          item._product?.catalog_number ||
          item._product?.FF_Catalog_Number ||
          'N/A';
        const shortDescription =
          item._product?.short_description || 'Product Description';
        const quantity = `${item.quantity} Case(s)`;
        return [vendorName, catalogNumber, shortDescription, quantity];
      });

      const internalColumnWidths = [20, 17, 30, 12];
      const internalHeaders = ['Vendor', 'Catalog Number', 'Description', 'Quantity'];
      const internalTable = formatTable(allItemsTableRows, internalColumnWidths, internalHeaders);

      const internalSubject = `New order placed by ${restaurantName}`;
      let internalBody = `A new order has been placed by ${restaurantName}.\n\n` +
        `User email: ${parsedUser.email}\n` +
        `Total Items: ${totals.totalLineItems}\n` +
        `Total Amount: $${totals.estimatedTotal.toFixed(2)}\n\n` +
        `Order Details:\n` +
        internalTable +
        `\nPlease review and take necessary actions.\n\n` +
        `Best regards,\nFareFood System`;

      try {
        await axios.post(
          'https://xawe-auye-zrgm.n7d.xano.io/api:E1YzybDk/order_notification',
          {
            admin_email: 'restaurants@fare.food',
            subject_header: internalSubject,
            email_body: internalBody,
            cc_email: '',
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        console.log('Internal notification email sent to restaurants@fare.food');
      } catch (error) {
        console.error('Error sending internal notification email:', error);
        setSnackbar({
          open: true,
          message: 'Failed to send internal notification email.',
          severity: 'error',
        });
      }

      // Send Slack notification
      const slackPayload = {
        text: `📦 *New Order*\n\n*Restaurant:* ${restaurantName} (ID: ${restaurantId})\n*User Email:* ${parsedUser.email}\n*Items:* ${totals.totalLineItems}\n*Total Amount:* $${totals.estimatedTotal.toFixed(2)}\n`
      };

      try {
        const slackResponse = await axios.post(
          'https://xawe-auye-zrgm.n7d.xano.io/api:XF3EVVma/slack_customers',
          slackPayload,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (slackResponse.status === 200) {
          console.log('Slack notification sent successfully.');
        } else {
          console.warn('Failed to send Slack notification.');
        }
      } catch (slackError) {
        console.error('Error sending Slack notification:', slackError);
      }

      // Complete the order
      const payload = {
        restaurantidfilter: restId,
        total_amount: totals.estimatedTotal,
        item_count: totals.totalQuantityCS,
      };

      const response = await axios.put(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/Complete_Order',
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data && response.status === 200) {
        // Order completed successfully
        setSnackbar({
          open: true,
          message: 'Order completed successfully.',
          severity: 'success',
        });
        // Clear the cart by fetching the updated cart items
        fetchCartItems();
      } else {
        setSnackbar({
          open: true,
          message: 'Failed to complete the order. Please try again.',
          severity: 'error',
        });
      }

      setPurchaseComplete(true);
    } catch (error) {
      console.error('Error completing order:', error);
      setSnackbar({
        open: true,
        message: 'Failed to complete order. Please try again later.',
        severity: 'error',
      });
    }
  };

  return (
    <div>
      <Helmet>
        <title>FareFood | Cart</title>
      </Helmet>
      <TopNavBar title="Cart" />

      <Box
        sx={{
          paddingTop: isMobile ? theme.spacing(7) : theme.spacing(8),
          paddingBottom: isMobile ? theme.spacing(8) : theme.spacing(2),
          px: isMobile ? theme.spacing(1) : theme.spacing(10), // Reduced padding on mobile
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          minHeight: '100vh',
          boxSizing: 'border-box',
          backgroundColor: 'white',
          overflowX: 'hidden', // Prevent horizontal overflow
        }}
      >
        <Grid
          container
          spacing={isMobile ? 0 : 4} // Removed spacing on mobile
          sx={{
            maxWidth: '1400px',
            width: '100%',
            justifyContent: isMobile ? 'center' : 'flex-start',
            margin: 0,
          }}
        >
          {/* Left Side - Review Cart */}
          <Grid item xs={12} md={8}>
            {/* Header with "Review Cart" */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: theme.spacing(1),
                marginTop: theme.spacing(4),
              }}
            >
              <Typography variant="h5" sx={{ fontFamily: theme.typography.fontFamily }}>
                Review Cart
              </Typography>
            </Box>

            {/* Search Bar */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                paddingBottom: theme.spacing(1),
                gap: theme.spacing(1),
                flexWrap: 'wrap',
                marginBottom: theme.spacing(2),
              }}
            >
              {/* Search Input */}
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search Cart…"
                  inputProps={{ 'aria-label': 'search' }}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </Search>
            </Box>

            {/* Cart Items */}
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: theme.spacing(2) }}>
                <CircularProgress />
              </Box>
            ) : Object.keys(groupedItems).length > 0 ? (
              Object.entries(groupedItems).map(([categoryName, items]) => (
                <Accordion
                  key={categoryName}
                  expanded={expandedCategories[categoryName] !== false}
                  onChange={handleAccordionChange(categoryName)}
                  sx={{
                    marginBottom: theme.spacing(2),
                    boxShadow: 'none',
                    border: 'none',
                    borderRadius: theme.shape.borderRadius,
                    '&:before': {
                      display: 'none',
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${categoryName}-content`}
                    id={`${categoryName}-header`}
                    sx={{
                      backgroundColor: 'transparent',
                      borderBottom: `1px solid #e0e0e0`,
                      '& .MuiAccordionSummary-content': {
                        margin: 0,
                      },
                      padding: theme.spacing(1, 0),
                    }}
                  >
                    {/* Category Header */}
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: theme.typography.fontFamily,
                        fontWeight: 'normal',
                      }}
                    >
                      {categoryName}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: 0 }}>
                    {/* Items in the Category */}
                    {items.map((item) => (
                      <CartItem
                        key={item.id}
                        item={item}
                        multiplier={multiplier}
                        onDelete={handleDeleteItem}
                        onUpdateQuantity={updateItemQuantity}
                      />
                    ))}
                  </AccordionDetails>
                </Accordion>
              ))
            ) : (
              <Typography
                variant="body1"
                sx={{
                  fontFamily: theme.typography.fontFamily,
                  textAlign: 'center',
                  marginTop: theme.spacing(2),
                }}
              >
                {searchTerm.trim() === ''
                  ? 'Your cart is empty.'
                  : 'No items match your search.'}
              </Typography>
            )}

            {/* MOQ Tracker for Mobile */}
            {isMobile && (
              <Box sx={{ marginTop: theme.spacing(2) }}>
                <MOQTracker />
              </Box>
            )}
          </Grid>

          {/* Right Side - Order Summary */}
          <Grid item xs={12} md={4}>
            {/* Order Summary Component */}
            <Box
              sx={{
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: theme.shape.borderRadius,
                padding: theme.spacing(2),
                backgroundColor: theme.palette.background.paper,
                marginTop: theme.spacing(4),
                position: 'relative',
                width: '100%',
                boxSizing: 'border-box',
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ fontFamily: theme.typography.fontFamily, marginBottom: theme.spacing(1) }}
              >
                Order Summary
              </Typography>

              <Divider sx={{ marginBottom: theme.spacing(1) }} />

              {/* Total Quantity */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: theme.spacing(0.5),
                }}
              >
                <Typography variant="body2" sx={{ fontFamily: theme.typography.fontFamily }}>
                  Total Quantity
                </Typography>
                <Typography variant="body2" sx={{ fontFamily: theme.typography.fontFamily }}>
                  {totals.totalQuantityCS} CS
                </Typography>
              </Box>

              {/* Total Line Items */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: theme.spacing(1),
                }}
              >
                <Typography variant="body2" sx={{ fontFamily: theme.typography.fontFamily }}>
                  Total Line Items
                </Typography>
                <Typography variant="body2" sx={{ fontFamily: theme.typography.fontFamily }}>
                  {totals.totalLineItems}
                </Typography>
              </Box>

              <Divider sx={{ marginBottom: theme.spacing(1) }} />

              {/* Cost Breakdown */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: theme.spacing(0.5),
                }}
              >
                <Typography variant="body2" sx={{ fontFamily: theme.typography.fontFamily }}>
                  Estimated Item Cost
                </Typography>
                <Typography variant="body2" sx={{ fontFamily: theme.typography.fontFamily }}>
                  ${totals.estimatedItemCost.toFixed(2)}
                </Typography>
              </Box>

              {/* Sales Tax */}
              {totals.totalSalesTax > 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: theme.spacing(1),
                  }}
                >
                  <Typography variant="body2" sx={{ fontFamily: theme.typography.fontFamily }}>
                    Sales Tax
                  </Typography>
                  <Typography variant="body2" sx={{ fontFamily: theme.typography.fontFamily }}>
                    ${totals.totalSalesTax.toFixed(2)}
                  </Typography>
                </Box>
              )}

              <Divider sx={{ marginBottom: theme.spacing(1) }} />

              {/* Estimated Total */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: theme.spacing(2),
                }}
              >
                <Typography variant="subtitle1" sx={{ fontFamily: theme.typography.fontFamily }}>
                  Estimated Total
                </Typography>
                <Typography variant="subtitle1" sx={{ fontFamily: theme.typography.fontFamily }}>
                  ${totals.estimatedTotal.toFixed(2)}
                </Typography>
              </Box>

              {/* Complete Purchase / Upgrade Button */}
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleCompletePurchase}
                disabled={tier === 1 || totals.totalQuantityCS === 0}
                sx={{
                  textTransform: 'none',
                  fontFamily: theme.typography.fontFamily,
                  borderRadius: theme.shape.borderRadius,
                  padding: theme.spacing(1),
                  position: 'relative',
                  zIndex: 1,
                  marginBottom: isMobile ? theme.spacing(8) : 0,
                  '&:hover': {
                    backgroundColor: theme.palette.primary.dark,
                  },
                }}
              >
                {tier === 1 ? 'Upgrade to Place Orders' : 'Complete Purchase'}
              </Button>
            </Box>

            {/* MOQ Tracker for Desktop */}
            {isDesktop && (
              <Box sx={{ marginTop: theme.spacing(2) }}>
                <MOQTracker />
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>

      {/* Snackbar for Notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%', fontFamily: theme.typography.fontFamily }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      {/* Purchase Completion Modal */}
      <Dialog open={purchaseComplete} onClose={() => setPurchaseComplete(false)}>
        <DialogTitle sx={{ fontFamily: theme.typography.fontFamily }}>
          Purchase Complete!
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ fontFamily: theme.typography.fontFamily }}>
            Your purchase has been successfully completed. You can view your orders or return to the
            home page.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setPurchaseComplete(false);
              navigate('/orders');
            }}
            color="primary"
            variant="outlined"
            sx={{
              fontFamily: theme.typography.fontFamily,
              borderColor: theme.palette.primary.main,
              color: theme.palette.primary.main,
              borderRadius: '25px',
              padding: theme.spacing(1, 2),
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              },
            }}
          >
            View Orders
          </Button>
          <Button
            onClick={() => {
              setPurchaseComplete(false);
              navigate('/home');
            }}
            color="primary"
            variant="contained"
            sx={{
              fontFamily: theme.typography.fontFamily,
              backgroundColor: theme.palette.primary.main,
              borderRadius: '25px',
              padding: theme.spacing(1, 2),
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
                boxShadow: theme.shadows[6],
              },
            }}
          >
            Go Home
          </Button>
        </DialogActions>
      </Dialog>

      {/* Display Checkout Error if any */}
      {checkoutError && (
        <Snackbar
          open={Boolean(checkoutError)}
          autoHideDuration={6000}
          onClose={() => setCheckoutError(null)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setCheckoutError(null)}
            severity="error"
            sx={{ width: '100%', fontFamily: theme.typography.fontFamily }}
          >
            {checkoutError}
          </Alert>
        </Snackbar>
      )}

      {/* Confirmation Modal */}
      <Dialog
        open={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ fontFamily: theme.typography.fontFamily, fontSize: '1.5rem' }}>
          Confirm Your Order
        </DialogTitle>
        <DialogContent dividers>
          <Box sx={{ padding: theme.spacing(2) }}>
            <Typography variant="h6" sx={{ marginBottom: theme.spacing(2) }}>
              Order Summary
            </Typography>
            <Divider sx={{ marginBottom: theme.spacing(2) }} />
            {/* Subtotal */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: theme.spacing(1),
              }}
            >
              <Typography variant="body1">Subtotal:</Typography>
              <Typography variant="body1">${totals.estimatedItemCost.toFixed(2)}</Typography>
            </Box>
            {/* Sales Tax */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: theme.spacing(1),
              }}
            >
              <Typography variant="body1">Sales Tax:</Typography>
              <Typography variant="body1">${totals.totalSalesTax.toFixed(2)}</Typography>
            </Box>
            <Divider sx={{ marginY: theme.spacing(2) }} />
            {/* Total */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: theme.spacing(1),
              }}
            >
              <Typography variant="h6">Total:</Typography>
              <Typography variant="h6">${totals.estimatedTotal.toFixed(2)}</Typography>
            </Box>
            {/* Disclaimer */}
            <Typography variant="body2" color="textSecondary" sx={{ marginTop: theme.spacing(2) }}>
              By confirming, you agree to the terms and conditions of your purchase.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsConfirmModalOpen(false)}
            color="primary"
            variant="outlined"
            sx={{
              textTransform: 'none',
              fontFamily: theme.typography.fontFamily,
              borderRadius: theme.shape.borderRadius,
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmOrder}
            color="primary"
            variant="contained"
            sx={{
              textTransform: 'none',
              fontFamily: theme.typography.fontFamily,
              borderRadius: theme.shape.borderRadius,
            }}
          >
            Confirm Order
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Cart;
