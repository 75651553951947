import React, { useState, useEffect, useCallback } from 'react';
import TopNavBar from './TopNavBar';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
  IconButton,
  Collapse,
  Box,
  Button,
  Divider,
  Grid,
  List as MUIList,
  ListItemButton,
  ListItemText,
  Drawer,
  InputBase,
  useMediaQuery,
  useTheme,
  Container,
  Tooltip,
  Card,
  CardContent,
  Pagination,
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import {
  Search as SearchIcon,
  FilterList as FilterListIcon,
  Close as CloseIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@mui/icons-material';
import axios from 'axios';
import moment from 'moment';
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Styled Components for Search Bar
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius * 5,
  border: `1px solid ${theme.palette.primary.main}`,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.25),
  },
  width: '100%',
  maxWidth: 500,
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(0),
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  pointerEvents: 'none',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontFamily: theme.typography.fontFamily,
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

function Returns() {
  const [returnsList, setReturnsList] = useState([]);
  const [filteredReturns, setFilteredReturns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('0'); // Default to 'All Returns'
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const apiEndpoint = 'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/help_tickets_2';

  // Define statusOptions for returns
  const statusOptions = [
    { label: 'All Returns', value: '0' },
    { label: 'Initiated', value: '1' },
    { label: 'In Progress', value: '2' },
    { label: 'Completed', value: '3' },
  ];

  // Pagination States
  const [totalResults, setTotalResults] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const returnsPerPage = 25; // Adjust as needed

  // Debounce the search term
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  // Filter returns based on the debounced search term
  useEffect(() => {
    if (debouncedSearchTerm) {
      const filtered = returnsList.filter((ticket) =>
        ticket.subject.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
      );
      setFilteredReturns(filtered);
    } else {
      setFilteredReturns(returnsList);
    }
  }, [debouncedSearchTerm, returnsList]);

  // Fetch Returns
  const fetchReturns = useCallback(
    async () => {
      setLoading(true);
      try {
        const authToken = localStorage.getItem('authToken');
        const user = JSON.parse(localStorage.getItem('user'));

        if (!authToken || !user) {
          console.error('Authentication token or user data not found in localStorage.');
          navigate('/');
          return;
        }

        const params = {
          useridfilter: user.id,
          tickettypefilter: 1, // Assuming '1' corresponds to Returns
          limit: returnsPerPage,
          offset: (currentPage - 1) * returnsPerPage,
          statusfilter: parseInt(selectedStatus),
        };

        const headers = { Authorization: `Bearer ${authToken}` };
        const response = await axios.get(apiEndpoint, { params, headers });

        const fetchedData = response.data;

        if (fetchedData && Array.isArray(fetchedData.items)) {
          setReturnsList(fetchedData.items);
          setTotalResults(fetchedData.itemsTotal || 0);
          setTotalPages(fetchedData.pageTotal || 0);
        } else {
          setReturnsList([]);
          setTotalResults(0);
          setTotalPages(0);
        }
      } catch (error) {
        console.error('Error fetching returns:', error);
        setReturnsList([]);
        setTotalResults(0);
        setTotalPages(0);
      } finally {
        setLoading(false);
      }
    },
    [navigate, apiEndpoint, selectedStatus, currentPage, returnsPerPage]
  );

  useEffect(() => {
    fetchReturns();
  }, [fetchReturns]);

  const toggleFilterDrawer = (open) => () => {
    setIsFilterDrawerOpen(open);
  };

  // Handle Page Change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <Helmet>
        <title>FareFood | Returns</title>
      </Helmet>
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        {/* Sticky TopNavBar without title */}
        <Box sx={{ position: 'sticky', top: 0, zIndex: theme.zIndex.appBar }}>
          <TopNavBar />
        </Box>

        {/* Main Content Area */}
        <Container
          maxWidth="lg"
          sx={{
            paddingTop: theme.spacing(4),
            flexGrow: 1,
          }}
        >
          {/* Title */}
          <Typography
            variant="h5"
            sx={{
              fontFamily: theme.typography.fontFamily,
              marginBottom: theme.spacing(2),
              marginTop: theme.spacing(8),
              color: 'primary',
            }}
          >
            {statusOptions.find((status) => status.value === selectedStatus)?.label}
          </Typography>

          {/* Result Count */}
          <Typography
            variant="subtitle2"
            color="text.secondary"
            sx={{ marginBottom: theme.spacing(1) }}
          >
            {debouncedSearchTerm
              ? `Found ${filteredReturns.length} returns matching "${debouncedSearchTerm}"`
              : `Showing ${(currentPage - 1) * returnsPerPage + 1}-${
                  (currentPage - 1) * returnsPerPage + filteredReturns.length
                } out of ${totalResults} returns`}
          </Typography>

          <Grid container sx={{ flexGrow: 1, display: 'flex', flexDirection: 'row' }}>
            {/* Filter Panel for Desktop */}
            {isDesktop && (
              <Grid
                item
                sm={3}
                md={2}
                sx={{
                  height: 'calc(100vh - 100px)',
                  padding: theme.spacing(2),
                  backgroundColor: theme.palette.background.paper,
                  position: 'sticky',
                  top: theme.spacing(4),
                  overflowY: 'auto',
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: 'black',
                    fontFamily: theme.typography.fontFamily,
                    marginBottom: theme.spacing(1),
                  }}
                >
                  Status
                </Typography>
                <Divider sx={{ marginBottom: theme.spacing(1) }} />
                <MUIList component="nav">
                  {statusOptions.map((status) => (
                    <ListItemButton
                      key={status.value}
                      selected={selectedStatus === status.value}
                      onClick={() => {
                        setSelectedStatus(status.value);
                        setCurrentPage(1);
                      }}
                      sx={{
                        borderRadius: theme.shape.borderRadius,
                        marginBottom: theme.spacing(0.5),
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            variant="body1"
                            sx={{
                              fontFamily: theme.typography.fontFamily,
                              color: 'black',
                            }}
                          >
                            {status.label}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  ))}

                  {/* Orders Link without Icon */}
                  <ListItemButton
                    onClick={() => navigate('/orders')}
                    selected={location.pathname === '/orders'}
                    sx={{
                      borderRadius: theme.shape.borderRadius,
                      marginTop: theme.spacing(2),
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography
                          variant="body1"
                          sx={{
                            fontFamily: theme.typography.fontFamily,
                            color: 'black',
                          }}
                        >
                          Orders
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </MUIList>
              </Grid>
            )}

            {/* Main Content */}
            <Grid
              item
              xs={12}
              sm={isDesktop ? 9 : 12}
              md={isDesktop ? 10 : 12}
              sx={{
                padding: isMobile ? 0 : theme.spacing(2),
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'auto',
                // Added paddingBottom to ensure the last item is visible above the bottom app bar
                paddingBottom: isMobile ? theme.spacing(8) : theme.spacing(2),
              }}
            >
              {/* Header with Search Bar and Filter Icon */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: isMobile ? 'column' : 'row',
                  justifyContent: isMobile ? 'space-between' : 'space-between',
                  alignItems: isMobile ? 'flex-start' : 'center',
                  marginBottom: theme.spacing(2),
                  gap: theme.spacing(2),
                  paddingX: isMobile ? theme.spacing(2) : 0,
                }}
              >
                {/* Search Bar and Filter Icon */}
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: isMobile ? '100%' : 'auto',
                    gap: theme.spacing(1),
                  }}
                >
                  {/* Search Bar */}
                  <Search>
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder="Search Returns…"
                      inputProps={{ 'aria-label': 'search' }}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </Search>

                  {/* Filter Icon, only show on mobile */}
                  {isMobile && (
                    <IconButton
                      aria-label="open filter"
                      onClick={toggleFilterDrawer(true)}
                      sx={{
                        color: theme.palette.primary.main,
                        backgroundColor: theme.palette.background.paper,
                        border: `1px solid ${theme.palette.primary.main}`,
                        '&:hover': {
                          backgroundColor: theme.palette.primary.main,
                          color: theme.palette.primary.contrastText,
                        },
                        borderRadius: '50%',
                        width: '40px',
                        height: '40px',
                      }}
                    >
                      <FilterListIcon />
                    </IconButton>
                  )}
                </Box>
              </Box>

              {/* Loading Indicator */}
              {loading && returnsList.length === 0 ? (
                <Box sx={{ textAlign: 'center', marginTop: theme.spacing(2) }}>
                  <CircularProgress />
                </Box>
              ) : filteredReturns.length > 0 ? (
                isDesktop ? (
                  <TableContainer
                    component={Paper}
                    sx={{
                      width: '100%',
                      margin: '0 auto',
                      maxWidth: '100%',
                      overflowX: 'hidden',
                      marginBottom: theme.spacing(2),
                    }}
                  >
                    <Table>
                      <TableHead sx={{ backgroundColor: theme.palette.background.paper }}>
                        <TableRow>
                          <TableCell
                            sx={{
                              color: 'black',
                              fontFamily: theme.typography.fontFamily,
                              fontWeight: 'normal',
                            }}
                          >
                            Date
                          </TableCell>
                          <TableCell
                            sx={{
                              color: 'black',
                              fontFamily: theme.typography.fontFamily,
                              fontWeight: 'normal',
                            }}
                          >
                            Subject
                          </TableCell>
                          <TableCell
                            sx={{
                              color: 'black',
                              fontFamily: theme.typography.fontFamily,
                              fontWeight: 'normal',
                            }}
                          >
                            Status
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredReturns.map((ticket) => (
                          <ReturnRow key={ticket.id} ticket={ticket} isDesktop={isDesktop} theme={theme} />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  // Mobile View
                  filteredReturns.map((ticket, index) => (
                    <ReturnRow
                      key={ticket.id}
                      ticket={ticket}
                      isDesktop={isDesktop}
                      theme={theme}
                      // Add marginBottom to the last item to prevent it from being hidden
                      style={{
                        marginBottom:
                          index === filteredReturns.length - 1 ? theme.spacing(8) : 0,
                      }}
                    />
                  ))
                )
              ) : (
                <Typography
                  variant="body1"
                  align="center"
                  sx={{ marginTop: theme.spacing(2), fontFamily: theme.typography.fontFamily }}
                >
                  No returns found.
                </Typography>
              )}

              {/* Pagination */}
              {totalPages > 1 && !debouncedSearchTerm && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: theme.spacing(4),
                    marginBottom: isMobile ? theme.spacing(8) : 0,
                    flexWrap: 'nowrap',
                  }}
                >
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                    size={isMobile ? 'small' : 'medium'}
                    showFirstButton={!isMobile}
                    showLastButton={!isMobile}
                    siblingCount={isMobile ? 0 : 1}
                    boundaryCount={isMobile ? 1 : 2}
                  />
                </Box>
              )}

              {/* Filter Drawer for Mobile */}
              <Drawer
                anchor="right"
                open={isFilterDrawerOpen}
                onClose={toggleFilterDrawer(false)}
                ModalProps={{
                  keepMounted: true,
                }}
              >
                <Box
                  sx={{
                    width: 250,
                    padding: theme.spacing(2),
                  }}
                  role="presentation"
                >
                  {/* Close Button */}
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton onClick={toggleFilterDrawer(false)} aria-label="close filter">
                      <CloseIcon />
                    </IconButton>
                  </Box>

                  {/* Filter Options */}
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: theme.typography.fontFamily,
                      marginBottom: theme.spacing(1),
                      color: 'black',
                    }}
                  >
                    Filter Returns
                  </Typography>
                  <Divider sx={{ marginBottom: theme.spacing(1) }} />
                  <MUIList component="nav">
                    {statusOptions.map((status) => (
                      <ListItemButton
                        key={status.value}
                        selected={selectedStatus === status.value}
                        onClick={() => {
                          setSelectedStatus(status.value);
                          setCurrentPage(1);
                          setIsFilterDrawerOpen(false);
                        }}
                        sx={{
                          borderRadius: theme.shape.borderRadius,
                          marginBottom: theme.spacing(0.5),
                        }}
                      >
                        <ListItemText
                          primary={
                            <Typography
                              variant="body1"
                              sx={{
                                fontFamily: theme.typography.fontFamily,
                                color: 'black',
                              }}
                            >
                              {status.label}
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    ))}

                    {/* Orders Link without Icon */}
                    <ListItemButton
                      onClick={() => {
                        navigate('/orders');
                        setIsFilterDrawerOpen(false);
                      }}
                      selected={location.pathname === '/orders'}
                      sx={{
                        borderRadius: theme.shape.borderRadius,
                        marginTop: theme.spacing(2),
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            variant="body1"
                            sx={{
                              fontFamily: theme.typography.fontFamily,
                              color: 'black',
                            }}
                          >
                            Orders
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </MUIList>
                </Box>
              </Drawer>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

// Separate ReturnRow component for better readability and maintainability
function ReturnRow({ ticket, isDesktop, theme, style }) {
  const [open, setOpen] = useState(false);

  const formattedDate = ticket.created_at
    ? moment(ticket.created_at).format('MMMM Do, YYYY')
    : 'Date not available';

  const handleRowClick = () => {
    setOpen(!open);
  };

  // For mobile-specific state for expanding return items
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleMobileExpandClick = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      {/* For Desktop: Use Table Rows */}
      {isDesktop ? (
        <>
          <TableRow
            hover
            onClick={handleRowClick}
            sx={{
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
                transform: 'scale(1.01)',
                transition: 'transform 0.2s ease-in-out',
              },
            }}
          >
            <TableCell sx={{ fontFamily: theme.typography.fontFamily }}>{formattedDate}</TableCell>
            <TableCell sx={{ fontFamily: theme.typography.fontFamily }}>{ticket.subject}</TableCell>
            <TableCell sx={{ fontFamily: theme.typography.fontFamily }}>
              {ticket._ticket_status?.status || 'Initiated'}
            </TableCell>
          </TableRow>
          {/* Collapsible Row for Return Details */}
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box
                  margin={1}
                  sx={{
                    overflowX: 'auto',
                    padding: theme.spacing(0, 1),
                    width: '100%',
                  }}
                >
                  {/* Return Details */}
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    component="div"
                    sx={{ fontFamily: theme.typography.fontFamily }}
                  >
                    Return Details
                  </Typography>
                  <Divider sx={{ marginBottom: '12px' }} />
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: theme.typography.fontFamily,
                      color: '#555555',
                    }}
                  >
                    <strong>Subject:</strong> {ticket.subject}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: theme.typography.fontFamily,
                      color: '#555555',
                    }}
                  >
                    <strong>Description:</strong> {ticket.description}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: theme.typography.fontFamily,
                      color: '#555555',
                    }}
                  >
                    <strong>Date Created:</strong> {formattedDate}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: theme.typography.fontFamily,
                      color: '#555555',
                    }}
                  >
                    <strong>Status:</strong> {ticket._ticket_status?.status || 'Initiated'}
                  </Typography>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </>
      ) : (
        // For Mobile: Use Cards
        <>
          <Card
            sx={{
              marginBottom: 0,
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              },
              borderRadius: 0,
              ...style, // Apply any additional styles passed as props
            }}
            onClick={handleMobileExpandClick}
          >
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="subtitle1" sx={{ fontFamily: theme.typography.fontFamily }}>
                  {formattedDate}
                </Typography>
                <IconButton onClick={handleMobileExpandClick} size="small">
                  {mobileOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Box>
              <Typography variant="body2" sx={{ fontFamily: theme.typography.fontFamily }}>
                {ticket.subject}
              </Typography>
              
            </CardContent>
            <Collapse in={mobileOpen} timeout="auto" unmountOnExit>
              <CardContent>
                {/* Return Details */}
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  component="div"
                  sx={{ fontFamily: theme.typography.fontFamily }}
                >
                  Return Details
                </Typography>
                <Divider sx={{ marginBottom: '12px' }} />
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: theme.typography.fontFamily,
                    color: '#555555',
                  }}
                >
                  <strong>Subject:</strong> {ticket.subject}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: theme.typography.fontFamily,
                    color: '#555555',
                  }}
                >
                  <strong>Description:</strong> {ticket.description}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: theme.typography.fontFamily,
                    color: '#555555',
                  }}
                >
                  <strong>Date Created:</strong> {formattedDate}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: theme.typography.fontFamily,
                    color: '#555555',
                  }}
                >
                  <strong>Status:</strong> {ticket._ticket_status?.status || 'Initiated'}
                </Typography>
              </CardContent>
            </Collapse>
          </Card>
        </>
      )}
    </>
  );
}

export default Returns;
