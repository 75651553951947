import React from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Tooltip,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import AddToCartButton from './AddToCartButton';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DescriptionIcon from '@mui/icons-material/Description';
import { useNavigate } from 'react-router-dom';
import { useTheme, alpha } from '@mui/material/styles';

function PHProductCard({ product, multiplier }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Calculate Price Per Unit for display
  const priceData = calculatePricePerUnit(
    product.purchase_price * multiplier,
    product.product_volume,
    product.priced_by
  );

  // Function to calculate Price Per Unit
  function calculatePricePerUnit(purchasePrice, productVolume, pricedBy) {
    if (purchasePrice && productVolume > 0 && pricedBy) {
      const pricePerUnit = purchasePrice / productVolume;
      return {
        pricePerUnit: `$${pricePerUnit.toFixed(2)}/${pricedBy}`,
        unitPriceValue: pricePerUnit,
      };
    }
    return null;
  }

  return (
    <Card
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        position: 'relative',
        transition: 'box-shadow 0.2s',
        '&:hover': {
          boxShadow: theme.shadows[4],
          backgroundColor: alpha(theme.palette.background.paper, 0.1),
        },
        height: '100%',
        overflow: 'hidden',
      }}
      onClick={() => navigate(`/product/${product.id}`)}
    >
      {/* Product Image */}
      {product.Image_URL && (
        <CardMedia
          component="img"
          height="80"
          image={product.Image_URL}
          alt={product.short_description}
          loading="lazy"
          sx={{
            objectFit: 'contain',
          }}
        />
      )}

      {/* Vendor Logo */}
      {product._vendor?.Logo_URL && (
        <Box
          sx={{
            position: 'absolute',
            top: theme.spacing(0.5),
            right: theme.spacing(0.5),
            width: 40,
            height: 40,
            borderRadius: '50%',
            backgroundColor: theme.palette.background.paper,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: theme.shadows[1],
          }}
        >
          <img
            src={product._vendor.Logo_URL}
            alt={product._vendor.Short_Name}
            style={{
              width: '70%',
              height: '70%',
              objectFit: 'contain',
              borderRadius: '50%',
            }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = '/path/to/default/logo.png';
            }}
          />
        </Box>
      )}

      {/* Card Content */}
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          padding: theme.spacing(0.25),
        }}
      >
        {/* Brand and Description Container */}
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            minHeight: '40px',
          }}
        >
          {/* Brand */}
          {product.brand && (
            <Typography
              variant="subtitle2"
              sx={{
                color: theme.palette.text.secondary,
                fontWeight: theme.typography.subtitle1.fontWeight,
                fontFamily: theme.typography.fontFamily,
                fontSize: '0.6rem',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                marginBottom: theme.spacing(0.1),
              }}
            >
              {product.brand}
            </Typography>
          )}

          {/* Product Description */}
          <Typography
            variant="body2"
            sx={{
              fontFamily: theme.typography.fontFamily,
              fontWeight: 'normal',
              color: 'black',
              fontSize: '0.7rem',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              marginBottom: 0,
            }}
          >
            {product.short_description || ''}
          </Typography>
        </Box>

        {/* Price and Icons */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginTop: theme.spacing(0.2),
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: '0.75rem',
              fontWeight: theme.typography.h6.fontWeight,
              fontFamily: theme.typography.fontFamily,
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            {product.purchase_price === 0
              ? 'MARKET'
              : `$${(product.purchase_price * multiplier).toFixed(2)}`}
            {priceData && (
              <Typography
                component="span"
                sx={{
                  fontSize: '0.65rem',
                  color: theme.palette.text.secondary,
                  marginLeft: theme.spacing(0.2),
                }}
              >
                ({priceData.pricePerUnit})
              </Typography>
            )}
          </Typography>

          {/* Icons Section */}
          <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
            {isMobile ? (
              priceData?.unitPriceValue < 0.01 && (
                <Tooltip
                  title="This price seems unusually low and might be per unit rather than per case. The final price will be confirmed before purchase."
                  placement="top"
                >
                  <WarningAmberIcon
                    sx={{
                      color: theme.palette.warning.main,
                      marginLeft: theme.spacing(0.2),
                      fontSize: '0.8rem',
                    }}
                  />
                </Tooltip>
              )
            ) : (
              <>
                {priceData?.unitPriceValue < 0.01 && (
                  <Tooltip
                    title="This price seems unusually low and might be per unit rather than per case. The final price will be confirmed before purchase."
                    placement="top"
                  >
                    <WarningAmberIcon
                      sx={{
                        color: theme.palette.warning.main,
                        marginLeft: theme.spacing(0.2),
                        fontSize: '0.8rem',
                      }}
                    />
                  </Tooltip>
                )}
                {product.Lead_Time > 0 && (
                  <Tooltip
                    title={`${product.Lead_Time} days Lead Time`}
                    placement="top"
                  >
                    <AccessTimeIcon
                      sx={{
                        color: theme.palette.info.main,
                        marginLeft: theme.spacing(0.2),
                        fontSize: '0.8rem',
                      }}
                    />
                  </Tooltip>
                )}
                {product.special_notes &&
                  !/no/i.test(product.special_notes) && (
                    <Tooltip
                      title={product.special_notes}
                      placement="top"
                    >
                      <DescriptionIcon
                        sx={{
                          color: theme.palette.text.secondary,
                          marginLeft: theme.spacing(0.2),
                          fontSize: '0.8rem',
                        }}
                      />
                    </Tooltip>
                  )}
              </>
            )}
          </Box>
        </Box>

        {/* Add to Cart Button */}
        <Box
          sx={{ marginTop: theme.spacing(0.1) }}
          onClick={(e) => e.stopPropagation()}
        >
          <AddToCartButton
            product={product}
            multiplier={multiplier}
            restaurantPrice={product.purchase_price}
          />
        </Box>
      </CardContent>
    </Card>
  );
}

export default PHProductCard;
