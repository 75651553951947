import React from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  useMediaQuery,
} from '@mui/material';
import AddToCartButton from './AddToCartButton';
import { useTheme, alpha } from '@mui/material/styles';

function InventoryProductCard({
  productInfo,
  item,
  multiplier,
  hasProductId,
  onClick,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const product = productInfo;

  // Get stock and PAR levels
  const stock =
    item.on_hand !== undefined
      ? item.on_hand
      : item.On_Hand !== undefined
      ? item.On_Hand
      : null;

  const par =
    item.par !== undefined
      ? item.par
      : item.PAR !== undefined
      ? item.PAR
      : null;

  // Determine if stock is low
  const isStockLow = stock !== null && par !== null && stock <= par;

  // Set background color based on stock level
  const cardBackgroundColor = isStockLow
    ? alpha(theme.palette.warning.light, 0.2) // Light, non-intrusive color
    : theme.palette.background.paper;

  return (
    <Card
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        position: 'relative',
        transition: 'box-shadow 0.2s',
        '&:hover': {
          boxShadow: theme.shadows[4],
          backgroundColor: alpha(theme.palette.background.paper, 0.1),
        },
        height: '100%',
        overflow: 'hidden',
        backgroundColor: cardBackgroundColor,
      }}
      onClick={onClick}
    >
      {/* Product Image */}
      {product?.Image_URL && (
        <CardMedia
          component="img"
          height="80"
          image={product.Image_URL}
          alt={product.short_description || item.item_description}
          loading="lazy"
          sx={{
            objectFit: 'contain',
          }}
        />
      )}

      {/* Vendor Logo */}
      {product?._vendor?.Logo_URL && (
        <Box
          sx={{
            position: 'absolute',
            top: theme.spacing(0.5),
            right: theme.spacing(0.5),
            width: 40,
            height: 40,
            borderRadius: '50%',
            backgroundColor: theme.palette.background.paper,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: theme.shadows[1],
          }}
        >
          <img
            src={product._vendor.Logo_URL}
            alt={product._vendor.Short_Name}
            style={{
              width: '70%',
              height: '70%',
              objectFit: 'contain',
              borderRadius: '50%',
            }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = '/path/to/default/logo.png';
            }}
          />
        </Box>
      )}

      {/* Card Content */}
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          padding: theme.spacing(0.5),
        }}
      >
        {/* Brand and Description Container */}
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            minHeight: '40px',
          }}
        >
          {/* Brand */}
          {product?.brand && (
            <Typography
              variant="subtitle2"
              sx={{
                color: theme.palette.text.secondary,
                fontWeight: theme.typography.subtitle1.fontWeight,
                fontFamily: theme.typography.fontFamily,
                fontSize: '0.6rem',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                marginBottom: theme.spacing(0.1),
              }}
            >
              {product.brand}
            </Typography>
          )}

          {/* Product Description */}
          <Typography
            variant="body2"
            sx={{
              fontFamily: theme.typography.fontFamily,
              fontWeight: 'normal',
              color: 'black',
              fontSize: '0.7rem',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              marginBottom: 0,
            }}
          >
            {product?.short_description || item.item_description || ''}
          </Typography>
        </Box>

        {/* Stock and PAR Levels */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginTop: theme.spacing(0.2),
            justifyContent: 'space-between',
          }}
        >
          {/* Stock Level */}
          <Typography
            variant="caption"
            sx={{
              fontSize: '.8rem',
              color: theme.palette.text.secondary,
            }}
          >
            Stock: {stock !== null ? stock : 'N/A'}
          </Typography>

          {/* PAR Level */}
          <Typography
            variant="caption"
            sx={{
              fontSize: '0.8rem',
              color: theme.palette.text.secondary,
            }}
          >
            PAR: {par !== null ? par : 'N/A'}
          </Typography>
        </Box>

        {/* Add to Cart Button */}
        {hasProductId && product?.id && (
          <Box
            sx={{ marginTop: theme.spacing(0.1) }}
            onClick={(e) => e.stopPropagation()}
          >
            <AddToCartButton
              product={product}
              multiplier={multiplier}
              restaurantPrice={product.purchase_price}
            />
          </Box>
        )}
      </CardContent>
    </Card>
  );
}

export default InventoryProductCard;
