// src/components/PHFilterPanel.js

import React, { useState, useEffect } from 'react';
import {
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  Box,
  InputBase,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormGroup,
} from '@mui/material';
import { useTheme, styled, alpha } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';

// Styled Components for Search Bar
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius * 3,
  border: `1px solid ${theme.palette.primary.main}`,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.25),
  },
  width: '100%',
  marginBottom: theme.spacing(1),
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: theme.spacing(0, 1.5),
  height: '100%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  pointerEvents: 'none',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontFamily: theme.typography.fontFamily,
  fontSize: '0.875rem',
  '& .MuiInputBase-input': {
    padding: theme.spacing(0.5, 0.5, 0.5, 0),
    paddingLeft: `calc(1em + ${theme.spacing(3)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

function PHFilterPanel({
  categories = [],
  selectedCategory,
  setSelectedCategory,
  subcategories = [],
  selectedSubcategory,
  setSelectedSubcategory,
  vendors = [],
  selectedVendors,
  setSelectedVendors,
  brands = [],
  selectedBrands,
  setSelectedBrands,
  clearAllFilters,
}) {
  const theme = useTheme();
  const [brandSearch, setBrandSearch] = useState('');
  const [vendorSearch, setVendorSearch] = useState('');

  // Filtered options based on search
  const filteredBrands = brands.filter((brand) =>
    brand.toLowerCase().includes(brandSearch.toLowerCase())
  );

  const filteredVendors = vendors.filter(
    (vendor) =>
      vendor.Short_Name &&
      vendor.Short_Name.toLowerCase().includes(vendorSearch.toLowerCase())
  );

  // Function to truncate text to a maximum length
  const truncateText = (text, maxLength) => {
    if (!text) return '';
    if (text.length <= maxLength) return text;
    return `${text.slice(0, maxLength)}...`;
  };

  // Debugging: Log filtered vendors and brands
  useEffect(() => {
    console.log('Filtered Brands:', filteredBrands);
    console.log('Filtered Vendors:', filteredVendors);
  }, [filteredBrands, filteredVendors]);

  return (
    <Box>
      {/* Filter Header */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: theme.spacing(2),
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontFamily: theme.typography.fontFamily,
            fontWeight: 'light',
            color: theme.palette.grey[600],
            fontSize: '0.875rem',
          }}
        >
          Filter By
        </Typography>
        <Button
          variant="text"
          onClick={clearAllFilters}
          sx={{
            textTransform: 'none',
            color: theme.palette.primary.main,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: '0.875rem',
          }}
        >
          Clear All
        </Button>
      </Box>

      {/* Category Filter */}
      <Accordion
        sx={{
          boxShadow: 'none',
          backgroundColor: 'transparent',
          '&:before': { display: 'none' },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: 'black' }} />}
          aria-controls="category-content"
          id="category-header"
          sx={{
            borderTop: `1px solid ${theme.palette.divider}`,
            borderBottom: `1px solid ${theme.palette.divider}`,
            minHeight: '48px',
            '& .MuiAccordionSummary-content': {
              margin: 0,
            },
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              fontFamily: theme.typography.fontFamily,
              fontWeight: 'regular',
              color: 'black',
              fontSize: '1rem',
            }}
          >
            Category
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup>
            {categories.map((category) => (
              <FormControlLabel
                key={category.id}
                control={
                  <Checkbox
                    checked={selectedCategory === category.id}
                    onChange={() => {
                      if (selectedCategory === category.id) {
                        setSelectedCategory(0); // Deselect if already selected
                        setSelectedSubcategory(0); // Reset subcategory
                      } else {
                        setSelectedCategory(category.id);
                      }
                    }}
                  />
                }
                label={truncateText(category.name, 20)}
                sx={{ fontFamily: theme.typography.fontFamily }}
              />
            ))}
          </FormGroup>
        </AccordionDetails>
      </Accordion>

      {/* Subcategory Filter */}
      {selectedCategory !== 0 && (
        <Accordion
          sx={{
            boxShadow: 'none',
            backgroundColor: 'transparent',
            '&:before': { display: 'none' },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: 'black' }} />}
            aria-controls="subcategory-content"
            id="subcategory-header"
            sx={{
              borderTop: `1px solid ${theme.palette.divider}`,
              borderBottom: `1px solid ${theme.palette.divider}`,
              minHeight: '48px',
              '& .MuiAccordionSummary-content': {
                margin: 0,
              },
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontFamily: theme.typography.fontFamily,
                fontWeight: 'regular',
                color: 'black',
                fontSize: '1rem',
              }}
            >
              Subcategory
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormGroup>
              {subcategories.map((subcategory) => (
                <FormControlLabel
                  key={subcategory.id}
                  control={
                    <Checkbox
                      checked={selectedSubcategory === subcategory.id}
                      onChange={() => {
                        if (selectedSubcategory === subcategory.id) {
                          setSelectedSubcategory(0); // Deselect if already selected
                        } else {
                          setSelectedSubcategory(subcategory.id);
                        }
                      }}
                    />
                  }
                  label={truncateText(subcategory.name, 20)}
                  sx={{ fontFamily: theme.typography.fontFamily }}
                />
              ))}
            </FormGroup>
          </AccordionDetails>
        </Accordion>
      )}

      {/* Vendor Filter */}
      <Accordion
        sx={{
          boxShadow: 'none',
          backgroundColor: 'transparent',
          '&:before': { display: 'none' },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: 'black' }} />}
          aria-controls="vendor-content"
          id="vendor-header"
          sx={{
            borderTop: `1px solid ${theme.palette.divider}`,
            borderBottom: `1px solid ${theme.palette.divider}`,
            minHeight: '48px',
            '& .MuiAccordionSummary-content': {
              margin: 0,
            },
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              fontFamily: theme.typography.fontFamily,
              fontWeight: 'regular',
              color: 'black',
              fontSize: '1rem',
            }}
          >
            Vendor
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/* Styled Search Bar */}
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search"
              inputProps={{ 'aria-label': 'search vendors' }}
              value={vendorSearch}
              onChange={(e) => setVendorSearch(e.target.value)}
            />
          </Search>
          <FormGroup>
            {filteredVendors.map((vendor) => (
              <FormControlLabel
                key={vendor.id}
                control={
                  <Checkbox
                    checked={selectedVendors.includes(vendor.id)}
                    onChange={() => {
                      if (selectedVendors.includes(vendor.id)) {
                        setSelectedVendors(selectedVendors.filter((id) => id !== vendor.id));
                      } else {
                        setSelectedVendors([...selectedVendors, vendor.id]);
                      }
                    }}
                  />
                }
                label={truncateText(vendor.Short_Name, 20)}
                sx={{ fontFamily: theme.typography.fontFamily }}
              />
            ))}
          </FormGroup>
        </AccordionDetails>
      </Accordion>

      {/* Brand Filter */}
      <Accordion
        sx={{
          boxShadow: 'none',
          backgroundColor: 'transparent',
          '&:before': { display: 'none' },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: 'black' }} />}
          aria-controls="brand-content"
          id="brand-header"
          sx={{
            borderTop: `1px solid ${theme.palette.divider}`,
            borderBottom: `1px solid ${theme.palette.divider}`,
            minHeight: '48px',
            '& .MuiAccordionSummary-content': {
              margin: 0,
            },
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              fontFamily: theme.typography.fontFamily,
              fontWeight: 'regular',
              color: 'black',
              fontSize: '1rem',
            }}
          >
            Brand
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/* Styled Search Bar */}
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search"
              inputProps={{ 'aria-label': 'search brands' }}
              value={brandSearch}
              onChange={(e) => setBrandSearch(e.target.value)}
            />
          </Search>
          <FormGroup>
            {filteredBrands.map((brand) => (
              <FormControlLabel
                key={brand}
                control={
                  <Checkbox
                    checked={selectedBrands.includes(brand)}
                    onChange={() => {
                      if (selectedBrands.includes(brand)) {
                        setSelectedBrands(selectedBrands.filter((b) => b !== brand));
                      } else {
                        setSelectedBrands([...selectedBrands, brand]);
                      }
                    }}
                  />
                }
                label={truncateText(brand, 20)}
                sx={{ fontFamily: theme.typography.fontFamily }}
              />
            ))}
          </FormGroup>
        </AccordionDetails>
      </Accordion>
    </Box>
  );

}

PHFilterPanel.propTypes = {
  categories: PropTypes.array.isRequired,
  selectedCategory: PropTypes.number.isRequired,
  setSelectedCategory: PropTypes.func.isRequired,
  subcategories: PropTypes.array.isRequired,
  selectedSubcategory: PropTypes.number.isRequired,
  setSelectedSubcategory: PropTypes.func.isRequired,
  vendors: PropTypes.array.isRequired,
  selectedVendors: PropTypes.array.isRequired,
  setSelectedVendors: PropTypes.func.isRequired,
  brands: PropTypes.array.isRequired,
  selectedBrands: PropTypes.array.isRequired,
  setSelectedBrands: PropTypes.func.isRequired,
  clearAllFilters: PropTypes.func.isRequired,
};

export default PHFilterPanel;
