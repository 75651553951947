import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Alert,
  InputBase,
  CardMedia,
  Container,
  Grid,
  Card,
  CardContent,
  Snackbar,
  IconButton,
  Drawer,
  ToggleButton,
  ToggleButtonGroup,
  Pagination,
} from '@mui/material';
import { useTheme, styled, alpha } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Search as SearchIcon,
  ViewList as ViewListIcon,
  ViewModule as ViewModuleIcon,
  FilterList as FilterListIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import PHProductCard from './PHProductCard'; // Import the new component
import TopNavBar from './TopNavBar';
import PHFilterPanel from './PHFilterPanel';
import { Helmet } from 'react-helmet';
import usePersistedState from './hooks/usePersistedState';
import AddToCartButton from './AddToCartButton';

// Styled Components for Search Bar
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius * 5,
  border: `1px solid ${theme.palette.primary.main}`,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.25),
  },
  width: '100%',
  maxWidth: 500,
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  pointerEvents: 'none',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontFamily: theme.typography.fontFamily,
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

// Utility functions
const serializeFilters = (filters) => {
  const params = new URLSearchParams();
  if (filters.searchTerm) params.set('search', filters.searchTerm);
  if (filters.selectedCategory) params.set('category', filters.selectedCategory);
  if (filters.selectedSubcategory) params.set('subcategory', filters.selectedSubcategory);
  if (filters.selectedVendors.length > 0) params.set('vendors', filters.selectedVendors.join(','));
  if (filters.selectedBrands.length > 0) params.set('brands', filters.selectedBrands.join(','));
  if (filters.currentPage !== 1) params.set('page', filters.currentPage);
  if (filters.viewMode !== 'card') params.set('view', filters.viewMode);
  return params.toString();
};

const deserializeFilters = (search) => {
  const params = new URLSearchParams(search);
  return {
    searchTerm: params.get('search') || '',
    selectedCategory: parseInt(params.get('category')) || 0,
    selectedSubcategory: parseInt(params.get('subcategory')) || 0,
    selectedVendors: params.get('vendors') ? params.get('vendors').split(',').map(Number) : [],
    selectedBrands: params.get('brands') ? params.get('brands').split(',') : [],
    currentPage: parseInt(params.get('page')) || 1,
    viewMode: params.get('view') || 'card',
  };
};

function PurchaseHistory() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const location = useLocation();

  // Deserialize initial filters from URL
  const initialFilters = deserializeFilters(location.search);

  // Persisted State variables
  const [inputSearchTerm, setInputSearchTerm] = usePersistedState('inputSearchTerm', initialFilters.searchTerm); // Temporary search term
  const [searchTerm, setSearchTerm] = usePersistedState('searchTerm', initialFilters.searchTerm); // Actual search term used for fetching
  const [selectedCategory, setSelectedCategory] = usePersistedState('selectedCategory', initialFilters.selectedCategory);
  const [selectedSubcategory, setSelectedSubcategory] = usePersistedState('selectedSubcategory', initialFilters.selectedSubcategory);
  const [selectedVendors, setSelectedVendors] = usePersistedState('selectedVendors', initialFilters.selectedVendors);
  const [selectedBrands, setSelectedBrands] = usePersistedState('selectedBrands', initialFilters.selectedBrands);
  const [currentPage, setCurrentPage] = usePersistedState('currentPage', initialFilters.currentPage);
  const [viewMode, setViewMode] = usePersistedState('viewMode', initialFilters.viewMode); // 'card' or 'list'

  // Other state variables
  const [purchaseHistoryItems, setPurchaseHistoryItems] = useState([]);
  const [loadingPurchaseHistory, setLoadingPurchaseHistory] = useState(false);
  const [errorPurchaseHistory, setErrorPurchaseHistory] = useState(null);

  const purchaseHistoryLimit = 24; // Based on itemsReceived from API
  const [totalPages, setTotalPages] = useState(1);
  const [multiplier, setMultiplier] = useState(1);
  const [userRestaurantId, setUserRestaurantId] = usePersistedState('userRestaurantId', null);
  const [userId, setUserId] = usePersistedState('userId', null);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [brands, setBrands] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  // Fetch user data and multiplier on component mount
  useEffect(() => {
    const fetchUserData = () => {
      const userData = localStorage.getItem('user');
      if (userData) {
        try {
          const parsedUser = JSON.parse(userData);
          setUserRestaurantId(parsedUser.restaurant_id);
          setUserId(parsedUser.id);

          const storedMultiplier = parseFloat(parsedUser.multiplier) || 1;
          setMultiplier(storedMultiplier);
          console.log('Multiplier retrieved from localStorage:', storedMultiplier);
        } catch (e) {
          console.error('Error parsing user data from local storage:', e);
          setErrorPurchaseHistory('Invalid user data. Please log in again.');
          setLoadingPurchaseHistory(false);
        }
      } else {
        setErrorPurchaseHistory('User not found. Please log in.');
        setLoadingPurchaseHistory(false);
      }
    };

    fetchUserData();
  }, [setUserRestaurantId, setUserId]);

  // Fetch Categories
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/category',
          {
            headers: { 'Content-Type': 'application/json' },
          }
        );
        setCategories(response.data);
        console.log('Fetched Categories:', response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
        setCategories([]);
      }
    };

    fetchCategories();
  }, []);

  // Fetch Subcategories based on selectedCategory
  useEffect(() => {
    const fetchSubcategories = async () => {
      if (selectedCategory === 0) {
        setSubcategories([]);
        setSelectedSubcategory(0);
        return;
      }
      try {
        const response = await axios.get(
          'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/subcategory',
          {
            headers: { 'Content-Type': 'application/json' },
            params: { filter: selectedCategory },
          }
        );
        setSubcategories(response.data);
        console.log('Fetched Subcategories:', response.data);
      } catch (error) {
        console.error('Error fetching subcategories:', error);
        setSubcategories([]);
      }
    };

    fetchSubcategories();
  }, [selectedCategory, setSelectedSubcategory]);

  // Extract Unique Vendors and Brands whenever purchaseHistoryItems change
  useEffect(() => {
    const extractVendors = () => {
      const uniqueVendorsMap = new Map();
      purchaseHistoryItems.forEach((product) => {
        const vendor = product._vendor;
        if (vendor && vendor.id && !uniqueVendorsMap.has(vendor.id)) {
          uniqueVendorsMap.set(vendor.id, vendor);
        }
      });
      setVendors(Array.from(uniqueVendorsMap.values()));
      console.log('Unique Vendors:', Array.from(uniqueVendorsMap.values()));
    };

    const extractBrands = () => {
      const uniqueBrandsSet = new Set();
      purchaseHistoryItems.forEach((product) => {
        const brand = product.brand;
        if (brand) {
          uniqueBrandsSet.add(brand);
        }
      });
      setBrands(Array.from(uniqueBrandsSet));
      console.log('Unique Brands:', Array.from(uniqueBrandsSet));
    };

    extractVendors();
    extractBrands();
  }, [purchaseHistoryItems]);

  // Effect to sync state changes to URL
  useEffect(() => {
    const filters = {
      searchTerm,
      selectedCategory,
      selectedSubcategory,
      selectedVendors,
      selectedBrands,
      currentPage,
      viewMode,
    };
    const queryString = serializeFilters(filters);
    navigate(`?${queryString}`, { replace: true });
  }, [
    searchTerm,
    selectedCategory,
    selectedSubcategory,
    selectedVendors,
    selectedBrands,
    currentPage,
    viewMode,
    navigate,
  ]);

  // Fetch purchase history when component mounts or when filters/page changes
  useEffect(() => {
    if (userId) {
      fetchPurchaseHistory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userId,
    currentPage,
    selectedCategory,
    selectedSubcategory,
    selectedVendors,
    selectedBrands,
    searchTerm,
  ]);

  const fetchPurchaseHistory = async () => {
    if (!userId) {
      setErrorPurchaseHistory(
        'User not authenticated. Please log in to view your purchase history.'
      );
      console.error('Error: User ID not found');
      return;
    }

    setLoadingPurchaseHistory(true);
    setErrorPurchaseHistory(null);

    try {
      const offset = (currentPage - 1) * purchaseHistoryLimit;

      // Build params with updated filter names
      const params = {
        useridfilter: userId,
        limit: purchaseHistoryLimit,
        offset: offset,
      };

      if (searchTerm.trim() !== '') {
        params.productnamefilter = searchTerm.trim();
      }

      if (selectedCategory !== 0) {
        params.category_id = selectedCategory;
      }

      if (selectedSubcategory !== 0) {
        params.subcategory_id = selectedSubcategory;
      }

      if (selectedVendors.length > 0) {
        params.vendor_id = selectedVendors.join(',');
      }

      if (selectedBrands.length > 0) {
        params.brandnamefilter = selectedBrands.join(',');
      }

      // Debugging: Log the parameters being sent
      console.log('Fetching Purchase History with params:', params);

      const response = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/purchase_history_items_react_3',
        {
          params: params,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      const data = response.data;

      // Debugging: Log the API response
      console.log('API Response:', data);

      let fetchedItems = [];
      if (Array.isArray(data.items)) {
        fetchedItems = data.items.map((item) => {
          const pricingArray = item._product._restaurant_products_pricing_of_product_test;
          let price = 0;

          if (Array.isArray(pricingArray)) {
            // Find the matching restaurant_id
            const matchedPricing = pricingArray.find(
              (p) => p.restaurant_id === userRestaurantId && typeof p.purchase_price === 'number'
            );
            if (matchedPricing) {
              price = matchedPricing.purchase_price;
            }
          }

          return {
            ...item._product,
            purchase_price: price,
          };
        });
      } else {
        console.warn('Unexpected API response structure for purchase history:', data);
        setErrorPurchaseHistory('Unexpected response structure from the server.');
        fetchedItems = [];
      }

      // Extract pagination info from API response
      const { pageTotal } = data;
      setTotalPages(pageTotal || 1);

      setPurchaseHistoryItems(fetchedItems);

      // Debugging: Log the fetched items
      console.log('Fetched Purchase History Items:', fetchedItems);
    } catch (err) {
      console.error('Error fetching purchase history:', err);
      setErrorPurchaseHistory(
        'Failed to load purchase history. Please try again later.'
      );
      setTotalPages(1);
    } finally {
      setLoadingPurchaseHistory(false);
    }
  };

  // Handle Search Submission
  const handleSearchSubmit = () => {
    setSearchTerm(inputSearchTerm.trim());
    setCurrentPage(1); // Reset to first page on new search
  };

  // Handle Search Key Down
  const handleSearchKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearchSubmit();
    }
  };

  // Handle View Mode Change
  const handleViewModeChange = (event, newViewMode) => {
    if (newViewMode !== null) {
      setViewMode(newViewMode);
    }
  };

  // Handle Snackbar close
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  // Handle Page Change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to top on page change
  };

  // Clear All Filters
  const clearAllFilters = () => {
    setSelectedCategory(0);
    setSelectedSubcategory(0);
    setSelectedVendors([]);
    setSelectedBrands([]);
    setInputSearchTerm('');
    setSearchTerm('');
    setCurrentPage(1);
  };

  // Function to toggle Drawer
  const toggleDrawer = (open) => () => {
    setIsDrawerOpen(open);
  };

  return (
    <div>
      {/* React Helmet for SEO and Marketing Copy */}
      <Helmet>
        <title>FareFood | Purchase History</title>
        <meta
          name="description"
          content="Explore your purchase history with FareFood. Easily track past orders, reorder your favorite meals, and discover new deals tailored just for you."
        />
        <meta
          name="keywords"
          content="purchase history, order tracking, FareFood, reorder, past purchases, food history, meal orders"
        />
        <meta property="og:title" content="FareFood | Purchase History" />
        <meta
          property="og:description"
          content="Explore your purchase history with FareFood. Easily track past orders, reorder your favorite meals, and discover new deals tailored just for you."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.farefood.com/purchase-history" />
        <meta
          property="og:image"
          content="https://www.farefood.com/images/purchase-history-banner.jpg"
        />
        <link rel="canonical" href="https://www.farefood.com/purchase-history" />
      </Helmet>

      {/* Sticky TopNavBar */}
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: theme.zIndex.appBar,
          paddingTop: theme.spacing(4),
        }}
      >
        <TopNavBar title="Purchase History" />
      </Box>

      {/* Main Content */}
      {loadingPurchaseHistory ? (
        <Box
          sx={{ display: 'flex', justifyContent: 'center', marginTop: theme.spacing(8) }}
        >
          <CircularProgress />
        </Box>
      ) : errorPurchaseHistory ? (
        <Container
          maxWidth="sm"
          sx={{
            textAlign: 'center',
            paddingTop: theme.spacing(8),
          }}
        >
          <Alert severity="error">{errorPurchaseHistory}</Alert>
        </Container>
      ) : (
        <Container
          maxWidth="lg"
          sx={{
            paddingTop: theme.spacing(8),
            paddingBottom: theme.spacing(4),
          }}
        >
          {/* Main Title */}
          <Typography
            variant="h5"
            component="h2"
            sx={{
              marginBottom: isMobile ? theme.spacing(1) : theme.spacing(2),
            }}
          >
            Purchase History
          </Typography>

          <Grid container spacing={2} alignItems="flex-start">
            {/* Filter Sidebar (Desktop) */}
            <Grid
              item
              xs={12}
              sm={3}
              md={2}
              sx={{
                paddingTop: theme.spacing(2),
                paddingBottom: theme.spacing(2),
                display: { xs: 'none', sm: 'block' },
                height: 'calc(100vh - 64px)',
                position: 'sticky',
                top: '64px',
                overflowY: 'auto',
                backgroundColor: theme.palette.background.paper,
              }}
            >
              <PHFilterPanel
                categories={categories}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                subcategories={subcategories}
                selectedSubcategory={selectedSubcategory}
                setSelectedSubcategory={setSelectedSubcategory}
                vendors={vendors}
                selectedVendors={selectedVendors}
                setSelectedVendors={setSelectedVendors}
                brands={brands}
                selectedBrands={selectedBrands}
                setSelectedBrands={setSelectedBrands}
                clearAllFilters={clearAllFilters}
              />
            </Grid>

            {/* Search, View Toggle, and Controls */}
            <Grid
              item
              xs={12}
              sm={9}
              md={10}
              sx={{
                paddingTop: isMobile ? theme.spacing(1) : theme.spacing(0),
                paddingBottom: isMobile ? theme.spacing(1) : theme.spacing(2),
              }}
            >
              {/* Header Section with Controls */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: isMobile ? 'column' : 'row',
                  alignItems: isMobile ? 'flex-start' : 'center',
                  justifyContent: 'space-between',
                  paddingBottom: theme.spacing(2),
                  gap: theme.spacing(2),
                }}
              >
                {/* Controls: View Toggle and Filter Icon */}
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: theme.spacing(1),
                  }}
                >
                  {/* View Toggle Button */}
                  <ToggleButtonGroup
                    value={viewMode}
                    exclusive
                    onChange={handleViewModeChange}
                    aria-label="view mode"
                    size="small"
                  >
                    <ToggleButton value="list" aria-label="list view">
                      <ViewListIcon />
                    </ToggleButton>
                    <ToggleButton value="card" aria-label="card view">
                      <ViewModuleIcon />
                    </ToggleButton>
                  </ToggleButtonGroup>

                  {/* Single Filter Icon only on Mobile */}
                  {isMobile && (
                    <IconButton
                      color="primary"
                      onClick={toggleDrawer(true)}
                      aria-label="open filters"
                      sx={{
                        border: `1px solid ${theme.palette.primary.main}`,
                        borderRadius: '50%',
                        padding: theme.spacing(1),
                        backgroundColor: alpha(theme.palette.primary.main, 0.1),
                        '&:hover': {
                          backgroundColor: alpha(theme.palette.primary.main, 0.2),
                        },
                      }}
                    >
                      <FilterListIcon />
                    </IconButton>
                  )}
                </Box>

                {/* Search Bar Positioned Below Controls on Mobile */}
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: isMobile ? 'center' : 'flex-end',
                  }}
                >
                  <Search sx={{ width: isMobile ? '100%' : 'auto' }}>
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder="Search Items…"
                      inputProps={{ 'aria-label': 'search' }}
                      value={inputSearchTerm}
                      onChange={(e) => setInputSearchTerm(e.target.value)}
                      onKeyDown={handleSearchKeyDown}
                    />
                  </Search>
                </Box>
              </Box>

              {/* Display Purchase History Items */}
              {purchaseHistoryItems.length > 0 ? (
                <>
                  {viewMode === 'card' ? (
                    <Grid container spacing={2}>
                      {purchaseHistoryItems.map((product) => (
                        <Grid item xs={6} sm={3} lg={2} key={product.id}>
                          <PHProductCard
                            product={product}
                            multiplier={multiplier}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    // List View
                    <Box>
                      {purchaseHistoryItems.map((product) => (
                        <Card
                          key={product.id}
                          sx={{
                            display: 'flex',
                            marginBottom: theme.spacing(2),
                            cursor: 'pointer',
                            position: 'relative',
                            '&:hover': {
                              boxShadow: theme.shadows[4],
                            },
                          }}
                          onClick={() => {
                            navigate(`/product/${product.id}${location.search}`);
                          }}
                        >
                          {product.Image_URL ? (
                            <CardMedia
                              component="img"
                              sx={{ width: 120, height: 120, objectFit: 'contain' }}
                              image={product.Image_URL}
                              alt={product.short_description}
                            />
                          ) : (
                            <Box
                              sx={{
                                width: 120,
                                height: 120,
                                backgroundColor: '#f0f0f0',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <Typography variant="subtitle1">No Image Available</Typography>
                            </Box>
                          )}

                          <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                            <CardContent sx={{ flex: '1 0 auto' }}>
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  fontFamily: theme.typography.fontFamily,
                                  color: theme.palette.text.secondary,
                                  fontWeight: 'bold',
                                }}
                              >
                                {product.brand}
                              </Typography>

                              <Typography
                                variant="body1"
                                sx={{
                                  fontFamily: theme.typography.fontFamily,
                                  marginBottom: '8px',
                                  display: '-webkit-box',
                                  WebkitLineClamp: 2,
                                  WebkitBoxOrient: 'vertical',
                                  overflow: 'hidden',
                                }}
                              >
                                {product.short_description}
                              </Typography>

                              {/* Price and AddToCartButton */}
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  marginTop: 'auto',
                                }}
                              >
                                <Typography
                                  variant="h6"
                                  sx={{
                                    fontSize: '1rem',
                                    fontWeight: theme.typography.h6.fontWeight,
                                    fontFamily: theme.typography.fontFamily,
                                    marginRight: theme.spacing(1),
                                  }}
                                >
                                  {product.purchase_price > 0
                                    ? `$${(product.purchase_price * multiplier).toFixed(2)}`
                                    : 'MARKET'}
                                </Typography>

                                {/* Always show AddToCartButton */}
                                <Box
                                  sx={{
                                    marginLeft: 'auto',
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  <AddToCartButton product={product} multiplier={multiplier} />
                                </Box>
                              </Box>
                            </CardContent>
                          </Box>

                          {/* Vendor Logo Positioned at Upper Right of Image */}
                          {product._vendor?.Logo_URL ? (
                            <Box
                              sx={{
                                position: 'absolute',
                                top: theme.spacing(2),
                                right: theme.spacing(2),
                                width: 40,
                                height: 40,
                                borderRadius: '50%',
                                backgroundColor: theme.palette.background.paper,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                boxShadow: theme.shadows[1],
                              }}
                            >
                              <img
                                src={product._vendor.Logo_URL}
                                alt={product._vendor.Short_Name}
                                style={{
                                  width: '80%',
                                  height: '80%',
                                  objectFit: 'contain',
                                  borderRadius: '50%',
                                }}
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = '/path/to/default/logo.png';
                                }}
                              />
                            </Box>
                          ) : null}
                        </Card>
                      ))}
                    </Box>
                  )}

                  {/* Pagination Controls */}
                  {totalPages > 1 && (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: theme.spacing(4),
                        marginBottom: theme.spacing(6),
                      }}
                    >
                      <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                        siblingCount={isMobile ? 0 : 1}
                        boundaryCount={isMobile ? 1 : 2}
                      />
                    </Box>
                  )}
                </>
              ) : (
                <Typography
                  variant="body1"
                  sx={{
                    textAlign: 'center',
                    marginTop: theme.spacing(2.5),
                    fontFamily: theme.typography.fontFamily,
                    fontSize: '1rem',
                  }}
                >
                  No purchase history found.
                </Typography>
              )}
            </Grid>
          </Grid>
        </Container>
      )}

      {/* Drawer for Mobile Filters */}
      <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{
            width: 250,
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            backgroundColor: theme.palette.background.paper,
          }}
          role="presentation"
        >
          {/* Close Button */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton onClick={toggleDrawer(false)} aria-label="close filters">
              <CloseIcon />
            </IconButton>
          </Box>

          {/* PHFilterPanel */}
          <PHFilterPanel
            categories={categories}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            subcategories={subcategories}
            selectedSubcategory={selectedSubcategory}
            setSelectedSubcategory={setSelectedSubcategory}
            vendors={vendors}
            selectedVendors={selectedVendors}
            setSelectedVendors={setSelectedVendors}
            brands={brands}
            selectedBrands={selectedBrands}
            setSelectedBrands={setSelectedBrands}
            clearAllFilters={clearAllFilters}
          />
        </Box>
      </Drawer>

      {/* Snackbar for Toast Messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        ContentProps={{
          sx: {
            backgroundColor:
              snackbarSeverity === 'success'
                ? theme.palette.success.main
                : snackbarSeverity === 'error'
                ? theme.palette.error.main
                : theme.palette.info.main,
            color: theme.palette.common.white,
          },
        }}
      />
    </div>
  );
}

export default PurchaseHistory;
