// src/CartItem.js

import React, { useState, useContext } from 'react';
import {
  Box,
  Card,
  CardMedia,
  Typography,
  Tooltip,
  IconButton,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  CircularProgress,
  Divider,
  Button,
  Avatar,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import {
  Inventory as InventoryIcon,
  List as ListIcon,
  Delete as DeleteIcon,
  Check as CheckIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import AddToCartButton from './AddToCartButton';
import { CartContext } from './CartContext';

// Define FareFood teal color
const farefoodTealColor = '#008080'; // Replace with your actual teal color code

// Maximum length for vendor name inside Avatar
const MAX_VENDOR_NAME_LENGTH = 15;

// Helper function to truncate text to a specified maximum length
const truncateText = (text, maxLength) => {
  if (!text) return '';
  return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
};

function CartItem({ item, multiplier }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { updateItemQuantity } = useContext(CartContext);
  const [addListSuccess, setAddListSuccess] = useState('');
  const [addListError, setAddListError] = useState('');
  const [addInventorySuccess, setAddInventorySuccess] = useState('');
  const [addInventoryError, setAddInventoryError] = useState('');
  const [openAddListModal, setOpenAddListModal] = useState(false);
  const [lists, setLists] = useState([]);
  const [listsLoading, setListsLoading] = useState(false);
  const [addingToList, setAddingToList] = useState(false);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  // State for confirm delete
  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleItemClick = () => {
    if (item._product && item._product.id) {
      navigate(`/product/${item._product.id}`);
    }
  };

  // Functions for Add to List
  const fetchLists = async () => {
    setListsLoading(true);
    setAddListError('');
    try {
      const authToken = localStorage.getItem('authToken');
      const user = JSON.parse(localStorage.getItem('user'));

      if (!authToken || !user || !user.restaurant_id) {
        console.error('Authentication token or user data not found.');
        setAddListError('Unable to fetch lists. Please try again.');
        return;
      }

      const headers = { Authorization: `Bearer ${authToken}` };
      const response = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/lists',
        {
          headers,
          params: {
            restaurantidfilter: user.restaurant_id,
          },
        }
      );

      setLists(response.data);
    } catch (error) {
      console.error('Error fetching lists:', error);
      setAddListError('Failed to load lists. Please try again.');
    } finally {
      setListsLoading(false);
    }
  };

  const handleOpenAddListModal = (e) => {
    e.stopPropagation();
    setOpenAddListModal(true);
    fetchLists();
  };

  const handleCloseAddListModal = (e) => {
    if (e) e.stopPropagation();
    setOpenAddListModal(false);
    setAddListSuccess('');
    setAddListError('');
  };

  const handleAddToList = (listId) => async (e) => {
    e.stopPropagation();
    setAddingToList(true);
    setAddListSuccess('');
    setAddListError('');
    try {
      const authToken = localStorage.getItem('authToken');
      const user = JSON.parse(localStorage.getItem('user'));

      if (!authToken || !user || !user.id) {
        console.error('Authentication token or user data not found.');
        setAddListError('Authentication error. Please log in again.');
        return;
      }

      const headers = {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      };

      const data = {
        list_item_name: '',
        product_id: item._product?.id,
        lists_id: listId,
      };

      await axios.post(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/list_item',
        data,
        { headers }
      );

      setAddListSuccess('Item successfully added to the list.');
      // Show a toast notification
      setSnackbar({
        open: true,
        message: 'Added to List',
        severity: 'success',
      });
      // Close the modal
      handleCloseAddListModal(e);
    } catch (error) {
      console.error('Error adding to list:', error);
      setAddListError('Failed to add item to the list. Please try again.');
      // Show a toast notification
      setSnackbar({
        open: true,
        message: 'Failed to add item to the list. Please try again.',
        severity: 'error',
      });
    } finally {
      setAddingToList(false);
    }
  };

  const handleAddToInventory = async (e) => {
    e.stopPropagation();
    setAddInventorySuccess('');
    setAddInventoryError('');
    try {
      const authToken = localStorage.getItem('authToken');
      const user = JSON.parse(localStorage.getItem('user'));

      if (!authToken || !user || !user.restaurant_id) {
        console.error('Authentication token or user data not found.');
        setAddInventoryError('Authentication error. Please log in again.');
        return;
      }

      const headers = {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      };

      const data = {
        restaurant_id: user.restaurant_id,
        item_description: item._product?.short_description || 'No description provided',
        On_Hand: 1,
        PAR: 1,
        remaining: 0,
        reorder_now: false,
        product_id: item._product?.id,
      };

      await axios.post(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/inventory',
        data,
        { headers }
      );

      setAddInventorySuccess('Item successfully added to inventory.');
      // Show a toast notification
      setSnackbar({
        open: true,
        message: 'Item successfully added to inventory.',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error adding to inventory:', error);
      setAddInventoryError('Failed to add item to inventory. Please try again.');
      // Show a toast notification
      setSnackbar({
        open: true,
        message: 'Failed to add item to inventory. Please try again.',
        severity: 'error',
      });
    }
  };

  // Handle Delete Click
  const handleDeleteClick = (e) => {
    e.stopPropagation();
    setConfirmDelete(true);
  };

  // Handle Confirm Delete
  const handleConfirmDelete = async (e) => {
    e.stopPropagation();
    try {
      await updateItemQuantity({ _product: item._product, orderitem_id: item.id }, 0);

      setSnackbar({
        open: true,
        message: 'Item removed from cart.',
        severity: 'success',
      });
      // No need to refresh the page; the cart will update automatically
    } catch (error) {
      console.error(
        'Error deleting item:',
        error.response ? error.response.data : error.message
      );
      setSnackbar({
        open: true,
        message: 'Failed to remove item. Please try again.',
        severity: 'error',
      });
    } finally {
      setConfirmDelete(false);
    }
  };

  // Handle Cancel Delete
  const handleCancelDelete = (e) => {
    e.stopPropagation();
    setConfirmDelete(false);
  };

  // Calculate itemPrice as total_price / quantity
  const itemPrice = item.total_price / item.quantity;

  // Purchase Price per Case and Price Per Unit
  const purchasePricePerCase = itemPrice;

  const { product_volume, priced_by } = item._product || {};

  const unitPrice =
    product_volume > 0 ? itemPrice / product_volume : null;

  // Function to render Purchase Price and Price Per Unit
  const renderPurchasePrice = () => {
    if (purchasePricePerCase > 0) {
      const pricePerUnitText = unitPrice
        ? `($${unitPrice.toFixed(2)}/${priced_by})`
        : '';
      return (
        <Typography
          variant="subtitle2"
          sx={{
            fontSize: '0.75rem',
            fontWeight: theme.typography.h6.fontWeight,
            fontFamily: theme.typography.fontFamily,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {`$${purchasePricePerCase.toFixed(2)} `}
          <Typography
            component="span"
            sx={{
              fontSize: '0.65rem',
              color: theme.palette.text.secondary,
              marginLeft: theme.spacing(0.5),
            }}
          >
            {pricePerUnitText}
          </Typography>
        </Typography>
      );
    } else {
      return (
        <Typography
          variant="subtitle2"
          sx={{
            fontSize: '0.75rem',
            fontWeight: theme.typography.h6.fontWeight,
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.primary,
          }}
        >
          MARKET
        </Typography>
      );
    }
  };

  return (
    <>
      <Card
        sx={{
          display: 'flex',
          padding: isMobile ? theme.spacing(1) : theme.spacing(1),
          marginBottom: theme.spacing(0),
          alignItems: 'flex-start',
          position: 'relative',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
          flexDirection: 'row',
          cursor: 'pointer',
          width: '100%',
          maxWidth: '100%',
          boxSizing: 'border-box',
          overflow: 'hidden',
          borderBottom: '1px solid #e0e0e0',
          justifyContent: 'flex-start',
        }}
      >
        {/* Vendor Logo and Product Image */}
        {item._product?._vendor && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginRight: isMobile ? theme.spacing(1) : theme.spacing(2),
              width: 'auto',
            }}
            onClick={handleItemClick}
          >
            <Avatar
              src={item._product._vendor.Logo_URL || undefined}
              alt={item._product._vendor.Short_Name}
              sx={{
                width: 40,
                height: 40,
                border: `2px solid ${farefoodTealColor}`,
                backgroundColor: item._product._vendor.Logo_URL
                  ? theme.palette.background.paper
                  : theme.palette.grey[300],
                color: item._product._vendor.Logo_URL
                  ? 'inherit'
                  : farefoodTealColor,
                overflow: 'hidden',
                fontSize: '0.6rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                marginBottom: theme.spacing(1),
              }}
              imgProps={{
                style: {
                  objectFit: 'contain',
                  width: '100%',
                  height: '100%',
                },
                onError: (e) => {
                  e.target.onerror = null;
                  e.target.src = undefined;
                },
              }}
            >
              {/* Display Truncated Vendor Name Inside Avatar When No Logo */}
              {!item._product._vendor.Logo_URL &&
                (truncateText(item._product._vendor.Short_Name, MAX_VENDOR_NAME_LENGTH) ||
                  'Vendor')}
            </Avatar>

            {/* Product Image */}
            <CardMedia
              component="img"
              sx={{
                width: 60,
                height: 60,
                objectFit: 'contain',
                flexShrink: 0,
              }}
              image={item._product?.Image_URL || '/placeholder.png'}
              alt={item._product?.short_description || 'Product Image'}
            />
          </Box>
        )}

        {/* Product Details */}
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            alignItems: 'flex-start',
            minWidth: 0,
            flexDirection: 'column',
            marginRight: isMobile ? theme.spacing(1) : 0,
          }}
          onClick={handleItemClick}
        >
          {/* Product Name */}
          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: theme.typography.fontFamily,
              fontSize: '0.85rem',
              fontWeight: theme.typography.fontWeightMedium,
              whiteSpace: 'normal',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
            }}
          >
            {item._product?.short_description || 'Product Name'}
          </Typography>

          {/* Brand */}
          {item._product?.brand && (
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                fontFamily: theme.typography.fontFamily,
                fontSize: '0.75rem',
                marginTop: theme.spacing(0.5),
              }}
            >
              {item._product.brand}
            </Typography>
          )}

          {/* Pack Size */}
          {item._product?.pack_size && (
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                fontFamily: theme.typography.fontFamily,
                fontSize: '0.75rem',
                marginTop: theme.spacing(0.5),
              }}
            >
              {item._product.pack_size}
            </Typography>
          )}

          {/* Icons Section */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginTop: theme.spacing(0.5),
            }}
          >
            {/* Add to List Icon */}
            <Tooltip title="Add to List" arrow>
              <IconButton
                onClick={(e) => handleOpenAddListModal(e)}
                size="small"
                color="primary"
              >
                <ListIcon fontSize="small" />
              </IconButton>
            </Tooltip>

            {/* Add to Inventory Icon */}
            <Tooltip title="Add to Inventory" arrow>
              <IconButton
                onClick={(e) => handleAddToInventory(e)}
                size="small"
                color="primary"
              >
                <InventoryIcon fontSize="small" />
              </IconButton>
            </Tooltip>

            {/* Trash Icon for Delete */}
            {confirmDelete ? (
              <Box sx={{ display: 'flex', gap: theme.spacing(0.5) }}>
                <IconButton
                  onClick={(e) => handleConfirmDelete(e)}
                  sx={{ color: theme.palette.success.main }}
                  aria-label="confirm delete"
                  size="small"
                >
                  <CheckIcon fontSize="small" />
                </IconButton>
                <IconButton
                  onClick={(e) => handleCancelDelete(e)}
                  sx={{ color: theme.palette.error.main }}
                  aria-label="cancel delete"
                  size="small"
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
            ) : (
              <Tooltip title="Remove from Cart" arrow>
                <IconButton
                  onClick={(e) => handleDeleteClick(e)}
                  sx={{ color: theme.palette.grey[500] }}
                  aria-label="delete product"
                  size="small"
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>

        {/* Right Side: Add to Cart Button and Prices */}
        <Box
          sx={{
            flexShrink: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            marginLeft: isMobile ? 0 : theme.spacing(2),
            marginTop: isMobile ? theme.spacing(1) : 0,
            minWidth: isMobile ? 'auto' : '150px',
          }}
        >
          {/* Add to Cart Button */}
          <Box sx={{ alignSelf: 'flex-end' }}>
            <AddToCartButton product={item._product} multiplier={multiplier} />
          </Box>

          {/* Purchase Price and Price Per Unit */}
          <Box sx={{ marginTop: theme.spacing(1), textAlign: 'right' }}>
            {renderPurchasePrice()}
          </Box>

          {/* Total Price */}
          <Box sx={{ marginTop: theme.spacing(1) }}>
            <Typography
              variant="subtitle2"
              sx={{
                fontFamily: theme.typography.fontFamily,
                fontSize: '0.85rem',
                fontWeight: theme.typography.fontWeightBold,
              }}
            >
              Total: ${(item.total_price || 0).toFixed(2)}
            </Typography>
          </Box>
        </Box>

        {/* Snackbar for Notifications */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbar.severity}
            sx={{ width: '100%', fontFamily: theme.typography.fontFamily }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>

        {/* Add List Modal */}
        <Dialog
          open={openAddListModal}
          onClose={(e) => {
            e.stopPropagation();
            handleCloseAddListModal(e);
          }}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle sx={{ fontFamily: theme.typography.fontFamily }}>
            Select a List to Add
          </DialogTitle>
          <DialogContent sx={{ fontFamily: theme.typography.fontFamily }}>
            {listsLoading ? (
              <Box sx={{ textAlign: 'center', marginTop: theme.spacing(2) }}>
                <CircularProgress size={24} />
              </Box>
            ) : addListError ? (
              <Alert severity="error">{addListError}</Alert>
            ) : (
              <List>
                {lists.map((list) => (
                  <ListItem key={list.id} disablePadding>
                    <ListItemButton onClick={(e) => handleAddToList(list.id)(e)}>
                      <ListItemText
                        primary={list.list_name}
                        primaryTypographyProps={{ fontFamily: theme.typography.fontFamily }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
                {lists.length === 0 && (
                  <Typography
                    variant="body2"
                    align="center"
                    sx={{ fontFamily: theme.typography.fontFamily }}
                  >
                    No lists available.
                  </Typography>
                )}
              </List>
            )}
            {addListSuccess && (
              <Alert
                severity="success"
                sx={{ mt: theme.spacing(2), fontFamily: theme.typography.fontFamily }}
              >
                {addListSuccess}
              </Alert>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                handleCloseAddListModal(e);
              }}
              disabled={addingToList}
              sx={{ fontFamily: theme.typography.fontFamily }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Card>

      {/* Divider between Cart Items with reduced thickness */}
      <Divider sx={{ borderColor: '#e0e0e0', marginY: theme.spacing(0), borderWidth: '1px' }} />
    </>
  );
}

export default CartItem;
