// src/Guides.js

import React, { useState, useEffect, useCallback } from 'react';
import TopNavBar from './TopNavBar';
import {
  Box,
  Typography,
  CircularProgress,
  Alert,
  Button,
  Container,
  Grid,
  IconButton,
  Dialog,
  ToggleButton,
  ToggleButtonGroup,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Drawer,
  Card,
  CardContent,
  CardMedia,
  TextField,
  InputBase,
} from '@mui/material';
import {
  Search as SearchIcon,
  Delete as DeleteIcon,
  Close as CloseIcon,
  FilterList as FilterListIcon,
  ViewList as ViewListIcon,
  ViewModule as ViewModuleIcon,
} from '@mui/icons-material';
import { useTheme, styled, alpha } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery'; // Corrected import
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ListProductCard from './ListProductCard'; // Import the ListProductCard component
import AddToCartButton from './AddToCartButton';
import GuidesFilterPanel from './GuidesFilterPanel'; // Import the updated GuidesFilterPanel component
import usePersistedState from './hooks/usePersistedState'; // Import the usePersistedState hook
import { Helmet } from 'react-helmet'; // Import Helmet

// Styled Components for Search Bar
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius * 3,
  border: `1px solid ${theme.palette.primary.main}`,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.25),
  },
  width: '100%',
  marginBottom: theme.spacing(1),
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: theme.spacing(0, 1.5),
  height: '100%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  pointerEvents: 'none',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontFamily: theme.typography.fontFamily,
  fontSize: '0.875rem',
  '& .MuiInputBase-input': {
    padding: theme.spacing(0.5, 0.5, 0.5, 0),
    paddingLeft: `calc(1em + ${theme.spacing(3)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

function Guides() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();

  // Persisted State variables
  const [fareFoodGuides, setFareFoodGuides] = usePersistedState('fareFoodGuides', []);
  const [activeGuide, setActiveGuide] = usePersistedState('activeGuide', null);
  const [guideItems, setGuideItems] = useState([]);
  const [loadingGuideItems, setLoadingGuideItems] = useState(false);
  const [errorGuideItems, setErrorGuideItems] = useState(null);

  // Filter states with persisted state
  const [selectedGuideId, setSelectedGuideId] = usePersistedState('selectedGuideId', '');
  const [categories, setCategories] = useState([]); // Fetch categories from API or define statically
  const [selectedCategory, setSelectedCategory] = usePersistedState('selectedCategory', 0);
  const [subcategories, setSubcategories] = useState([]); // Fetch based on selected category
  const [selectedSubcategory, setSelectedSubcategory] = usePersistedState('selectedSubcategory', 0);
  const [vendors, setVendors] = useState([]); // Vendors associated with the user's restaurant
  const [selectedVendor, setSelectedVendor] = usePersistedState('selectedVendor', null);
  const [brands, setBrands] = useState([]); // Fetch brands from API or define statically
  const [selectedBrands, setSelectedBrands] = usePersistedState('selectedBrands', []);

  const [clearAllFiltersFlag, setClearAllFiltersFlag] = useState(false);

  const [loadingGuides, setLoadingGuides] = useState(true);
  const [errorGuides, setErrorGuides] = useState(null);

  // Pagination state for Guide Items
  const [guideItemsOffset, setGuideItemsOffset] = useState(0);
  const guideItemsLimit = 20; // Number of items to fetch per request
  const [guideItemsHasMore, setGuideItemsHasMore] = useState(true);

  // Modal state for Create a Guide
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [newGuideName, setNewGuideName] = useState('');
  const [creatingGuide, setCreatingGuide] = useState(false);
  const [createGuideError, setCreateGuideError] = useState(null);

  // Snackbar state for toast messages
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // 'success' or 'error' or 'info'

  // State for delete confirmation of entire guides
  const [deletingGuideId, setDeletingGuideId] = useState(null);
  const [deleteGuideLoading, setDeleteGuideLoading] = useState(false);

  // State for delete confirmation of guide items
  const [deletingItemId, setDeletingItemId] = useState(null);
  const [deleteItemLoading, setDeleteItemLoading] = useState(false);

  // State for multiplier
  const [multiplier, setMultiplier] = useState(1);

  // State for userRestaurantId and userId
  const [userRestaurantId, setUserRestaurantId] = useState(null);
  const [userId, setUserId] = useState(null);

  // State for Filter Drawer
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);

  // State for View Mode with persisted state
  const [viewMode, setViewMode] = usePersistedState('viewMode', 'card'); // 'card' or 'list'

  // State for search input with persisted state
  const [searchTerm, setSearchTerm] = usePersistedState('searchTerm', '');
  const [productNameFilter, setProductNameFilter] = usePersistedState('productNameFilter', '');

  // Toggle function for Filter Drawer
  const toggleFilterDrawer = (open) => () => {
    setIsFilterDrawerOpen(open);
  };

  // Fetch user data and multiplier on component mount
  useEffect(() => {
    const fetchUserData = () => {
      const userData = localStorage.getItem('user');
      if (userData) {
        try {
          const parsedUser = JSON.parse(userData);
          setUserRestaurantId(parsedUser.restaurant_id);
          setUserId(parsedUser.id);

          // Retrieve the multiplier from parsedUser
          const storedMultiplier = parseFloat(parsedUser.multiplier) || 1;
          setMultiplier(storedMultiplier);
          console.log('Multiplier retrieved from localStorage:', storedMultiplier);
        } catch (e) {
          console.error('Error parsing user data from local storage:', e);
          setErrorGuides('Invalid user data. Please log in again.');
          setLoadingGuides(false);
        }
      } else {
        setErrorGuides('User not found. Please log in.');
        setLoadingGuides(false);
      }
    };

    fetchUserData();
  }, []);

  // Fetch categories, subcategories, vendors, and brands (Assuming APIs are available)
  useEffect(() => {
    const fetchFilters = async () => {
      try {
        // Fetch Categories
        const categoriesResponse = await axios.get('https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/category', {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        setCategories(categoriesResponse.data || []);

        // Fetch Restaurant Vendors
        const restaurantVendorsResponse = await axios.get('https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/restaurant_vendors', {
          params: { restaurant_id: userRestaurantId },
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const restaurantVendors = restaurantVendorsResponse.data || [];
        const associatedVendors = restaurantVendors.map((rv) => rv._vendor);
        setVendors(associatedVendors);

        // Fetch Brands
        const brandsResponse = await axios.get('https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/brand', {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        setBrands(brandsResponse.data || []);
      } catch (err) {
        console.error('Error fetching filter data:', err);
        // Optionally handle errors for filters
      }
    };

    if (userRestaurantId) {
      fetchFilters();
    }
  }, [userRestaurantId]);

  // Fetch subcategories when selectedCategory changes
  useEffect(() => {
    const fetchSubcategories = async () => {
      if (selectedCategory === 0) {
        setSubcategories([]);
        setSelectedSubcategory(0);
        return;
      }

      try {
        const subcategoriesResponse = await axios.get('https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/subcategory', {
          params: { filter: selectedCategory },
          headers: {
            'Content-Type': 'application/json',
          },
        });
        setSubcategories(subcategoriesResponse.data || []);
      } catch (err) {
        console.error('Error fetching subcategories:', err);
        // Optionally handle errors for subcategories
        setSubcategories([]);
      }
    };

    fetchSubcategories();
  }, [selectedCategory, setSelectedSubcategory]);

  // Fetch FareFood-generated guides when userRestaurantId is available
  useEffect(() => {
    if (!userRestaurantId) return;

    const fetchGuides = async () => {
      try {
        const response = await axios.get(
          'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/lists',
          {
            params: {
              restaurantidfilter: userRestaurantId,
            },
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        let allLists = [];
        if (Array.isArray(response.data)) {
          allLists = response.data;
        } else if (response.data && Array.isArray(response.data.lists)) {
          allLists = response.data.lists;
        } else {
          console.warn('Unexpected API response structure:', response.data);
          setErrorGuides('Unexpected response structure from the server.');
          allLists = [];
        }

        // Filter only FareFood-generated guides (includes 'order guide' or 'deals' in name)
        const guides = allLists.filter(
          (list) =>
            list.list_name.toLowerCase().includes('order guide') ||
            list.list_name.toLowerCase().includes('deals')
        );

        setFareFoodGuides(guides);

        setLoadingGuides(false);
      } catch (err) {
        console.error('Error fetching guides:', err);
        if (err.response && err.response.data && err.response.data.error) {
          setErrorGuides(err.response.data.error);
        } else if (err.message) {
          setErrorGuides(err.message);
        } else {
          setErrorGuides('Failed to fetch guides. Please try again later.');
        }
        setLoadingGuides(false);
      }
    };

    fetchGuides();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRestaurantId]);

  // Synchronize activeGuide with selectedGuideId and fareFoodGuides
  useEffect(() => {
    if (fareFoodGuides.length === 0) {
      setActiveGuide(null);
      setGuideItems([]);
      setGuideItemsOffset(0);
      setGuideItemsHasMore(false);
      return;
    }

    if (selectedGuideId) {
      const guide = fareFoodGuides.find((g) => g.id === selectedGuideId);
      if (guide) {
        setActiveGuide(guide);
        fetchGuideItems(guide.id, 0, true, productNameFilter);
      } else {
        // If selectedGuideId doesn't exist anymore, select the first guide
        setSelectedGuideId(fareFoodGuides[0].id);
        setActiveGuide(fareFoodGuides[0]);
        fetchGuideItems(fareFoodGuides[0].id, 0, true, productNameFilter);
      }
    } else {
      // If no guide is selected, select the first guide by default
      setSelectedGuideId(fareFoodGuides[0].id);
      setActiveGuide(fareFoodGuides[0]);
      fetchGuideItems(fareFoodGuides[0].id, 0, true, productNameFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGuideId, fareFoodGuides]);

  // Fetch guide items when activeGuide or filters or productNameFilter changes
  useEffect(() => {
    if (activeGuide) {
      // Reset guide items state when filters or productNameFilter change
      setGuideItems([]);
      setGuideItemsOffset(0);
      setGuideItemsHasMore(true);
      fetchGuideItems(activeGuide.id, 0, true, productNameFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeGuide,
    selectedCategory,
    selectedSubcategory,
    selectedVendor,
    selectedBrands,
    productNameFilter,
  ]);

  // Fetch guide items function with useCallback to prevent unnecessary re-creations
  const fetchGuideItems = useCallback(
    async (guideId, offset, reset = false, searchTerm = '') => {
      if (!guideId) return;

      setLoadingGuideItems(true);
      setErrorGuideItems(null);

      try {
        const params = {
          lists_id: guideId,
          productnamefilter: searchTerm,
          limit: guideItemsLimit,
          offset: offset,
          category_id: selectedCategory !== 0 ? selectedCategory : undefined,
          subcategory_id: selectedSubcategory !== 0 ? selectedSubcategory : undefined,
          brandfilter: selectedBrands.length > 0 ? selectedBrands.join(',') : undefined,
          vendor_id: selectedVendor || undefined,
          restaurant_id: userRestaurantId, // Added restaurant_id parameter
        };

        const response = await axios.get(
          'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/list_of_items_react_2', // Updated API endpoint
          {
            params,
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        const data = response.data;

        if (data && Array.isArray(data.items)) {
          setGuideItems((prevItems) => (reset ? data.items : [...prevItems, ...data.items]));
          setGuideItemsOffset(offset + data.items.length);
          setGuideItemsHasMore(data.items.length === guideItemsLimit);
        } else {
          console.warn(`Unexpected response structure for guide ${guideId}:`, data);
          setErrorGuideItems('Unexpected response structure from the server.');
          setGuideItemsHasMore(false);
        }
      } catch (err) {
        console.error(`Error fetching items for guide ${guideId}:`, err);
        if (err.response && err.response.data && err.response.data.error) {
          setErrorGuideItems(err.response.data.error);
        } else if (err.message) {
          setErrorGuideItems(err.message);
        } else {
          setErrorGuideItems('Failed to fetch items. Please try again later.');
        }
        setGuideItemsHasMore(false);
      } finally {
        setLoadingGuideItems(false);
      }
    },
    [
      selectedCategory,
      selectedSubcategory,
      selectedVendor,
      selectedBrands,
      guideItemsLimit,
      userRestaurantId, // Added userRestaurantId as dependency
    ]
  );

  // Handle View Mode Change
  const handleViewModeChange = (event, newViewMode) => {
    if (newViewMode !== null) {
      setViewMode(newViewMode);
    }
  };

  // Load more handler for Guide Items
  const loadMoreGuideItems = () => {
    if (selectedGuideId && guideItemsHasMore && !loadingGuideItems) {
      fetchGuideItems(selectedGuideId, guideItemsOffset, false, productNameFilter);
    }
  };

  // Handle Create a Guide Modal
  const openCreateModal = () => {
    setNewGuideName('');
    setCreateGuideError(null);
    setCreateModalOpen(true);
  };

  const closeCreateModal = () => {
    setCreateModalOpen(false);
  };

  const handleCreateGuide = async () => {
    if (!newGuideName.trim()) {
      setCreateGuideError('Guide name cannot be empty.');
      return;
    }

    setCreatingGuide(true);
    setCreateGuideError(null);

    try {
      const response = await axios.post(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/lists',
        {
          list_name: newGuideName.trim(),
          restaurant_id: userRestaurantId,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      // Assuming the API returns the created guide
      const createdGuide = response.data;

      // Update the guides state
      if (createdGuide) {
        setFareFoodGuides((prev) => [...prev, createdGuide]);
        setSelectedGuideId(createdGuide.id);
        setActiveGuide(createdGuide); // Immediately set the new guide as active
        fetchGuideItems(createdGuide.id, 0, true, productNameFilter); // Fetch items for the new guide
      }

      setCreatingGuide(false);
      setCreateModalOpen(false);
      setSnackbarMessage('Guide created successfully.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (err) {
      console.error('Error creating guide:', err);
      if (err.response && err.response.data && err.response.data.error) {
        setCreateGuideError(err.response.data.error);
      } else if (err.message) {
        setCreateGuideError(err.message);
      } else {
        setCreateGuideError('Failed to create guide. Please try again later.');
      }
      setCreatingGuide(false);
    }
  };

  // Handle Snackbar close
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  // Handle Delete Icon Click for Entire Guides
  const handleDeleteGuideClick = (guideId) => {
    setDeletingGuideId(guideId);
  };

  // Handle Confirm Delete for Entire Guides
  const handleConfirmDeleteGuide = async (guideId) => {
    setDeleteGuideLoading(true);
    try {
      await axios.delete(`https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/lists/${guideId}`, {
        data: { lists_id: guideId },
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // Remove the deleted guide from fareFoodGuides
      setFareFoodGuides((prev) => prev.filter((guide) => guide.id !== guideId));

      // If the deleted guide was active, reset activeGuide
      if (selectedGuideId === guideId) {
        const remainingGuides = fareFoodGuides.filter((guide) => guide.id !== guideId);
        if (remainingGuides.length > 0) {
          setSelectedGuideId(remainingGuides[0].id);
          setActiveGuide(remainingGuides[0]);
          fetchGuideItems(remainingGuides[0].id, 0, true, productNameFilter);
        } else {
          setSelectedGuideId('');
          setActiveGuide(null);
          setGuideItems([]);
          setGuideItemsOffset(0);
          setGuideItemsHasMore(false);
        }
      }

      setSnackbarMessage('Guide deleted successfully.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (err) {
      console.error('Error deleting guide:', err);
      let errorMsg = 'Failed to delete guide. Please try again later.';
      if (err.response && err.response.data && err.response.data.error) {
        errorMsg = err.response.data.error;
      } else if (err.message) {
        errorMsg = err.message;
      }
      setSnackbarMessage(errorMsg);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setDeletingGuideId(null);
      setDeleteGuideLoading(false);
    }
  };

  // Handle Cancel Delete for Entire Guides
  const handleCancelDeleteGuide = () => {
    setDeletingGuideId(null);
  };

  // Delete Handler for Guide Items
  const handleDeleteItem = (guideItemId) => {
    handleConfirmDeleteItem(guideItemId);
  };

  // Handle Confirm Delete for Guide Items
  const handleConfirmDeleteItem = async (itemId) => {
    setDeleteItemLoading(true);
    try {
      await axios.delete(`https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/list_item/${itemId}`, {
        data: { list_item_id: itemId },
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // Remove the deleted item from guideItems state
      setGuideItems((prevItems) => prevItems.filter((item) => item.id !== itemId));

      setSnackbarMessage('Item deleted successfully.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (err) {
      console.error('Error deleting item:', err);
      let errorMsg = 'Failed to delete item. Please try again later.';
      if (err.response && err.response.data && err.response.data.error) {
        errorMsg = err.response.data.error;
      } else if (err.message) {
        errorMsg = err.message;
      }
      setSnackbarMessage(errorMsg);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setDeletingItemId(null);
      setDeleteItemLoading(false);
    }
  };

  // Handle Cancel Delete for Guide Items
  const handleCancelDeleteItem = () => {
    setDeletingItemId(null);
  };

  // Handle Filter Panel Clear All Filters
  const handleClearAllFilters = () => {
    setSelectedCategory(0);
    setSelectedSubcategory(0);
    setSelectedVendor(null);
    setSelectedBrands([]);
    setSearchTerm('');
    setProductNameFilter('');
    setClearAllFiltersFlag((prev) => !prev); // Toggle to trigger useEffect if needed
  };

  return (
    <div>
      {/* React Helmet for SEO and Marketing Copy */}
      <Helmet>
        <title>FareFood | Guides</title>
        <meta
          name="description"
          content="Discover FareFood Guides to effortlessly manage your purchase history. Track past orders, quickly reorder your favorite meals, and explore exclusive deals tailored just for you."
        />
        <meta
          name="keywords"
          content="FareFood Guides, purchase history, order tracking, reorder meals, exclusive deals, past orders, meal management, personalized offers"
        />
        <meta property="og:title" content="FareFood | Guides" />
        <meta
          property="og:description"
          content="Manage your FareFood purchases with ease. Use our Guides to track past orders, reorder your favorite meals, and find exclusive deals personalized for you."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.farefood.com/guides" />
        <meta property="og:image:alt" content="FareFood Guides Dashboard" />

        {/* Twitter Card Integration */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="FareFood | Guides" />
        <meta
          name="twitter:description"
          content="Efficiently manage your FareFood purchase history. Track and reorder past orders, and access exclusive deals tailored just for you through our comprehensive Guides."
        />

        {/* Canonical Link */}
        <link rel="canonical" href="https://www.farefood.com/guides" />
      </Helmet>

      {/* Sticky TopNavBar without the title */}
      <Box sx={{ position: 'sticky', top: 0, zIndex: theme.zIndex.appBar }}>
        <TopNavBar /> {/* Removed the title prop */}
      </Box>

      {/* Main Content */}
      {loadingGuides ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: theme.spacing(8) }}>
          <CircularProgress />
        </Box>
      ) : errorGuides ? (
        <Container
          maxWidth="sm"
          sx={{
            textAlign: 'center',
            paddingTop: theme.spacing(8), // Added paddingTop to avoid overlap
          }}
        >
          <Alert severity="error">{errorGuides}</Alert>
        </Container>
      ) : (
        <Container
          maxWidth="lg"
          sx={{
            paddingTop: theme.spacing(8), // Adjusted paddingTop to align with TopNavBar
          }}
        >
          {/* Title */}
          <Typography
            variant="h5"
            sx={{
              fontFamily: theme.typography.fontFamily,
              paddingTop: theme.spacing(4),
              marginBottom: theme.spacing(2),
              color: theme.palette.primary.main, // Ensuring primary color for consistency
            }}
          >
            {activeGuide ? activeGuide.list_name : 'Select a Guide to View Items'}
          </Typography>

          {/* Main Grid */}
          <Grid container>
            {/* Left Panel for Desktop */}
            {isDesktop && (
              <Grid
                item
                sm={3}
                md={2}
                sx={{
                  // borderRight: `1px solid ${theme.palette.divider}`, // Removed to eliminate vertical line
                  minHeight: 'calc(100vh - 64px)', // Adjusted to account for TopNavBar height
                  padding: theme.spacing(2),
                  backgroundColor: theme.palette.background.paper,
                  position: 'sticky',
                  top: theme.spacing(8), // Align with paddingTop of Container
                  overflowY: 'auto',
                }}
              >
                {/* Filters for Desktop */}
                <Box sx={{ marginTop: theme.spacing(4) }}>
                  <GuidesFilterPanel
                    guides={fareFoodGuides}
                    selectedGuideId={selectedGuideId}
                    setSelectedGuideId={(id) => setSelectedGuideId(Number(id))}
                    categories={categories}
                    selectedCategory={selectedCategory}
                    setSelectedCategory={setSelectedCategory}
                    subcategories={subcategories}
                    selectedSubcategory={selectedSubcategory}
                    setSelectedSubcategory={setSelectedSubcategory}
                    vendors={vendors}
                    selectedVendor={selectedVendor}
                    setSelectedVendor={(id) => setSelectedVendor(id !== null ? Number(id) : null)}
                    brands={brands}
                    selectedBrands={selectedBrands}
                    setSelectedBrands={setSelectedBrands}
                    clearAllFilters={handleClearAllFilters}
                  />
                </Box>
              </Grid>
            )}

            {/* Main Viewing Area */}
            <Grid
              item
              xs={12}
              sm={isDesktop ? 9 : 12}
              md={isDesktop ? 10 : 12}
              sx={{
                padding: theme.spacing(2),
                minHeight: 'calc(100vh - 64px)', // Adjusted to account for TopNavBar height
              }}
            >
              {/* Header Section with Controls and Search */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: isMobile ? 'column' : 'row',
                  alignItems: isMobile ? 'flex-start' : 'center',
                  justifyContent: 'space-between',
                  paddingBottom: theme.spacing(2),
                  gap: theme.spacing(2),
                }}
              >
                {/* Controls and Title Row */}
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    justifyContent: isMobile ? 'space-between' : 'flex-start',
                    flexWrap: 'wrap',
                    gap: theme.spacing(1),
                  }}
                >
                  {/* Controls: View Toggle and Delete Button */}
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: theme.spacing(1),
                    }}
                  >
                    {/* View Toggle Button */}
                    <ToggleButtonGroup
                      value={viewMode}
                      exclusive
                      onChange={handleViewModeChange}
                      aria-label="view mode"
                      size="small"
                    >
                      <ToggleButton value="list" aria-label="list view">
                        <ViewListIcon />
                      </ToggleButton>
                      <ToggleButton value="card" aria-label="card view">
                        <ViewModuleIcon />
                      </ToggleButton>
                    </ToggleButtonGroup>

                    {/* Delete Active Guide Button */}
                    {activeGuide && (
                      <IconButton
                        onClick={() => handleDeleteGuideClick(activeGuide.id)}
                        sx={{
                          color: theme.palette.error.main,
                          backgroundColor: alpha(theme.palette.error.main, 0.1),
                          '&:hover': {
                            backgroundColor: alpha(theme.palette.error.main, 0.2),
                          },
                        }}
                        aria-label="delete guide"
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}

                    {/* Filter Icon for Mobile */}
                    {!isDesktop && (
                      <IconButton
                        aria-label="open filter"
                        onClick={toggleFilterDrawer(true)}
                        sx={{
                          color: theme.palette.primary.main,
                          backgroundColor: theme.palette.background.paper,
                          border: `1px solid ${theme.palette.primary.main}`,
                          '&:hover': {
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                          },
                        }}
                      >
                        <FilterListIcon />
                      </IconButton>
                    )}

                    {/* Create a Guide Button for Mobile */}
                    
                  </Box>
                </Box>

                {/* Search Bar Positioned Below Controls on Mobile */}
                <Box
                  sx={{
                    width: '100%',
                    display: isMobile ? 'block' : 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Search sx={{ width: isMobile ? '100%' : 'auto' }}>
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder="Search Items…"
                      inputProps={{ 'aria-label': 'search items' }}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          setProductNameFilter(searchTerm.trim());
                        }
                      }}
                    />
                  </Search>
                </Box>
              </Box>

              {/* Display Content Based on Active Guide */}
              {activeGuide ? (
                // Display Guide Items (FareFood Guides)
                <>
                  {loadingGuideItems && guideItemsOffset === 0 ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: theme.spacing(2.5) }}>
                      <CircularProgress />
                    </Box>
                  ) : errorGuideItems ? (
                    <Box sx={{ marginTop: theme.spacing(2.5) }}>
                      <Alert severity="error">{errorGuideItems}</Alert>
                    </Box>
                  ) : guideItems.length > 0 ? (
                    <>
                      {viewMode === 'card' ? (
                        <Grid container spacing={2}>
                          {guideItems.map((item) => {
                            const product = item._product;

                            if (!product) {
                              console.warn(`Item with id ${item.id} is missing _product.`);
                              return null;
                            }

                            // Extract purchase_price from the nested field
                            const purchasePrice = product._restaurant_products_pricing_of_product_test
                              ? product._restaurant_products_pricing_of_product_test.purchase_price
                              : product.purchase_price; // Fallback if the new field is missing

                            return (
                              <Grid item xs={6} sm={4} md={2} key={item.id}>
                                <ListProductCard
                                  product={product}
                                  multiplier={multiplier}
                                  showDelete={true}
                                  onDelete={handleDeleteItem} // Pass the delete handler
                                  listItemId={item.id} // Pass the list_item_id
                                  purchasePrice={purchasePrice} // Pass the correct purchase price
                                />
                              </Grid>
                            );
                          })}
                        </Grid>
                      ) : (
                        // List View
                        <Box>
                          {guideItems.map((item) => {
                            const product = item._product;

                            if (!product) {
                              console.warn(`Item with id ${item.id} is missing _product.`);
                              return null;
                            }

                            // Extract purchase_price from the nested field
                            const purchasePrice = product._restaurant_products_pricing_of_product_test
                              ? product._restaurant_products_pricing_of_product_test.purchase_price
                              : product.purchase_price; // Fallback if the new field is missing

                            return (
                              <Card
                                key={item.id}
                                sx={{
                                  display: 'flex',
                                  marginBottom: theme.spacing(2),
                                  cursor: 'pointer',
                                  position: 'relative',
                                  '&:hover': {
                                    boxShadow: theme.shadows[4],
                                  },
                                }}
                                onClick={() => {
                                  navigate(`/product/${product.id}`);
                                }}
                              >
                                {product.Image_URL ? (
                                  <CardMedia
                                    component="img"
                                    sx={{ width: 120, height: 120, objectFit: 'contain' }}
                                    image={product.Image_URL}
                                    alt={product.short_description}
                                  />
                                ) : (
                                  <Box
                                    sx={{
                                      width: 120,
                                      height: 120,
                                      backgroundColor: '#f0f0f0',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <Typography variant="subtitle1">No Image Available</Typography>
                                  </Box>
                                )}

                                <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                  <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography
                                      variant="subtitle1"
                                      sx={{
                                        fontFamily: theme.typography.fontFamily,
                                        color: theme.palette.text.secondary,
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      {product.brand}
                                    </Typography>

                                    <Typography
                                      variant="body1"
                                      sx={{
                                        fontFamily: theme.typography.fontFamily,
                                        marginBottom: '8px',
                                        display: '-webkit-box',
                                        WebkitLineClamp: 2,
                                        WebkitBoxOrient: 'vertical',
                                        overflow: 'hidden',
                                      }}
                                    >
                                      {product.short_description}
                                    </Typography>

                                    {/* Price and AddToCartButton */}
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginTop: 'auto',
                                      }}
                                    >
                                      <Typography
                                        variant="h6"
                                        sx={{
                                          fontSize: '1rem',
                                          fontWeight: theme.typography.h6.fontWeight,
                                          fontFamily: theme.typography.fontFamily,
                                          marginRight: theme.spacing(1),
                                        }}
                                      >
                                        {purchasePrice > 0
                                          ? `$${(purchasePrice * multiplier).toFixed(2)}`
                                          : 'Contact us'}
                                      </Typography>

                                      {purchasePrice > 0 && (
                                        <Box
                                          sx={{
                                            marginLeft: 'auto',
                                            display: 'flex',
                                            alignItems: 'center',
                                          }}
                                          onClick={(e) => e.stopPropagation()} // Prevent card's onClick
                                        >
                                          <AddToCartButton product={product} multiplier={multiplier} />
                                        </Box>
                                      )}
                                    </Box>
                                  </CardContent>
                                </Box>

                                {/* Vendor Logo Positioned at Upper Right of Image */}
                                {product._vendor?.Logo_URL && (
                                  <Box
                                    sx={{
                                      position: 'absolute',
                                      top: theme.spacing(2),
                                      right: theme.spacing(2),
                                      width: 40,
                                      height: 40,
                                      borderRadius: '50%',
                                      backgroundColor: theme.palette.background.paper,
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      boxShadow: theme.shadows[1],
                                    }}
                                  >
                                    <img
                                      src={product._vendor.Logo_URL}
                                      alt={product._vendor.Short_Name}
                                      style={{
                                        width: '80%',
                                        height: '80%',
                                        objectFit: 'contain',
                                        borderRadius: '50%',
                                      }}
                                      onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = '/assets/default-logo.png'; // Update with actual default logo path
                                      }}
                                    />
                                  </Box>
                                )}

                                {/* Delete Button */}
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteItem(item.id);
                                  }}
                                  sx={{ position: 'absolute', top: theme.spacing(1), left: theme.spacing(1) }}
                                  aria-label="delete item"
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Card>
                            );
                          })}
                        </Box>
                      )}

                      {/* Load More Button for Guide Items */}
                      {guideItemsHasMore && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: theme.spacing(2.5) }}>
                          {loadingGuideItems ? (
                            <CircularProgress />
                          ) : (
                            <Button
                              variant="contained"
                              onClick={loadMoreGuideItems}
                              sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.contrastText,
                                '&:hover': {
                                  backgroundColor: theme.palette.primary.dark,
                                },
                              }}
                            >
                              Load More
                            </Button>
                          )}
                        </Box>
                      )}
                    </>
                  ) : (
                    <Typography
                      variant="body1"
                      sx={{
                        textAlign: 'center',
                        marginTop: theme.spacing(2.5),
                        fontFamily: theme.typography.fontFamily,
                        fontSize: '1rem',
                      }}
                    >
                      No items found in this guide.
                    </Typography>
                  )}
                </>
              ) : (
                // Default Content when no guide is active
                <Typography
                  variant="body1"
                  sx={{
                    textAlign: 'center',
                    marginTop: theme.spacing(2.5),
                    fontFamily: theme.typography.fontFamily,
                    fontSize: '1rem',
                  }}
                >
                  Select a guide to view items.
                </Typography>
              )}
            </Grid>
          </Grid>
        </Container>
      )}

      {/* Filter Drawer for Mobile */}
      <Drawer
        anchor="right"
        open={isFilterDrawerOpen}
        onClose={toggleFilterDrawer(false)}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <Box
          sx={{
            width: 250,
            padding: theme.spacing(2),
          }}
          role="presentation"
        >
          {/* Close Button */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton onClick={toggleFilterDrawer(false)} aria-label="close filter">
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Filter Panel */}
          <GuidesFilterPanel
            guides={fareFoodGuides}
            selectedGuideId={selectedGuideId}
            setSelectedGuideId={(id) => setSelectedGuideId(Number(id))}
            categories={categories}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            subcategories={subcategories}
            selectedSubcategory={selectedSubcategory}
            setSelectedSubcategory={setSelectedSubcategory}
            vendors={vendors}
            selectedVendor={selectedVendor}
            setSelectedVendor={(id) => setSelectedVendor(id !== null ? Number(id) : null)}
            brands={brands}
            selectedBrands={selectedBrands}
            setSelectedBrands={setSelectedBrands}
            clearAllFilters={handleClearAllFilters}
          />
        </Box>
      </Drawer>

      {/* Create a Guide Modal */}
      <Dialog open={createModalOpen} onClose={closeCreateModal} fullWidth maxWidth="sm">
        <DialogTitle>Create a New Guide</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Guide Name"
            type="text"
            fullWidth
            variant="outlined"
            value={newGuideName}
            onChange={(e) => setNewGuideName(e.target.value)}
            disabled={creatingGuide}
          />
          {createGuideError && (
            <Alert severity="error" sx={{ marginTop: theme.spacing(1) }}>
              {createGuideError}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeCreateModal} disabled={creatingGuide}>
            Cancel
          </Button>
          <Button
            onClick={handleCreateGuide}
            variant="contained"
            disabled={creatingGuide}
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
              },
            }}
          >
            {creatingGuide ? <CircularProgress size={24} /> : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for Toast Messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        ContentProps={{
          sx: {
            backgroundColor:
              snackbarSeverity === 'success'
                ? theme.palette.success.main
                : snackbarSeverity === 'error'
                ? theme.palette.error.main
                : theme.palette.info.main,
            color: theme.palette.common.white,
          },
        }}
      />

      {/* Delete Confirmation Dialog for Guides */}
      <Dialog
        open={Boolean(deletingGuideId)}
        onClose={handleCancelDeleteGuide}
        aria-labelledby="delete-guide-dialog-title"
        aria-describedby="delete-guide-dialog-description"
      >
        <DialogTitle id="delete-guide-dialog-title">Delete Guide</DialogTitle>
        <DialogContent>
          <Typography id="delete-guide-dialog-description">
            Are you sure you want to delete this guide? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDeleteGuide} disabled={deleteGuideLoading}>
            Cancel
          </Button>
          <Button
            onClick={() => handleConfirmDeleteGuide(deletingGuideId)}
            variant="contained"
            color="error"
            disabled={deleteGuideLoading}
            startIcon={deleteGuideLoading ? <CircularProgress size={20} /> : null}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Guides;
