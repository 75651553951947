// src/Lists.js

import React, { useState, useEffect, useCallback } from 'react';
import TopNavBar from './TopNavBar';
import {
  Box,
  Typography,
  CircularProgress,
  Alert,
  Button,
  InputBase,
  Card,
  CardMedia,
  CardContent,
  Container,
  Grid,
  Divider,
  IconButton,
  Dialog,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Drawer,
} from '@mui/material';
import {
  Search as SearchIcon,
  Delete as DeleteIcon,
  Close as CloseIcon,
  FilterList as FilterListIcon,
  ViewList as ViewListIcon,
  ViewModule as ViewModuleIcon,
} from '@mui/icons-material';
import { useTheme, styled, alpha } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AddToCartButton from './AddToCartButton'; // Ensure this component handles event.stopPropagation()
import ListProductCard from './ListProductCard';
import ListsFilterPanel from './ListsFilterPanel'; // Import the updated filter panel
import usePersistedState from './hooks/usePersistedState'; // Import the custom hook
import { Helmet } from 'react-helmet'; // Import Helmet

// Styled Components for Search Bar
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius * 3,
  border: `1px solid ${theme.palette.primary.main}`,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.25),
  },
  width: '100%',
  marginBottom: theme.spacing(1),
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: theme.spacing(0, 1.5),
  height: '100%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  pointerEvents: 'none',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontFamily: theme.typography.fontFamily,
  fontSize: '0.875rem',
  '& .MuiInputBase-input': {
    padding: theme.spacing(0.5, 0.5, 0.5, 0),
    paddingLeft: `calc(1em + ${theme.spacing(3)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

function Lists() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();

  // State variables with persistence
  const [selectedListId, setSelectedListId] = usePersistedState('selectedListId', null, JSON.parse);
  const [selectedCategory, setSelectedCategory] = usePersistedState('selectedCategory', 0, JSON.parse);
  const [selectedSubcategory, setSelectedSubcategory] = usePersistedState('selectedSubcategory', 0, JSON.parse);
  const [selectedVendor, setSelectedVendor] = usePersistedState('selectedVendor', null, JSON.parse);
  const [selectedBrands, setSelectedBrands] = usePersistedState('selectedBrands', [], JSON.parse);
  const [listSearchInput, setListSearchInput] = usePersistedState('listSearchInput', '', JSON.parse);
  const [listSearchTerm, setListSearchTerm] = usePersistedState('listSearchTerm', '', JSON.parse);

  // Other state variables (non-persistent)
  const [userLists, setUserLists] = useState([]);
  const [activeList, setActiveList] = useState(null);
  const [listItems, setListItems] = useState([]);
  const [loadingListItems, setLoadingListItems] = useState(false);
  const [errorListItems, setErrorListItems] = useState(null);

  const [categories, setCategories] = useState([]); // Fetch categories from API or define statically
  const [subcategories, setSubcategories] = useState([]); // Fetch based on selected category
  const [vendors, setVendors] = useState([]); // Vendors associated with the user's restaurant
  const [brands, setBrands] = useState([]); // Fetch brands from API or define statically

  const [clearAllFiltersFlag, setClearAllFiltersFlag] = useState(false);

  const [loadingLists, setLoadingLists] = useState(true);
  const [errorLists, setErrorLists] = useState(null);

  // Pagination state for List Items
  const [listItemsOffset, setListItemsOffset] = useState(0);
  const listItemsLimit = 20; // Number of items to fetch per request
  const [listItemsHasMore, setListItemsHasMore] = useState(true);

  // Modal state for Create a List
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [newListName, setNewListName] = useState('');
  const [creatingList, setCreatingList] = useState(false);
  const [createListError, setCreateListError] = useState(null);

  // Snackbar state for toast messages
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // 'success' or 'error' or 'info'

  // State for delete confirmation of entire lists
  const [deletingListId, setDeletingListId] = useState(null);
  const [deleteListLoading, setDeleteListLoading] = useState(false);

  // State for delete confirmation of list items
  const [deletingItemId, setDeletingItemId] = useState(null);
  const [deleteItemLoading, setDeleteItemLoading] = useState(false);

  // State for multiplier
  const [multiplier, setMultiplier] = useState(1);

  // State for userRestaurantId and userId
  const [userRestaurantId, setUserRestaurantId] = useState(null);
  const [userId, setUserId] = useState(null);

  // State for Filter Drawer
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);

  // State for View Mode
  const [viewMode, setViewMode] = useState('card'); // 'card' or 'list'

  // Toggle function for Filter Drawer
  const toggleFilterDrawer = (open) => () => {
    setIsFilterDrawerOpen(open);
  };

  // State to track if initial fetch is complete
  const [initialFetchComplete, setInitialFetchComplete] = useState(false);

  // Fetch user data and multiplier on component mount
  useEffect(() => {
    const fetchUserData = () => {
      const userData = localStorage.getItem('user');
      if (userData) {
        try {
          const parsedUser = JSON.parse(userData);
          setUserRestaurantId(parsedUser.restaurant_id);
          setUserId(parsedUser.id);

          // Retrieve the multiplier from parsedUser
          const storedMultiplier = parseFloat(parsedUser.multiplier) || 1;
          setMultiplier(storedMultiplier);
          console.log('Multiplier retrieved from localStorage:', storedMultiplier);
        } catch (e) {
          console.error('Error parsing user data from local storage:', e);
          setErrorLists('Invalid user data. Please log in again.');
          setLoadingLists(false);
        }
      } else {
        setErrorLists('User not found. Please log in.');
        setLoadingLists(false);
      }
    };

    fetchUserData();
  }, []);

  // Fetch categories, subcategories, vendors, and brands (Assuming APIs are available)
  useEffect(() => {
    const fetchFilters = async () => {
      if (!userRestaurantId) return;

      try {
        // Fetch Categories
        const categoriesResponse = await axios.get('https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/category', {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        setCategories(categoriesResponse.data || []);

        // Fetch Restaurant Vendors
        const restaurantVendorsResponse = await axios.get(
          'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/restaurant_vendors',
          {
            params: { restaurant_id: userRestaurantId },
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        const restaurantVendors = restaurantVendorsResponse.data || [];
        const associatedVendors = restaurantVendors.map((rv) => rv._vendor);
        setVendors(associatedVendors);

        // Fetch Brands
        const brandsResponse = await axios.get('https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/brand', {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        setBrands(brandsResponse.data || []);
      } catch (err) {
        console.error('Error fetching filter data:', err);
        // Optionally handle errors for filters
      }
    };

    if (userRestaurantId) {
      fetchFilters();
    }
  }, [userRestaurantId]);

  // Fetch subcategories when selectedCategory changes
  useEffect(() => {
    const fetchSubcategories = async () => {
      if (selectedCategory === 0) {
        setSubcategories([]);
        setSelectedSubcategory(0);
        return;
      }

      try {
        const subcategoriesResponse = await axios.get('https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/subcategory', {
          params: { filter: selectedCategory },
          headers: {
            'Content-Type': 'application/json',
          },
        });
        setSubcategories(subcategoriesResponse.data || []);
      } catch (err) {
        console.error('Error fetching subcategories:', err);
        // Optionally handle errors for subcategories
        setSubcategories([]);
      }
    };

    fetchSubcategories();
  }, [selectedCategory, setSelectedSubcategory]);

  // Fetch user-generated lists when userRestaurantId is available or listSearchTerm changes
  useEffect(() => {
    if (!userRestaurantId) return;

    const fetchLists = async () => {
      try {
        const params = {
          restaurantidfilter: userRestaurantId,
        };

        if (listSearchTerm !== '') {
          params.productnamefilter = listSearchTerm;
        }

        const response = await axios.get('https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/lists', {
          params: params,
          headers: {
            'Content-Type': 'application/json',
          },
        });

        let allLists = [];
        if (Array.isArray(response.data)) {
          allLists = response.data;
        } else if (response.data && Array.isArray(response.data.lists)) {
          allLists = response.data.lists;
        } else {
          console.warn('Unexpected API response structure:', response.data);
          setErrorLists('Unexpected response structure from the server.');
          allLists = [];
        }

        // Filter only user-generated lists (excluding Guides)
        const userGenerated = allLists.filter(
          (list) =>
            !list.list_name.toLowerCase().includes('order guide') &&
            !list.list_name.toLowerCase().includes('deals')
        );

        setUserLists(userGenerated);

        // **Updated Logic to Retain Active List if Exists**
        const activeListStillExists = userGenerated.find((list) => list.id === selectedListId);

        if (userGenerated.length > 0) {
          if (activeListStillExists) {
            setActiveList(activeListStillExists);
            // Trigger fetching items for this list
            fetchListItems(activeListStillExists.id, 0, true, listSearchTerm);
          } else {
            // If the active list no longer exists, set the first list as active
            setSelectedListId(userGenerated[0].id);
            setActiveList(userGenerated[0]);
            // Trigger fetching items for the new active list
            fetchListItems(userGenerated[0].id, 0, true, listSearchTerm);
          }
        } else {
          setSelectedListId(null);
          setActiveList(null);
          setListItems([]);
          setListItemsOffset(0);
          setListItemsHasMore(false);
        }

        setLoadingLists(false);
        setInitialFetchComplete(true);
      } catch (err) {
        console.error('Error fetching user lists:', err);
        if (err.response && err.response.data && err.response.data.error) {
          setErrorLists(err.response.data.error);
        } else if (err.message) {
          setErrorLists(err.message);
        } else {
          setErrorLists('Failed to fetch lists. Please try again later.');
        }
        setLoadingLists(false);
        setInitialFetchComplete(true);
      }
    };

    fetchLists();
  }, [userRestaurantId, listSearchTerm, selectedListId, setSelectedListId]);

  // Fetch list items when selectedListId changes
  // Note: This useEffect might not be necessary anymore because we're fetching items directly after setting activeList
  /*
  useEffect(() => {
    if (selectedListId !== null) {
      const selected = userLists.find((list) => list.id === selectedListId);
      setActiveList(selected || null);
      setListItems([]);
      setListItemsOffset(0);
      setListItemsHasMore(true);
      fetchListItems(selectedListId, 0, true, listSearchTerm);
    } else {
      setActiveList(null);
      setListItems([]);
      setListItemsOffset(0);
      setListItemsHasMore(false);
    }
  }, [selectedListId]);
  */

  // Debounce the search term to prevent excessive API calls (Optional)
  useEffect(() => {
    const handler = setTimeout(() => {
      if (activeList) {
        // Reset list items state when filters change
        setListItems([]);
        setListItemsOffset(0);
        setListItemsHasMore(true);
        fetchListItems(activeList.id, 0, true, listSearchTerm);
      }
    }, 500); // 500ms debounce delay

    return () => {
      clearTimeout(handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedCategory,
    selectedSubcategory,
    selectedVendor,
    selectedBrands,
    clearAllFiltersFlag,
    listSearchTerm,
  ]);

  // Function to fetch list items
  const fetchListItems = useCallback(async (listId, offset, reset = false, searchTerm = '') => {
    if (!listId) return;

    setLoadingListItems(true);
    setErrorListItems(null);

    try {
      const params = {
        lists_id: listId,
        productnamefilter: searchTerm,
        limit: listItemsLimit,
        offset: offset,
        category_id: selectedCategory !== 0 ? selectedCategory : undefined,
        subcategory_id: selectedSubcategory !== 0 ? selectedSubcategory : undefined,
        brandfilter: selectedBrands.length > 0 ? selectedBrands.join(',') : undefined,
        vendor_id: selectedVendor || undefined,
        restaurant_id: userRestaurantId, // Include restaurant_id
      };

      const response = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/list_of_items_react_2',
        {
          params,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      const data = response.data;

      if (data && Array.isArray(data.items)) {
        setListItems((prevItems) => (reset ? data.items : [...prevItems, ...data.items]));
        setListItemsOffset(offset + data.items.length);
        setListItemsHasMore(data.items.length === listItemsLimit);
      } else {
        console.warn(`Unexpected response structure for list ${listId}:`, data);
        setErrorListItems('Unexpected response structure from the server.');
        setListItemsHasMore(false);
      }
    } catch (err) {
      console.error(`Error fetching items for list ${listId}:`, err);
      if (err.response && err.response.data && err.response.data.error) {
        setErrorListItems(err.response.data.error);
      } else if (err.message) {
        setErrorListItems(err.message);
      } else {
        setErrorListItems('Failed to fetch items. Please try again later.');
      }
      setListItemsHasMore(false);
    } finally {
      setLoadingListItems(false);
    }
  }, [
    selectedCategory,
    selectedSubcategory,
    selectedVendor,
    selectedBrands,
    userRestaurantId,
    listItemsLimit,
  ]);

  // Handle View Mode Change
  const handleViewModeChange = (event, newViewMode) => {
    if (newViewMode !== null) {
      setViewMode(newViewMode);
    }
  };

  // Load more handler for List Items
  const loadMoreListItems = () => {
    if (activeList && listItemsHasMore && !loadingListItems) {
      fetchListItems(activeList.id, listItemsOffset, false, listSearchTerm);
    }
  };

  // Handle Create a List Modal
  const openCreateModal = () => {
    setNewListName('');
    setCreateListError(null);
    setCreateModalOpen(true);
  };

  const closeCreateModal = () => {
    setCreateModalOpen(false);
  };

  const handleCreateList = async () => {
    if (!newListName.trim()) {
      setCreateListError('List name cannot be empty.');
      return;
    }

    setCreatingList(true);
    setCreateListError(null);

    try {
      const response = await axios.post(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/lists',
        {
          list_name: newListName.trim(),
          restaurant_id: userRestaurantId,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      // Assuming the API returns the created list
      const createdList = response.data;

      // Update the lists state
      if (createdList) {
        setUserLists((prev) => [...prev, createdList]);
        setSelectedListId(createdList.id);
        setActiveList(createdList); // Immediately set the new list as active
        fetchListItems(createdList.id, 0, true, listSearchTerm); // Fetch items for the new list
      }

      setCreatingList(false);
      setCreateModalOpen(false);
      setSnackbarMessage('List created successfully.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (err) {
      console.error('Error creating list:', err);
      if (err.response && err.response.data && err.response.data.error) {
        setCreateListError(err.response.data.error);
      } else if (err.message) {
        setCreateListError(err.message);
      } else {
        setCreateListError('Failed to create list. Please try again later.');
      }
      setCreatingList(false);
    }
  };

  // Handle Snackbar close
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  // Handle Delete Icon Click for Entire Lists
  const handleDeleteListClick = (listId) => {
    setDeletingListId(listId);
  };

  // Handle Confirm Delete for Entire Lists
  const handleConfirmDeleteList = async (listId) => {
    setDeleteListLoading(true);
    try {
      await axios.delete(`https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/lists/${listId}`, {
        data: { lists_id: listId },
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // Remove the deleted list from userLists
      setUserLists((prev) => prev.filter((list) => list.id !== listId));

      // If the deleted list was active, reset activeList
      if (selectedListId === listId) {
        const remainingLists = userLists.filter((list) => list.id !== listId);
        if (remainingLists.length > 0) {
          setSelectedListId(remainingLists[0].id);
          setActiveList(remainingLists[0]);
          fetchListItems(remainingLists[0].id, 0, true, listSearchTerm);
        } else {
          setSelectedListId(null);
          setActiveList(null);
          setListItems([]);
          setListItemsOffset(0);
          setListItemsHasMore(false);
        }
      }

      setSnackbarMessage('List deleted successfully.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (err) {
      console.error('Error deleting list:', err);
      let errorMsg = 'Failed to delete list. Please try again later.';
      if (err.response && err.response.data && err.response.data.error) {
        errorMsg = err.response.data.error;
      } else if (err.message) {
        errorMsg = err.message;
      }
      setSnackbarMessage(errorMsg);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setDeletingListId(null);
      setDeleteListLoading(false);
    }
  };

  // Handle Cancel Delete for Entire Lists
  const handleCancelDeleteList = () => {
    setDeletingListId(null);
  };

  // Delete Handler for List Items
  const handleDeleteProduct = (listItemId) => {
    handleConfirmDeleteItem(listItemId);
  };

  // Handle Confirm Delete for List Items
  const handleConfirmDeleteItem = async (itemId) => {
    setDeleteItemLoading(true);
    try {
      await axios.delete(`https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/list_item/${itemId}`, {
        data: { list_item_id: itemId },
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // Remove the deleted item from listItems state
      setListItems((prevItems) => prevItems.filter((item) => item.id !== itemId));

      setSnackbarMessage('Item deleted successfully.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (err) {
      console.error('Error deleting item:', err);
      let errorMsg = 'Failed to delete item. Please try again later.';
      if (err.response && err.response.data && err.response.data.error) {
        errorMsg = err.response.data.error;
      } else if (err.message) {
        errorMsg = err.message;
      }
      setSnackbarMessage(errorMsg);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setDeletingItemId(null);
      setDeleteItemLoading(false);
    }
  };

  // Ensure that clearAllFilters also clears the persisted states
  const clearAllFilters = () => {
    setSelectedCategory(0);
    setSelectedSubcategory(0);
    setSelectedVendor(null); // Reset selectedVendor
    setSelectedBrands([]);
    setListSearchInput(''); // Reset search input
    setListSearchTerm(''); // Reset search term
    setClearAllFiltersFlag((prev) => !prev); // Toggle to trigger useEffect
  };

  return (
    <div>
      <Helmet>
        <title>FareFood | Lists</title>
        <meta
          name="description"
          content="Organize and manage your FareFood lists with ease. Whether it's your favorite meals, grocery items, or special offers, our Lists feature helps you stay organized and make the most of your FareFood experience."
        />
        <meta
          name="keywords"
          content="FareFood Lists, manage lists, favorite meals, grocery organization, special offers, meal planning, personalized lists, food management"
        />
        <meta property="og:title" content="FareFood | Lists" />
        <meta
          property="og:description"
          content="Effortlessly organize your favorite meals, groceries, and special offers with FareFood Lists. Stay organized, streamline your orders, and enjoy personalized deals tailored just for you."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.farefood.com/lists" />
        <meta property="og:image:alt" content="FareFood Lists Dashboard" />
        <meta property="og:site_name" content="FareFood" />

        {/* Twitter Card Integration */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="FareFood | Lists" />
        <meta
          name="twitter:description"
          content="Keep your FareFood organized with our Lists feature. Easily manage your favorite meals, track grocery items, and access exclusive offers all in one place."
        />

        {/* Canonical Link */}
        <link rel="canonical" href="https://www.farefood.com/lists" />
      </Helmet>

      {/* Sticky TopNavBar without the title */}
      <Box sx={{ position: 'sticky', top: 0, zIndex: theme.zIndex.appBar }}>
        <TopNavBar /> {/* Removed the title prop */}
      </Box>

      {/* Main Content */}
      {loadingLists ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: theme.spacing(8) }}>
          <CircularProgress />
        </Box>
      ) : errorLists ? (
        <Container
          maxWidth="sm"
          sx={{
            textAlign: 'center',
            paddingTop: theme.spacing(8), // Added paddingTop to avoid overlap
          }}
        >
          <Alert severity="error">{errorLists}</Alert>
        </Container>
      ) : (
        <Container
          maxWidth="lg"
          sx={{
            paddingTop: theme.spacing(8), // Added paddingTop to align with TopNavBar
          }}
        >
          {/* Title */}
          <Typography
            variant="h5"
            sx={{
              fontFamily: theme.typography.fontFamily,
              marginBottom: theme.spacing(2),
              paddingTop: theme.spacing(4),
              color: 'primary.main', // Ensuring primary color for consistency
            }}
          >
            {activeList ? activeList.list_name : 'Select a List to View Items'}
          </Typography>

          {/* Main Grid */}
          <Grid container>
            {/* Left Panel for Desktop */}
            {isDesktop && (
              <Grid
                item
                sm={3}
                md={2}
                sx={{
                  // borderRight: `1px solid ${theme.palette.divider}`, // Removed to eliminate vertical line
                  minHeight: 'calc(100vh - 64px)', // Adjusted to account for TopNavBar height
                  padding: theme.spacing(2),
                  backgroundColor: theme.palette.background.paper,
                  position: 'sticky',
                  top: theme.spacing(8), // Align with paddingTop of Container
                  overflowY: 'auto',
                }}
              >
                {/* Create a List Button */}
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    marginBottom: theme.spacing(2),
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    fontFamily: theme.typography.fontFamily,
                    '&:hover': {
                      backgroundColor: theme.palette.primary.dark,
                    },
                  }}
                  onClick={openCreateModal} // Open modal
                >
                  Create a List
                </Button>

                <Divider sx={{ marginBottom: theme.spacing(2) }} />

                {/* Filters for Desktop */}
                <Box sx={{ marginTop: theme.spacing(4) }}>
                  <ListsFilterPanel
                    lists={userLists}
                    selectedListId={selectedListId}
                    setSelectedListId={(id) => setSelectedListId(Number(id))}
                    categories={categories}
                    selectedCategory={selectedCategory}
                    setSelectedCategory={setSelectedCategory}
                    subcategories={subcategories}
                    selectedSubcategory={selectedSubcategory}
                    setSelectedSubcategory={setSelectedSubcategory}
                    vendors={vendors}
                    selectedVendor={selectedVendor}
                    setSelectedVendor={(id) => setSelectedVendor(id !== null ? Number(id) : null)}
                    brands={brands}
                    selectedBrands={selectedBrands}
                    setSelectedBrands={setSelectedBrands}
                    clearAllFilters={clearAllFilters}
                  />
                </Box>
              </Grid>
            )}

            {/* Main Viewing Area */}
            <Grid
              item
              xs={12}
              sm={isDesktop ? 9 : 12}
              md={isDesktop ? 10 : 12}
              sx={{
                padding: theme.spacing(2),
                minHeight: 'calc(100vh - 64px)', // Adjusted to account for TopNavBar height
              }}
            >
              {/* Header Section with Controls and Search */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: isMobile ? 'column' : 'row',
                  alignItems: isMobile ? 'flex-start' : 'center',
                  justifyContent: 'space-between',
                  paddingBottom: theme.spacing(2),
                  gap: theme.spacing(2),
                }}
              >
                {/* Controls and Title Row */}
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    justifyContent: isMobile ? 'space-between' : 'flex-start',
                    flexWrap: 'wrap',
                    gap: theme.spacing(1),
                  }}
                >
                  {/* Controls: View Toggle and Delete Button */}
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: theme.spacing(1),
                    }}
                  >
                    {/* View Toggle Button */}
                    <ToggleButtonGroup
                      value={viewMode}
                      exclusive
                      onChange={handleViewModeChange}
                      aria-label="view mode"
                      size="small"
                    >
                      <ToggleButton value="list" aria-label="list view">
                        <ViewListIcon />
                      </ToggleButton>
                      <ToggleButton value="card" aria-label="card view">
                        <ViewModuleIcon />
                      </ToggleButton>
                    </ToggleButtonGroup>

                    {/* Delete Active List Button */}
                    {activeList && (
                      <IconButton
                        onClick={() => handleDeleteListClick(activeList.id)}
                        sx={{
                          color: theme.palette.error.main,
                          backgroundColor: alpha(theme.palette.error.main, 0.1),
                          '&:hover': {
                            backgroundColor: alpha(theme.palette.error.main, 0.2),
                          },
                        }}
                        aria-label="delete list"
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}

                    {/* Filter Icon for Mobile */}
                    {!isDesktop && (
                      <IconButton
                        aria-label="open filter"
                        onClick={toggleFilterDrawer(true)}
                        sx={{
                          color: theme.palette.primary.main,
                          backgroundColor: theme.palette.background.paper,
                          border: `1px solid ${theme.palette.primary.main}`,
                          '&:hover': {
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                          },
                        }}
                      >
                        <FilterListIcon />
                      </IconButton>
                    )}

                    {/* Create a List Button for Mobile */}
                    {isMobile && (
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: theme.palette.primary.main,
                          color: theme.palette.primary.contrastText,
                          fontFamily: theme.typography.fontFamily,
                          '&:hover': {
                            backgroundColor: theme.palette.primary.dark,
                          },
                        }}
                        onClick={openCreateModal}
                      >
                        Create a List
                      </Button>
                    )}
                  </Box>
                </Box>

                {/* Search Bar Positioned Below Controls on Mobile */}
                <Box
                  sx={{
                    width: '100%',
                    display: isMobile ? 'block' : 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Search sx={{ width: isMobile ? '100%' : 'auto' }}>
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder="Search Items…"
                      aria-label="search"
                      value={listSearchInput}
                      onChange={(e) => setListSearchInput(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          setListSearchTerm(listSearchInput.trim());
                        }
                      }}
                    />
                  </Search>
                </Box>
              </Box>

              {/* Display Content Based on Active List */}
              {activeList ? (
                // Display List Items (User Lists)
                <>
                  {loadingListItems && listItemsOffset === 0 ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: theme.spacing(2.5) }}>
                      <CircularProgress />
                    </Box>
                  ) : errorListItems ? (
                    <Box sx={{ marginTop: theme.spacing(2.5) }}>
                      <Alert severity="error">{errorListItems}</Alert>
                    </Box>
                  ) : listItems.length > 0 ? (
                    <>
                      {viewMode === 'card' ? (
                        <Grid container spacing={2}>
                          {listItems.map((item) => {
                            const product = item._product;

                            if (!product || !product._restaurant_products_pricing_of_product_test) {
                              // Skip items without restaurant-specific pricing
                              return null;
                            }

                            return (
                              <Grid item xs={6} sm={4} md={2} key={item.id}>
                                <ListProductCard
                                  product={product}
                                  multiplier={multiplier}
                                  showDelete={true}
                                  onDelete={handleDeleteProduct} // Pass the delete handler
                                  listItemId={item.id} // Pass the list_item_id
                                />
                              </Grid>
                            );
                          })}
                        </Grid>
                      ) : (
                        // List View
                        <Box>
                          {listItems.map((item) => {
                            const product = item._product;

                            if (!product || !product._restaurant_products_pricing_of_product_test) {
                              // Skip items without restaurant-specific pricing
                              return null;
                            }

                            return (
                              <Card
                                key={item.id}
                                sx={{
                                  display: 'flex',
                                  marginBottom: theme.spacing(2),
                                  cursor: 'pointer',
                                  position: 'relative',
                                  '&:hover': {
                                    boxShadow: theme.shadows[4],
                                  },
                                }}
                                onClick={() => {
                                  navigate(`/product/${product.id}`);
                                }}
                              >
                                {product.Image_URL ? (
                                  <CardMedia
                                    component="img"
                                    sx={{ width: 120, height: 120, objectFit: 'contain' }}
                                    image={product.Image_URL}
                                    alt={product.short_description}
                                  />
                                ) : (
                                  <Box
                                    sx={{
                                      width: 120,
                                      height: 120,
                                      backgroundColor: '#f0f0f0',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <Typography variant="subtitle1">No Image Available</Typography>
                                  </Box>
                                )}

                                <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                  <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography
                                      variant="subtitle1"
                                      sx={{
                                        fontFamily: theme.typography.fontFamily,
                                        color: theme.palette.text.secondary,
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      {product.brand}
                                    </Typography>

                                    <Typography
                                      variant="body1"
                                      sx={{
                                        fontFamily: theme.typography.fontFamily,
                                        marginBottom: '8px',
                                        display: '-webkit-box',
                                        WebkitLineClamp: 2,
                                        WebkitBoxOrient: 'vertical',
                                        overflow: 'hidden',
                                      }}
                                    >
                                      {product.short_description}
                                    </Typography>

                                    {/* Price and AddToCartButton */}
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginTop: 'auto',
                                      }}
                                    >
                                      <Typography
                                        variant="h6"
                                        sx={{
                                          fontSize: '1rem',
                                          fontWeight: theme.typography.h6.fontWeight,
                                          fontFamily: theme.typography.fontFamily,
                                          marginRight: theme.spacing(1),
                                        }}
                                      >
                                        {product._restaurant_products_pricing_of_product_test.purchase_price > 0
                                          ? `$${(product._restaurant_products_pricing_of_product_test.purchase_price * multiplier).toFixed(2)}`
                                          : 'Contact us'}
                                      </Typography>

                                      {product._restaurant_products_pricing_of_product_test.purchase_price > 0 && (
                                        <Box
                                          sx={{
                                            marginLeft: 'auto',
                                            display: 'flex',
                                            alignItems: 'center',
                                          }}
                                          onClick={(e) => e.stopPropagation()} // Prevent card's onClick
                                        >
                                          <AddToCartButton product={product} multiplier={multiplier} />
                                        </Box>
                                      )}
                                    </Box>
                                  </CardContent>
                                </Box>

                                {/* Vendor Logo Positioned at Upper Right of Image */}
                                {product._vendor?.Logo_URL && (
                                  <Box
                                    sx={{
                                      position: 'absolute',
                                      top: theme.spacing(2),
                                      right: theme.spacing(2),
                                      width: 40,
                                      height: 40,
                                      borderRadius: '50%',
                                      backgroundColor: theme.palette.background.paper,
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      boxShadow: theme.shadows[1],
                                    }}
                                  >
                                    <img
                                      src={product._vendor.Logo_URL}
                                      alt={product._vendor.Short_Name}
                                      style={{
                                        width: '80%',
                                        height: '80%',
                                        objectFit: 'contain',
                                        borderRadius: '50%',
                                      }}
                                      onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = '/path/to/default/logo.png'; // Update with actual default logo path
                                      }}
                                    />
                                  </Box>
                                )}

                                {/* Delete Button */}
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteProduct(item.id);
                                  }}
                                  sx={{ position: 'absolute', top: theme.spacing(1), left: theme.spacing(1) }}
                                  aria-label="delete item"
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Card>
                            );
                          })}
                        </Box>
                      )}

                      {/* Load More Button for List Items */}
                      {listItemsHasMore && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: theme.spacing(2.5) }}>
                          {loadingListItems ? (
                            <CircularProgress />
                          ) : (
                            <Button
                              variant="contained"
                              onClick={loadMoreListItems}
                              sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.contrastText,
                                '&:hover': {
                                  backgroundColor: theme.palette.primary.dark,
                                },
                              }}
                            >
                              Load More
                            </Button>
                          )}
                        </Box>
                      )}
                    </>
                  ) : (
                    // Default Content when no items are available
                    <Typography
                      variant="body1"
                      sx={{
                        textAlign: 'center',
                        marginTop: theme.spacing(2.5),
                        fontFamily: theme.typography.fontFamily,
                        fontSize: '1rem',
                      }}
                    >
                      No items available in this list.
                    </Typography>
                  )}
                </>
              ) : (
                // Default Content when no list is active
                <Typography
                  variant="body1"
                  sx={{
                    textAlign: 'center',
                    marginTop: theme.spacing(2.5),
                    fontFamily: theme.typography.fontFamily,
                    fontSize: '1rem',
                  }}
                >
                  Select a list to view items.
                </Typography>
              )}
            </Grid>
          </Grid>
        </Container>
      )}

      {/* Filter Drawer for Mobile */}
      <Drawer
        anchor="right"
        open={isFilterDrawerOpen}
        onClose={toggleFilterDrawer(false)}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <Box
          sx={{
            width: 250,
            padding: theme.spacing(2),
          }}
          role="presentation"
        >
          {/* Close Button */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton onClick={toggleFilterDrawer(false)} aria-label="close filter">
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Filter Panel */}
          <ListsFilterPanel
            lists={userLists}
            selectedListId={selectedListId}
            setSelectedListId={(id) => setSelectedListId(Number(id))}
            categories={categories}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            subcategories={subcategories}
            selectedSubcategory={selectedSubcategory}
            setSelectedSubcategory={setSelectedSubcategory}
            vendors={vendors}
            selectedVendor={selectedVendor}
            setSelectedVendor={(id) => setSelectedVendor(id !== null ? Number(id) : null)}
            brands={brands}
            selectedBrands={selectedBrands}
            setSelectedBrands={setSelectedBrands}
            clearAllFilters={clearAllFilters}
          />
        </Box>
      </Drawer>

      {/* Create a List Modal */}
      <Dialog open={createModalOpen} onClose={closeCreateModal} fullWidth maxWidth="sm">
        <DialogTitle>Create a New List</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="List Name"
            type="text"
            fullWidth
            variant="outlined"
            value={newListName}
            onChange={(e) => setNewListName(e.target.value)}
            disabled={creatingList}
          />
          {createListError && (
            <Alert severity="error" sx={{ marginTop: theme.spacing(1) }}>
              {createListError}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeCreateModal} disabled={creatingList}>
            Cancel
          </Button>
          <Button
            onClick={handleCreateList}
            variant="contained"
            disabled={creatingList}
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
              },
            }}
          >
            {creatingList ? <CircularProgress size={24} /> : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for Toast Messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        ContentProps={{
          sx: {
            backgroundColor:
              snackbarSeverity === 'success'
                ? theme.palette.success.main
                : snackbarSeverity === 'error'
                ? theme.palette.error.main
                : theme.palette.info.main,
            color: theme.palette.common.white,
          },
        }}
      />

      {/* Delete Confirmation Dialog for Lists */}
      <Dialog
        open={Boolean(deletingListId)}
        onClose={handleCancelDeleteList}
        aria-labelledby="delete-list-dialog-title"
        aria-describedby="delete-list-dialog-description"
      >
        <DialogTitle id="delete-list-dialog-title">Delete List</DialogTitle>
        <DialogContent>
          <Typography id="delete-list-dialog-description">
            Are you sure you want to delete this list? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDeleteList} disabled={deleteListLoading}>
            Cancel
          </Button>
          <Button
            onClick={() => handleConfirmDeleteList(deletingListId)}
            variant="contained"
            color="error"
            disabled={deleteListLoading}
            startIcon={deleteListLoading ? <CircularProgress size={20} /> : null}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Lists;
