// src/pages/Home.js

import React, { useState, useEffect, useContext } from 'react';
import TopNavBar from './TopNavBar';
import {
  Box,
  Typography,
  Container,
} from '@mui/material';
import { Masonry } from '@mui/lab'; // Import Masonry from MUI Lab
import { useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { Helmet } from 'react-helmet';

// Import SpendSummary and GettingStartedGuide
import SpendSummary from './SpendSummary';
import GettingStartedGuide from './GettingStartedGuide';

// Import ProductDetailsModal
import ProductDetailsModal from './ProductDetailsModal';

// Import CartContext
import { CartContext } from './CartContext';

// Import LowStockComponent and MOQComponent
import LowStockComponent from './LowStockComponent';
import MOQComponent from './MOQComponent';

function Home() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [showOnboardBanner, setShowOnboardBanner] = useState(false);

  // Add this effect to check the banner visibility
  useEffect(() => {
    const userData = localStorage.getItem('user');
    if (userData) {
      const user = JSON.parse(userData);
      setShowOnboardBanner(user.showonboardbanner || false);
    }
  }, []);

  // Add this handler to update the banner state when it's dismissed
  const handleBannerDismiss = () => {
    setShowOnboardBanner(false);
  };

  // Access CartContext
  const { subtotal } = useContext(CartContext);

  // State variables for Low Stock Inventory
  const [lowStockItems, setLowStockItems] = useState([]);
  const [lowStockLoading, setLowStockLoading] = useState(true);
  const [lowStockError, setLowStockError] = useState(null);

  // State variables for MOQ Tracker
  const [restaurantData, setRestaurantData] = useState(null);
  const [moqLoading, setMoqLoading] = useState(false);
  const [moqError, setMoqError] = useState(null);

  // State variables for Product Modal and Multiplier
  const [selectedItem, setSelectedItem] = useState(null);
  const [openProductModal, setOpenProductModal] = useState(false);
  const [multiplier, setMultiplier] = useState(1);

  // Helper Functions
  const getRestaurantId = () => {
    const userJson = localStorage.getItem('user');
    if (!userJson) {
      throw new Error('User data not found in local storage.');
    }

    let user;
    try {
      user = JSON.parse(userJson);
    } catch (parseError) {
      throw new Error('Failed to parse user data from local storage.');
    }

    const restaurantId = user.restaurant_id;
    if (!restaurantId) {
      throw new Error('Restaurant ID not found in user data.');
    }

    return restaurantId;
  };

  // Fetch Multiplier
  useEffect(() => {
    const fetchUserData = () => {
      const userData = localStorage.getItem('user');
      if (userData) {
        try {
          const parsedUser = JSON.parse(userData);
          const storedMultiplier = parseFloat(parsedUser.multiplier) || 1;
          setMultiplier(storedMultiplier);
          console.log('Multiplier retrieved from localStorage:', storedMultiplier);
        } catch (e) {
          console.error('Error parsing user data from local storage:', e);
          setMultiplier(1); // Default to 1 if error
        }
      } else {
        console.error('User data not found in localStorage.');
        setMultiplier(1); // Default to 1 if user data not found
      }
    };

    fetchUserData();
  }, []);

  // Define fetchInventory as a separate function
  const fetchInventory = async () => {
    setLowStockLoading(true);
    try {
      const authToken = localStorage.getItem('authToken');
      const user = JSON.parse(localStorage.getItem('user'));

      if (!authToken || !user || !user.restaurant_id) {
        console.error('Authentication token or user data not found.');
        navigate('/');
        return;
      }

      const params = {
        restaurantidfilter: user.restaurant_id,
        limit: 100, // Adjust as needed
        offset: 0,
      };

      const headers = { Authorization: `Bearer ${authToken}` };
      const apiEndpoint = 'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/inventoryreact';

      const response = await axios.get(apiEndpoint, { params, headers });
      const fetchedInventory = response.data.items || [];

      // Filter to low stock items
      const lowStockData = fetchedInventory.filter(
        (item) => item.On_Hand <= item.PAR
      );

      // Optionally, sort the low stock items by how low the stock is (ascending)
      lowStockData.sort((a, b) => a.On_Hand - b.On_Hand);

      setLowStockItems(lowStockData);
    } catch (error) {
      console.error('Error fetching low stock inventory:', error);
      setLowStockError(
        error.message || 'An unexpected error occurred while fetching low stock inventory.'
      );
    } finally {
      setLowStockLoading(false);
    }
  };

  // Fetch Low Stock Inventory Items on component mount
  useEffect(() => {
    fetchInventory();
  }, [navigate]);

  // Fetch MOQ Tracker Data
  useEffect(() => {
    const fetchMOQData = async () => {
      setMoqLoading(true);
      try {
        const authToken = localStorage.getItem('authToken');
        const restaurantId = getRestaurantId();

        if (!authToken || !restaurantId) {
          console.error('Authentication token or restaurant ID not found.');
          navigate('/');
          return;
        }

        const headers = { Authorization: `Bearer ${authToken}` };
        const params = { restaurant_id: restaurantId };

        const response = await axios.get(
          'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/moqtracker',
          { headers, params }
        );

        console.log('MOQ Tracker Data:', response.data);

        if (response.data && response.data.length > 0) {
          setRestaurantData(response.data[0]);
        } else {
          console.error('No data received from MOQ Tracker API.');
          setRestaurantData(null); // Explicitly set to null
        }
      } catch (error) {
        console.error('Error fetching MOQ Tracker data:', error);
        setMoqError(
          error.message || 'An unexpected error occurred while fetching MOQ Tracker data.'
        );
      } finally {
        setMoqLoading(false);
      }
    };

    fetchMOQData();
  }, [navigate]);

  // Handler for viewing all out-of-stock items
  const handleGoToInventory = () => {
    navigate('/inventory');
  };

  // Handler for inventory item click (opens the modal)
  const handleInventoryItemClick = (item) => {
    setSelectedItem(item);
    setOpenProductModal(true);
  };

  return (
    <div>
      <Helmet>
        <title>FareFood | Home</title>
      </Helmet>
      {/* Sticky TopNavBar */}
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: theme.zIndex.appBar, // Ensures the navbar stays above other content
          backgroundColor: theme.palette.background.paper, // Prevents content bleed
          boxShadow: theme.shadows[4], // Optional: Adds shadow for better separation
        }}
      >
        <TopNavBar onSearch={null} title="Home" />
      </Box>

      {/* Main Content Wrapper */}
      <Box
        sx={{
          paddingTop: theme.spacing(2), // Adjusted padding to account for sticky navbar
          paddingBottom: theme.spacing(6),
          transition: 'padding-left 0.3s',
          minHeight: '100vh',
          backgroundColor: 'white',
          fontFamily: theme.typography.fontFamily, // Use theme's font family
        }}
      >
        {/* Container to set max width to 1200px */}
        <Container maxWidth="lg" sx={{ padding: theme.spacing(2) }}>
          {/* Welcome Section */}
          <Box
            sx={{
              marginBottom: theme.spacing(3),
              textAlign: 'left',
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: 300,
                marginBottom: theme.spacing(1),
                marginTop: theme.spacing(4),
              }}
            >
              Dashboard
            </Typography>
          </Box>

          {/* Masonry Layout with 3 columns on medium and large screens */}
          <Masonry columns={{ xs: 1, sm: 2, md: 3, lg: 3 }} spacing={2}>
            {/* Getting Started Guide - Only render the Box if guide is visible */}
            {showOnboardBanner && (
              <Box sx={{ gridColumn: '1 / -1', marginBottom: 2 }}>
                <GettingStartedGuide onDismiss={handleBannerDismiss} />
              </Box>
            )}

            {/* Spend Summary Section */}
            <SpendSummary />

            {/* Low Stock Inventory Section - Refactored as LowStockComponent */}
            <LowStockComponent
              lowStockItems={lowStockItems}
              lowStockLoading={lowStockLoading}
              lowStockError={lowStockError}
              handleGoToInventory={handleGoToInventory}
              onItemClick={handleInventoryItemClick}
              multiplier={multiplier} // Pass the multiplier here
            />

            {/* MOQ Tracker Section - Refactored as MOQComponent */}
            <MOQComponent
              restaurantData={restaurantData}
              moqLoading={moqLoading}
              moqError={moqError}
              subtotal={subtotal}
              handleGoToInventory={handleGoToInventory}
            />
          </Masonry>
        </Container>
      </Box>

      {/* Product Details Modal */}
      {openProductModal && selectedItem && (
        <ProductDetailsModal
          open={openProductModal}
          handleClose={() => setOpenProductModal(false)}
          inventoryItem={selectedItem}
          multiplier={multiplier}
          fetchInventory={fetchInventory} // Pass fetchInventory here
        />
      )}
    </div>
  );
}

export default Home;
