// src/ListsFilterPanel.js

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  InputBase,
  FormGroup,
  FormControlLabel,
  Radio,
  RadioGroup,
  Box,
  Button,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme, styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';

// Styled Components for Search Bar
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius * 3,
  border: `1px solid ${theme.palette.primary.main}`,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.25),
  },
  width: '100%',
  marginBottom: theme.spacing(1),
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: theme.spacing(0, 1.5),
  height: '100%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  pointerEvents: 'none',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontFamily: theme.typography.fontFamily,
  fontSize: '0.875rem',
  '& .MuiInputBase-input': {
    padding: theme.spacing(0.5, 0.5, 0.5, 0),
    paddingLeft: `calc(1em + ${theme.spacing(3)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

function ListsFilterPanel({
  lists,
  selectedListId,
  setSelectedListId,
  categories,
  selectedCategory,
  setSelectedCategory,
  subcategories,
  selectedSubcategory,
  setSelectedSubcategory,
  vendors,
  selectedVendor,
  setSelectedVendor,
  brands,
  selectedBrands,
  setSelectedBrands,
  clearAllFilters,
}) {
  const theme = useTheme();

  // Search states
  const [listSearch, setListSearch] = useState('');
  const [categorySearch, setCategorySearch] = useState('');
  const [subcategorySearch, setSubcategorySearch] = useState('');
  const [vendorSearch, setVendorSearch] = useState('');

  // Filtered data
  const [filteredLists, setFilteredLists] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [filteredSubcategories, setFilteredSubcategories] = useState([]);
  const [filteredVendors, setFilteredVendors] = useState([]);

  // Effects to filter data based on search
  useEffect(() => {
    const lowercasedFilter = listSearch.toLowerCase();
    const filtered = lists.filter((list) =>
      list.list_name.toLowerCase().includes(lowercasedFilter)
    );
    setFilteredLists(filtered);
  }, [listSearch, lists]);

  useEffect(() => {
    const lowercasedFilter = categorySearch.toLowerCase();
    const filtered = categories.filter((category) =>
      category.name.toLowerCase().includes(lowercasedFilter)
    );
    setFilteredCategories(filtered);
  }, [categorySearch, categories]);

  useEffect(() => {
    const lowercasedFilter = subcategorySearch.toLowerCase();
    const filtered = subcategories.filter((subcategory) =>
      subcategory.name.toLowerCase().includes(lowercasedFilter)
    );
    setFilteredSubcategories(filtered);
  }, [subcategorySearch, subcategories]);

  useEffect(() => {
    const lowercasedFilter = vendorSearch.toLowerCase();
    const filtered = vendors.filter(
      (vendor) =>
        vendor.Short_Name &&
        vendor.Short_Name.toLowerCase().includes(lowercasedFilter)
    );
    setFilteredVendors(filtered);
  }, [vendorSearch, vendors]);

  // Function to truncate text to a maximum length
  const truncateText = (text, maxLength) => {
    if (!text) return '';
    if (text.length <= maxLength) return text;
    return `${text.slice(0, maxLength)}...`;
  };

  return (
    <Box>
      {/* Filter Header */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: theme.spacing(2),
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontFamily: theme.typography.fontFamily,
            fontWeight: 'light',
            color: theme.palette.grey[600],
            fontSize: '0.875rem',
          }}
        >
          Filter By
        </Typography>
        <Button
          variant="text"
          onClick={clearAllFilters}
          sx={{
            textTransform: 'none',
            color: theme.palette.primary.main,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: '0.875rem',
          }}
        >
          Clear All
        </Button>
      </Box>

      {/* Lists Filter */}
      <Accordion
        sx={{
          boxShadow: 'none',
          backgroundColor: 'transparent',
          '&:before': { display: 'none' },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: 'black' }} />}
          aria-controls="lists-content"
          id="lists-header"
          sx={{
            borderTop: `1px solid ${theme.palette.divider}`,
            borderBottom: `1px solid ${theme.palette.divider}`,
            minHeight: '48px',
            '& .MuiAccordionSummary-content': {
              margin: 0,
            },
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              fontFamily: theme.typography.fontFamily,
              fontWeight: 'regular',
              color: 'black',
              fontSize: '1rem',
            }}
          >
            Lists
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/* Search Bar for Lists */}
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search Lists"
              aria-label="search lists"
              value={listSearch}
              onChange={(e) => setListSearch(e.target.value)}
            />
          </Search>
          <FormGroup>
            {filteredLists.map((list) => (
              <FormControlLabel
                key={list.id}
                control={
                  <Radio
                    checked={selectedListId === list.id}
                    onChange={() => setSelectedListId(list.id)}
                    value={list.id.toString()} // Ensure value is a string
                    name="list-selection"
                    inputProps={{ 'aria-label': list.list_name }}
                  />
                }
                label={truncateText(list.list_name, 20)}
                sx={{ fontFamily: theme.typography.fontFamily }}
              />
            ))}
            {filteredLists.length === 0 && (
              <Typography
                variant="body2"
                sx={{
                  textAlign: 'center',
                  marginTop: theme.spacing(1),
                  fontFamily: theme.typography.fontFamily,
                  color: theme.palette.text.secondary,
                }}
              >
                No lists found.
              </Typography>
            )}
          </FormGroup>
        </AccordionDetails>
      </Accordion>

      {/* Category Filter */}
      <Accordion
        sx={{
          boxShadow: 'none',
          backgroundColor: 'transparent',
          '&:before': { display: 'none' },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: 'black' }} />}
          aria-controls="category-content"
          id="category-header"
          sx={{
            borderTop: `1px solid ${theme.palette.divider}`,
            borderBottom: `1px solid ${theme.palette.divider}`,
            minHeight: '48px',
            '& .MuiAccordionSummary-content': {
              margin: 0,
            },
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              fontFamily: theme.typography.fontFamily,
              fontWeight: 'regular',
              color: 'black',
              fontSize: '1rem',
            }}
          >
            Category
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/* Search Bar for Categories */}
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search Categories"
              aria-label="search categories"
              value={categorySearch}
              onChange={(e) => setCategorySearch(e.target.value)}
            />
          </Search>
          <FormGroup>
            {filteredCategories.map((category) => (
              <FormControlLabel
                key={category.id}
                control={
                  <Radio
                    checked={selectedCategory === category.id}
                    onChange={() => {
                      if (selectedCategory === category.id) {
                        setSelectedCategory(0); // Deselect if already selected
                        setSelectedSubcategory(0); // Reset subcategory
                      } else {
                        setSelectedCategory(category.id);
                      }
                    }}
                    value={category.id.toString()} // Ensure value is a string
                    name="category-selection"
                    inputProps={{ 'aria-label': category.name }}
                  />
                }
                label={truncateText(category.name, 20)}
                sx={{ fontFamily: theme.typography.fontFamily }}
              />
            ))}
            {filteredCategories.length === 0 && (
              <Typography
                variant="body2"
                sx={{
                  textAlign: 'center',
                  marginTop: theme.spacing(1),
                  fontFamily: theme.typography.fontFamily,
                  color: theme.palette.text.secondary,
                }}
              >
                No categories found.
              </Typography>
            )}
          </FormGroup>
        </AccordionDetails>
      </Accordion>

      {/* Subcategory Filter */}
      {selectedCategory !== 0 && (
        <Accordion
          sx={{
            boxShadow: 'none',
            backgroundColor: 'transparent',
            '&:before': { display: 'none' },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: 'black' }} />}
            aria-controls="subcategory-content"
            id="subcategory-header"
            sx={{
              borderTop: `1px solid ${theme.palette.divider}`,
              borderBottom: `1px solid ${theme.palette.divider}`,
              minHeight: '48px',
              '& .MuiAccordionSummary-content': {
                margin: 0,
              },
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontFamily: theme.typography.fontFamily,
                fontWeight: 'regular',
                color: 'black',
                fontSize: '1rem',
              }}
            >
              Subcategory
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {/* Search Bar for Subcategories */}
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search Subcategories"
                aria-label="search subcategories"
                value={subcategorySearch}
                onChange={(e) => setSubcategorySearch(e.target.value)}
              />
            </Search>
            <FormGroup>
              {filteredSubcategories.map((subcategory) => (
                <FormControlLabel
                  key={subcategory.id}
                  control={
                    <Radio
                      checked={selectedSubcategory === subcategory.id}
                      onChange={() => {
                        if (selectedSubcategory === subcategory.id) {
                          setSelectedSubcategory(0); // Deselect if already selected
                        } else {
                          setSelectedSubcategory(subcategory.id);
                        }
                      }}
                      value={subcategory.id.toString()} // Ensure value is a string
                      name="subcategory-selection"
                      inputProps={{ 'aria-label': subcategory.name }}
                    />
                  }
                  label={truncateText(subcategory.name, 20)}
                  sx={{ fontFamily: theme.typography.fontFamily }}
                />
              ))}
              {filteredSubcategories.length === 0 && (
                <Typography
                  variant="body2"
                  sx={{
                    textAlign: 'center',
                    marginTop: theme.spacing(1),
                    fontFamily: theme.typography.fontFamily,
                    color: theme.palette.text.secondary,
                  }}
                >
                  No subcategories found.
                </Typography>
              )}
            </FormGroup>
          </AccordionDetails>
        </Accordion>
      )}

      {/* Vendor Filter */}
      <Accordion
        sx={{
          boxShadow: 'none',
          backgroundColor: 'transparent',
          '&:before': { display: 'none' },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: 'black' }} />}
          aria-controls="vendor-content"
          id="vendor-header"
          sx={{
            borderTop: `1px solid ${theme.palette.divider}`,
            borderBottom: `1px solid ${theme.palette.divider}`,
            minHeight: '48px',
            '& .MuiAccordionSummary-content': {
              margin: 0,
            },
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              fontFamily: theme.typography.fontFamily,
              fontWeight: 'regular',
              color: 'black',
              fontSize: '1rem',
            }}
          >
            Vendor
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/* Search Bar for Vendors */}
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search Vendors"
              aria-label="search vendors"
              value={vendorSearch}
              onChange={(e) => setVendorSearch(e.target.value)}
            />
          </Search>
          <RadioGroup
            aria-label="vendor-selection"
            name="vendor-selection-group"
            value={selectedVendor !== null ? selectedVendor.toString() : ''}
            onChange={(e) => setSelectedVendor(e.target.value !== '' ? Number(e.target.value) : null)} // Convert to number
          >
            {filteredVendors.map((vendor) => (
              <FormControlLabel
                key={vendor.id}
                control={<Radio />}
                label={truncateText(vendor.Short_Name, 20)}
                value={vendor.id.toString()} // Ensure value is a string
                sx={{ fontFamily: theme.typography.fontFamily }}
              />
            ))}
            {filteredVendors.length === 0 && (
              <Typography
                variant="body2"
                sx={{
                  textAlign: 'center',
                  marginTop: theme.spacing(1),
                  fontFamily: theme.typography.fontFamily,
                  color: theme.palette.text.secondary,
                }}
              >
                No vendors found.
              </Typography>
            )}
          </RadioGroup>
        </AccordionDetails>
      </Accordion>

      {/* Brand Filter (Optional) */}
      {/* Implement similar structure if brands need to be filtered */}
    </Box>
  );
}

ListsFilterPanel.propTypes = {
  lists: PropTypes.array.isRequired,
  selectedListId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setSelectedListId: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  selectedCategory: PropTypes.number.isRequired,
  setSelectedCategory: PropTypes.func.isRequired,
  subcategories: PropTypes.array.isRequired,
  selectedSubcategory: PropTypes.number.isRequired,
  setSelectedSubcategory: PropTypes.func.isRequired,
  vendors: PropTypes.array.isRequired,
  selectedVendor: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setSelectedVendor: PropTypes.func.isRequired,
  brands: PropTypes.array.isRequired,
  selectedBrands: PropTypes.array.isRequired,
  setSelectedBrands: PropTypes.func.isRequired,
  clearAllFilters: PropTypes.func.isRequired,
};

export default ListsFilterPanel;
