// src/components/ProductDetailsModal.js

import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  IconButton,
} from '@mui/material';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import { useNavigate } from 'react-router-dom';
import AddToCartButton from './AddToCartButton';
import { useTheme } from '@mui/material/styles';

function ProductDetailsModal({
  open,
  handleClose,
  inventoryItem,
  fetchInventory,
  multiplier,
}) {
  const [product, setProduct] = useState(null);
  const [loadingProduct, setLoadingProduct] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const [onHand, setOnHand] = useState(inventoryItem.On_Hand);
  const [par, setPar] = useState(inventoryItem.PAR);
  const navigate = useNavigate();
  const theme = useTheme();

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 500,
    bgcolor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[24],
    p: 0,
    overflow: 'auto',
  };

  const fetchProductDetails = async () => {
    setLoadingProduct(true);
    try {
      const authToken = localStorage.getItem('authToken');
      const headers = { Authorization: `Bearer ${authToken}` };

      const response = await axios.get(
        `https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/product/${inventoryItem.product_id}`,
        { headers }
      );

      console.log('Product Details:', response.data);
      setProduct(response.data);
    } catch (error) {
      console.error('Error fetching product details:', error);
      setProduct(null);
    } finally {
      setLoadingProduct(false);
    }
  };

  useEffect(() => {
    if (inventoryItem.product_id !== 0) {
      fetchProductDetails();
    } else {
      setProduct(null);
      setLoadingProduct(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryItem.product_id]);

  // Handle initial delete icon click
  const handleDeleteClick = (e) => {
    e.stopPropagation();
    setConfirmDelete(true);
  };

  // Handle confirm delete
  const handleConfirmDelete = async (e) => {
    e.stopPropagation();
    await handleRemoveItem();
  };

  // Handle cancel delete
  const handleCancelDelete = (e) => {
    e.stopPropagation();
    setConfirmDelete(false);
  };

  const handleRemoveItem = async () => {
    try {
      const authToken = localStorage.getItem('authToken');
      const headers = {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      };

      await axios.delete(
        `https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/inventory/${inventoryItem.id}`,
        { headers }
      );

      // Refresh the inventory data
      if (fetchInventory) {
        await fetchInventory();
      }

      // Close the modal
      handleClose();
    } catch (error) {
      console.error('Error removing inventory item:', error);
      alert('An error occurred while removing the inventory item.');
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoadingUpdate(true);

    try {
      const authToken = localStorage.getItem('authToken');
      const headers = {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      };

      const data = {
        inventory_item_id: inventoryItem.id,
        onhand: parseFloat(onHand),
        par: parseFloat(par),
      };

      const response = await axios.patch(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/update_inventory_item',
        data,
        { headers }
      );

      console.log('Inventory item updated:', response.data);

      // Refresh the inventory data
      if (fetchInventory) {
        await fetchInventory();
      }

      // Close the modal
      handleClose();
    } catch (error) {
      console.error('Error updating inventory item:', error);
      alert('An error occurred while updating the inventory item.');
    } finally {
      setLoadingUpdate(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="product-details-modal"
      onClick={(e) => e.stopPropagation()} // Prevent click events from propagating
    >
      <Box sx={style}>
        {/* Image and Top Icons */}
        <Box sx={{ position: 'relative' }}>
          {/* Image */}
          {product && product.Image_URL ? (
            <Box
              component="img"
              src={product.Image_URL}
              alt={product.short_description}
              sx={{
                width: '100%',
                maxHeight: 200,
                objectFit: 'contain',
                borderTopLeftRadius: theme.shape.borderRadius,
                borderTopRightRadius: theme.shape.borderRadius,
              }}
            />
          ) : (
            <Box
              sx={{
                width: '100%',
                height: 150,
                backgroundColor: theme.palette.grey[200],
                borderTopLeftRadius: theme.shape.borderRadius,
                borderTopRightRadius: theme.shape.borderRadius,
              }}
            />
          )}

          {/* Close Icon */}
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: theme.spacing(1),
              right: theme.spacing(1),
              color: theme.palette.common.white,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
              },
            }}
          >
            <CloseIcon />
          </IconButton>

          {/* Remove Item Icon with Confirmation */}
          <Box
            sx={{
              position: 'absolute',
              top: theme.spacing(1),
              left: theme.spacing(1),
            }}
          >
            {confirmDelete ? (
              <Box sx={{ display: 'flex', gap: theme.spacing(0.5) }}>
                <IconButton
                  onClick={handleConfirmDelete}
                  sx={{
                    color: theme.palette.success.main,
                    backgroundColor: theme.palette.common.white,
                  }}
                  aria-label="confirm delete"
                >
                  <CheckIcon />
                </IconButton>
                <IconButton
                  onClick={handleCancelDelete}
                  sx={{
                    color: theme.palette.grey[600],
                    backgroundColor: theme.palette.common.white,
                  }}
                  aria-label="cancel delete"
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            ) : (
              <IconButton
                onClick={handleDeleteClick}
                sx={{
                  color: theme.palette.common.white,
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                  },
                }}
                aria-label="delete product"
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Box>

          {/* Vendor Logo Positioned at Upper Right of Image */}
          {product?._vendor?.Logo_URL && (
            <Box
              sx={{
                position: 'absolute',
                top: theme.spacing(1),
                right: theme.spacing(6), // Adjusted to prevent overlap with close icon
                width: 40,
                height: 40,
                borderRadius: '50%',
                backgroundColor: theme.palette.common.white,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                boxShadow: theme.shadows[1],
              }}
            >
              <Box
                component="img"
                src={product._vendor.Logo_URL}
                alt={product._vendor.Short_Name}
                sx={{
                  width: '80%',
                  height: '80%',
                  objectFit: 'contain',
                  borderRadius: '50%',
                }}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = '/path/to/default/logo.png'; // Replace with actual fallback image path
                }}
              />
            </Box>
          )}

          {/* AddToCartButton at Bottom Left */}
          {product && (
            <Box
              sx={{
                position: 'absolute',
                bottom: -24,
                left: theme.spacing(2),
                zIndex: 1,
              }}
              onClick={(e) => e.stopPropagation()} // Prevent click from closing modal
            >
              <AddToCartButton product={product} multiplier={multiplier} />
            </Box>
          )}
        </Box>

        {/* Content Section */}
        <Box sx={{ padding: theme.spacing(2), paddingTop: theme.spacing(4) }}>
          {inventoryItem.product_id !== 0 && loadingProduct ? (
            <Box sx={{ textAlign: 'center', marginTop: theme.spacing(2) }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              {inventoryItem.product_id !== 0 && product ? (
                <>
                  {/* Product Description */}
                  <Typography
                    variant="body1"
                    sx={{
                      marginTop: theme.spacing(1.5),
                      fontFamily: theme.typography.fontFamily,
                      color: theme.palette.text.primary,
                    }}
                  >
                    {product.short_description}
                  </Typography>

                  {/* Vendor Name */}
                  {product._vendor?.Short_Name && (
                    <Typography
                      variant="subtitle1"
                      sx={{
                        color: theme.palette.text.secondary,
                        fontFamily: theme.typography.fontFamily,
                        fontSize: '0.8rem',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        marginTop: theme.spacing(0.5),
                      }}
                    >
                      {product._vendor.Short_Name}
                    </Typography>
                  )}
                </>
              ) : (
                <Typography
                  variant="h6"
                  sx={{
                    marginTop: theme.spacing(1.5),
                    fontWeight: theme.typography.fontWeightBold,
                    fontFamily: theme.typography.fontFamily,
                    color: theme.palette.text.primary,
                  }}
                >
                  {inventoryItem.item_description}
                </Typography>
              )}

              {/* Form Inputs */}
              <form onSubmit={handleUpdate}>
                <TextField
                  label="On Hand Quantity"
                  variant="outlined"
                  fullWidth
                  required
                  type="number"
                  inputProps={{ min: 0 }}
                  value={onHand}
                  onChange={(e) => setOnHand(e.target.value)}
                  sx={{
                    marginTop: theme.spacing(2),
                    fontFamily: theme.typography.fontFamily,
                    '& .MuiOutlinedInput-root': {
                      fontFamily: theme.typography.fontFamily,
                    },
                    '& .MuiInputLabel-root': {
                      fontFamily: theme.typography.fontFamily,
                    },
                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: theme.palette.primary.main,
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: theme.palette.primary.main,
                    },
                  }}
                />
                <TextField
                  label="PAR Quantity"
                  variant="outlined"
                  fullWidth
                  required
                  type="number"
                  inputProps={{ min: 0 }}
                  value={par}
                  onChange={(e) => setPar(e.target.value)}
                  sx={{
                    marginTop: theme.spacing(2),
                    fontFamily: theme.typography.fontFamily,
                    '& .MuiOutlinedInput-root': {
                      fontFamily: theme.typography.fontFamily,
                    },
                    '& .MuiInputLabel-root': {
                      fontFamily: theme.typography.fontFamily,
                    },
                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: theme.palette.primary.main,
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: theme.palette.primary.main,
                    },
                  }}
                />
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  disabled={loadingUpdate}
                  sx={{
                    marginTop: theme.spacing(3),
                    borderRadius: '50px',
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    fontFamily: theme.typography.fontFamily,
                    textTransform: 'none',
                    '&:hover': {
                      backgroundColor: theme.palette.primary.dark,
                    },
                  }}
                >
                  {loadingUpdate ? 'Updating...' : 'Update Inventory'}
                </Button>
              </form>

              {/* View Details Button */}
              {inventoryItem.product_id !== 0 && (
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent click from closing modal
                    navigate(`/product/${inventoryItem.product_id}`);
                  }}
                  sx={{
                    marginTop: theme.spacing(2),
                    borderRadius: '50px',
                    color: theme.palette.primary.main,
                    borderColor: theme.palette.primary.main,
                    fontFamily: theme.typography.fontFamily,
                    textTransform: 'none',
                    '&:hover': {
                      backgroundColor: theme.palette.action.hover,
                    },
                  }}
                >
                  View Details
                </Button>
              )}
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
}

export default ProductDetailsModal;
