// src/Orders.js

import React, { useState, useEffect, useCallback } from 'react';
import TopNavBar from './TopNavBar';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useTheme,
  CircularProgress,
  Typography,
  IconButton,
  Collapse,
  Box,
  Button,
  Tooltip,
  Grid,
  List as MUIList,
  ListItemButton,
  ListItemText,
  Divider,
  useMediaQuery,
  Drawer,
  InputBase,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Snackbar,
  Alert,
  Card,
  CardContent,
  MenuItem,
  Pagination, // Import Pagination component
  FormControl,
} from '@mui/material';
import {
  Search as SearchIcon,
  FilterList as FilterListIcon,
  Close as CloseIcon,
  Reply as ReplyIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@mui/icons-material';
import axios from 'axios';
import moment from 'moment';
import { useNavigate, useLocation } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import { Helmet } from 'react-helmet';

// Styled Components for Search Bar
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius * 5,
  border: `1px solid ${theme.palette.primary.main}`,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.25),
  },
  width: '100%',
  maxWidth: 500,
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(0),
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  pointerEvents: 'none',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontFamily: theme.typography.fontFamily,
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

function Orders() {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('0'); // Default to 'All Orders'
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const apiEndpoint = 'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/order_2';

  const statusOptions = [
    { label: 'All Orders', value: '0' },
    { label: 'New', value: '1' },
    { label: 'In Progress', value: '3' },
    { label: 'Completed', value: '5' },
  ];

  // State variables for restaurant information and vendors
  const [restaurantName, setRestaurantName] = useState('Unknown Restaurant');
  const [restaurantLocation, setRestaurantLocation] = useState('Unknown Location');
  const [restaurantId, setRestaurantId] = useState(null);
  const [vendors, setVendors] = useState([]);

  // Pagination States
  const [totalResults, setTotalResults] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const ordersPerPage = 25; // Adjust as needed

  // Fetch restaurant data and vendors
  useEffect(() => {
    const fetchUserData = async () => {
      const userData = localStorage.getItem('user');
      if (userData) {
        try {
          const parsedUser = JSON.parse(userData);

          // Fetch Restaurant Name and Location
          const restaurantId = parsedUser.restaurant_id;
          setRestaurantId(restaurantId);

          if (restaurantId) {
            try {
              const response = await axios.get(
                'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/restaurant',
                {
                  params: {
                    restaurantidfilter: restaurantId,
                  },
                  headers: {
                    'Content-Type': 'application/json',
                  },
                }
              );

              if (Array.isArray(response.data) && response.data.length > 0) {
                const restaurantData = response.data[0];
                setRestaurantName(restaurantData.name || 'Unknown Restaurant');
                setRestaurantLocation(restaurantData.location || 'Unknown Location');
              } else {
                setRestaurantName('Unknown Restaurant');
                setRestaurantLocation('Unknown Location');
              }

              // Fetch Vendors
              fetchVendorEmails(restaurantId);
            } catch (error) {
              console.error('Error fetching restaurant Name and Location:', error);
              setRestaurantName('Unknown Restaurant');
              setRestaurantLocation('Unknown Location');
            }
          } else {
            console.error('Restaurant ID not found in user data.');
            setRestaurantName('Unknown Restaurant');
            setRestaurantLocation('Unknown Location');
          }
        } catch (e) {
          console.error('Error parsing user data from local storage:', e);
          setRestaurantName('Unknown Restaurant');
          setRestaurantLocation('Unknown Location');
        }
      } else {
        console.error('User data not found in localStorage.');
        setRestaurantName('Unknown Restaurant');
        setRestaurantLocation('Unknown Location');
      }
    };

    fetchUserData();
  }, []);

  const fetchVendorEmails = async (restaurantId) => {
    try {
      const response = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/restaurant_vendors',
        {
          params: { restaurant_id: restaurantId },
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (Array.isArray(response.data)) {
        setVendors(response.data);
      } else {
        console.error('Invalid data received for vendors.');
      }
    } catch (error) {
      console.error('Error fetching vendors:', error);
    }
  };

  // Debounce the search term to prevent filtering on every keystroke
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500); // 500ms debounce delay

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  // Filter orders based on the debounced search term
  useEffect(() => {
    if (debouncedSearchTerm) {
      const filtered = orders.filter((order) =>
        order.orderitem?.some((item) =>
          item.product?.short_description
            ?.toLowerCase()
            .includes(debouncedSearchTerm.toLowerCase())
        )
      );
      setFilteredOrders(filtered);
    } else {
      setFilteredOrders(orders);
    }
  }, [debouncedSearchTerm, orders]);

  const fetchOrders = useCallback(async () => {
    setLoading(true);
    try {
      const authToken = localStorage.getItem('authToken');
      const user = JSON.parse(localStorage.getItem('user'));

      if (!authToken || !user) {
        console.error('Authentication token or user data not found in localStorage.');
        navigate('/');
        return;
      }

      const params = {
        restaurantidfilter: user.restaurant_id,
        checkoutcompletefilter: true,
        deliverystatusidfilter: parseInt(selectedStatus),
        limit: ordersPerPage,
        offset: (currentPage - 1) * ordersPerPage,
        datefilter: '',
      };

      const headers = { Authorization: `Bearer ${authToken}` };
      const response = await axios.get(apiEndpoint, { params, headers });

      const fetchedOrders = response.data.items;

      if (Array.isArray(fetchedOrders)) {
        setOrders(fetchedOrders);
        setTotalResults(response.data.itemsTotal || 0);
        setTotalPages(response.data.pageTotal || 0);
      } else {
        setOrders([]);
        setTotalResults(0);
        setTotalPages(0);
      }
    } catch (error) {
      console.error('Error fetching orders:', error);
      setOrders([]);
      setTotalResults(0);
      setTotalPages(0);
    } finally {
      setLoading(false);
    }
  }, [navigate, apiEndpoint, selectedStatus, currentPage, ordersPerPage]);

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  const toggleFilterDrawer = (open) => () => {
    setIsFilterDrawerOpen(open);
  };

  // Handle Page Change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <Helmet>
        <title>FareFood | Orders</title>
      </Helmet>
      {/* Parent Box with Flex Layout */}
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        {/* Sticky TopNavBar without title */}
        <Box sx={{ position: 'sticky', top: 0, zIndex: theme.zIndex.appBar }}>
          <TopNavBar />
        </Box>

        {/* Main Content Area */}
        <Container
          maxWidth="lg"
          disableGutters={isMobile}
          sx={{
            paddingTop: theme.spacing(4),
            flexGrow: 1,
          }}
        >
          {/* Title */}
          <Typography
            variant="h5"
            sx={{
              fontFamily: theme.typography.fontFamily,
              marginBottom: theme.spacing(2),
              marginTop: theme.spacing(8),
              paddingLeft: theme.spacing(2),
              color: 'primary',
            }}
          >
            {statusOptions.find((status) => status.value === selectedStatus)?.label}
          </Typography>

          {/* Result Count */}
          <Typography
            variant="subtitle2"
            color="text.secondary"
            sx={{ marginBottom: theme.spacing(1), paddingLeft: theme.spacing(2) }}
          >
            {debouncedSearchTerm
              ? `Found ${filteredOrders.length} orders matching "${debouncedSearchTerm}"`
              : `Showing ${(currentPage - 1) * ordersPerPage + 1}-${
                  (currentPage - 1) * ordersPerPage + filteredOrders.length
                } out of ${totalResults} orders`}
          </Typography>

          <Grid container sx={{ flexGrow: 1, display: 'flex', flexDirection: 'row' }}>
            {/* Filter Panel for Desktop */}
            {isDesktop && (
              <Grid
                item
                sm={3}
                md={2}
                sx={{
                  height: 'calc(100vh - 100px)',
                  padding: theme.spacing(2),
                  backgroundColor: theme.palette.background.paper,
                  position: 'sticky',
                  top: theme.spacing(4),
                  overflowY: 'auto',
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: 'black',
                    fontFamily: theme.typography.fontFamily,
                    marginBottom: theme.spacing(1),
                  }}
                >
                  Status
                </Typography>
                <Divider sx={{ marginBottom: theme.spacing(1) }} />
                <MUIList component="nav">
                  {statusOptions.map((status) => (
                    <ListItemButton
                      key={status.value}
                      selected={selectedStatus === status.value}
                      onClick={() => {
                        setSelectedStatus(status.value);
                        setCurrentPage(1);
                      }}
                      sx={{
                        borderRadius: theme.shape.borderRadius,
                        marginBottom: theme.spacing(0.5),
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            variant="body1"
                            sx={{
                              fontFamily: theme.typography.fontFamily,
                              color: 'black',
                            }}
                          >
                            {status.label}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  ))}

                  {/* Returns Link without Icon */}
                  <ListItemButton
                    onClick={() => navigate('/returns')}
                    selected={location.pathname === '/returns'}
                    sx={{
                      borderRadius: theme.shape.borderRadius,
                      marginTop: theme.spacing(2),
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography
                          variant="body1"
                          sx={{
                            fontFamily: theme.typography.fontFamily,
                            color: 'black',
                          }}
                        >
                          Returns
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </MUIList>
              </Grid>
            )}

            {/* Main Content */}
            <Grid
              item
              xs={12}
              sm={isDesktop ? 9 : 12}
              md={isDesktop ? 10 : 12}
              sx={{
                padding: isMobile ? 0 : theme.spacing(2),
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'auto',
              }}
            >
              {/* Header with Search Bar and Filter Icon */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: isMobile ? 'column' : 'row',
                  justifyContent: isMobile ? 'space-between' : 'space-between',
                  alignItems: isMobile ? 'flex-start' : 'center',
                  marginBottom: theme.spacing(2),
                  gap: theme.spacing(2),
                  paddingX: isMobile ? theme.spacing(2) : 0,
                }}
              >
                {/* Search Bar and Filter Icon */}
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: isMobile ? '100%' : 'auto',
                    gap: theme.spacing(1),
                  }}
                >
                  {/* Search Bar */}
                  <Search>
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder="Search Orders…"
                      inputProps={{ 'aria-label': 'search' }}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </Search>

                  {/* Filter Icon, only show on mobile */}
                  {isMobile && (
                    <IconButton
                      aria-label="open filter"
                      onClick={toggleFilterDrawer(true)}
                      sx={{
                        color: theme.palette.primary.main,
                        backgroundColor: theme.palette.background.paper,
                        border: `1px solid ${theme.palette.primary.main}`,
                        '&:hover': {
                          backgroundColor: theme.palette.primary.main,
                          color: theme.palette.primary.contrastText,
                        },
                        borderRadius: '50%',
                        width: '40px',
                        height: '40px',
                      }}
                    >
                      <FilterListIcon />
                    </IconButton>
                  )}
                </Box>
              </Box>

              {/* Loading Indicator */}
              {loading && orders.length === 0 ? (
                <Box sx={{ textAlign: 'center', marginTop: theme.spacing(2) }}>
                  <CircularProgress />
                </Box>
              ) : filteredOrders.length > 0 ? (
                isDesktop ? (
                  <TableContainer component={Paper} sx={{ marginBottom: theme.spacing(2) }}>
                    <Table>
                      {/* Moved TableHead here */}
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              fontFamily: theme.typography.fontFamily,
                              fontWeight: 'normal',
                            }}
                          >
                            Date Placed
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              fontFamily: theme.typography.fontFamily,
                              fontWeight: 'normal',
                            }}
                          >
                            Qty
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              fontFamily: theme.typography.fontFamily,
                              fontWeight: 'normal',
                            }}
                          >
                            Total
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredOrders.map((order) => (
                          <OrderRow
                            key={order.id}
                            order={order}
                            isDesktop={isDesktop}
                            theme={theme}
                            fetchOrders={fetchOrders} // Pass fetchOrders to refresh the data
                            restaurantName={restaurantName}
                            restaurantLocation={restaurantLocation}
                            vendors={vendors}
                          />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  // Mobile View
                  filteredOrders.map((order) => (
                    <OrderRow
                      key={order.id}
                      order={order}
                      isDesktop={isDesktop}
                      theme={theme}
                      fetchOrders={fetchOrders}
                      restaurantName={restaurantName}
                      restaurantLocation={restaurantLocation}
                      vendors={vendors}
                    />
                  ))
                )
              ) : (
                <Typography
                  variant="body1"
                  align="center"
                  sx={{ marginTop: theme.spacing(2), fontFamily: theme.typography.fontFamily }}
                >
                  No orders found.
                </Typography>
              )}

              {/* Pagination */}
              {totalPages > 1 && !debouncedSearchTerm && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: theme.spacing(4),
                    marginBottom: isMobile ? theme.spacing(8) : 0,
                    flexWrap: 'nowrap', // Prevent wrapping
                  }}
                >
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                    size={isMobile ? 'small' : 'medium'} // Adjust size for mobile
                    showFirstButton={!isMobile} // Hide first/last buttons on mobile
                    showLastButton={!isMobile}
                    siblingCount={isMobile ? 0 : 1} // Reduce buttons on mobile
                    boundaryCount={isMobile ? 1 : 2}
                  />
                </Box>
              )}

              {/* Filter Drawer for Mobile */}
              <Drawer
                anchor="right"
                open={isFilterDrawerOpen}
                onClose={toggleFilterDrawer(false)}
                ModalProps={{
                  keepMounted: true,
                }}
              >
                <Box
                  sx={{
                    width: 250,
                    padding: theme.spacing(2),
                  }}
                  role="presentation"
                >
                  {/* Close Button */}
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton onClick={toggleFilterDrawer(false)} aria-label="close filter">
                      <CloseIcon />
                    </IconButton>
                  </Box>

                  {/* Filter Options */}
                  <Typography
                    variant="h6"
                    sx={{ fontFamily: theme.typography.fontFamily, marginBottom: theme.spacing(1), color: 'black' }}
                  >
                    Filter Orders
                  </Typography>
                  <Divider sx={{ marginBottom: theme.spacing(1) }} />
                  <MUIList component="nav">
                    {statusOptions.map((status) => (
                      <ListItemButton
                        key={status.value}
                        selected={selectedStatus === status.value}
                        onClick={() => {
                          setSelectedStatus(status.value);
                          setCurrentPage(1);
                          setIsFilterDrawerOpen(false);
                        }}
                        sx={{
                          borderRadius: theme.shape.borderRadius,
                          marginBottom: theme.spacing(0.5),
                        }}
                      >
                        <ListItemText
                          primary={
                            <Typography
                              variant="body1"
                              sx={{
                                fontFamily: theme.typography.fontFamily,
                                color: 'black',
                              }}
                            >
                              {status.label}
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    ))}

                    {/* Returns Link without Icon */}
                    <ListItemButton
                      onClick={() => {
                        navigate('/returns');
                        setIsFilterDrawerOpen(false);
                      }}
                      selected={location.pathname === '/returns'}
                      sx={{
                        borderRadius: theme.shape.borderRadius,
                        marginTop: theme.spacing(2),
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            variant="body1"
                            sx={{
                              fontFamily: theme.typography.fontFamily,
                              color: 'black',
                            }}
                          >
                            Returns
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </MUIList>
                </Box>
              </Drawer>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

// Separate OrderRow component for better readability and maintainability
function OrderRow({ order, isDesktop, theme, fetchOrders, restaurantName, restaurantLocation, vendors }) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const [editQuantityModalOpen, setEditQuantityModalOpen] = useState(false);
  const [quantityToUpdate, setQuantityToUpdate] = useState('');
  const [selectedOrderItem, setSelectedOrderItem] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);
  const [orderItemToDelete, setOrderItemToDelete] = useState(null);

  // State variables for Return Modal
  const [returnModalOpen, setReturnModalOpen] = useState(false);
  const [orderItemToReturn, setOrderItemToReturn] = useState(null);
  const [reason, setReason] = useState('');
  const [comments, setComments] = useState('');
  const [loadingReturn, setLoadingReturn] = useState(false);
  const [returnError, setReturnError] = useState(null);
  const [returnSuccess, setReturnSuccess] = useState(false);

  // Updated date format to "MMMM Do, YYYY" (e.g., "May 20th, 2024")
  const formattedDate = order.date_placed
    ? moment(order.date_placed).format('MMMM Do, YYYY')
    : 'Date not available';

  // Update totalAmount calculation
  const totalAmount =
    (order._sum_of_order_items_of_order?.order_item_total_price || 0) +
    (order._sum_of_order_items_of_order?.order_item_sales_tax_added || 0);

  const handleRowClick = () => {
    setOpen(!open);
  };

  const handleItemClick = (productId) => {
    navigate(`/product/${productId}`);
  };

  // Modify handleStartReturn to open modal instead of navigating
  const handleStartReturn = (orderItem) => {
    setOrderItemToReturn(orderItem);
    setReturnModalOpen(true);
  };

  const handleEditQuantity = (orderItem) => {
    setSelectedOrderItem(orderItem);
    setQuantityToUpdate(orderItem.quantity);
    setEditQuantityModalOpen(true);
  };

  const handleQuantityUpdateSubmit = async () => {
    try {
      const user = JSON.parse(localStorage.getItem('user'));

      if (!user) {
        setSnackbar({
          open: true,
          message: 'User not authenticated. Please log in again.',
          severity: 'error',
        });
        navigate('/');
        return;
      }

      const userEmail = user.email || 'No Email Provided';

      const payload = {
        quantity: parseInt(quantityToUpdate),
        order_item_id: selectedOrderItem.id,
        user_id: user.id,
      };

      // Make the PATCH request to update the quantity
      await axios.patch(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/edit_order_item_quantity',
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      // Send the email to the vendor
      const vendorId = selectedOrderItem.product?._vendor?.id;

      if (!vendorId) {
        setSnackbar({
          open: true,
          message: 'Supplier information not available.',
          severity: 'error',
        });
      } else {
        // Find vendor email and name from vendors state
        const vendor = vendors.find((v) => v.vendor_id === vendorId);

        if (!vendor) {
          setSnackbar({
            open: true,
            message: 'Supplier not found for this item.',
            severity: 'error',
          });
        } else {
          const vendorEmail = vendor.vendor_email;
          const vendorName = vendor._vendor?.Short_Name || 'Supplier';

          const restaurantNameFromProps = restaurantName || user.restaurant_name || 'A restaurant';
          const restaurantLocationFromProps = restaurantLocation || 'Unknown Location';

          const subjectHeader = `${restaurantNameFromProps} has requested an order quantity change`;

          // Prepare email body
          let emailBody = `Dear ${vendorName},\n\n`;
          emailBody += `${restaurantNameFromProps} has requested to change the quantity of an order item through FareFood.\n\n`;
          emailBody += `Restaurant Details:\n`;
          emailBody += `Name: ${restaurantNameFromProps}\n`;
          emailBody += `Location: ${restaurantLocationFromProps}\n\n`;
          emailBody += 'Order Item Details:\n\n';

          const catalogNumber =
            selectedOrderItem.product?.catalog_number ||
            selectedOrderItem.product?.FF_Catalog_Number ||
            'N/A';
          const shortDescription =
            selectedOrderItem.product?.short_description || 'Product Description';
          const originalQuantity = `${selectedOrderItem.quantity} Case(s)`;
          const newQuantity = `${quantityToUpdate} Case(s)`;

          emailBody += `Catalog Number: ${catalogNumber}\n`;
          emailBody += `Description: ${shortDescription}\n`;
          emailBody += `Original Quantity: ${originalQuantity}\n`;
          emailBody += `New Quantity: ${newQuantity}\n\n`;

          emailBody += `If there are any issues with fulfilling this request or if any items are out of stock, please contact us as soon as possible at ${userEmail}, michael@fare.food, vincent@fare.food, or suppliers@fare.food. Please CC us on any communications.\n\n`;
          emailBody += 'Thank you for partnering with FareFood!\n\n';
          emailBody += 'Best regards,\nThe FareFood Team';

          // Send email via the email API
          await axios.post(
            'https://xawe-auye-zrgm.n7d.xano.io/api:E1YzybDk/order_notification',
            {
              admin_email: vendorEmail,
              subject_header: subjectHeader,
              email_body: emailBody,
            },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );

          setSnackbar({
            open: true,
            message: 'Quantity update request sent to vendor.',
            severity: 'success',
          });
        }
      }

      // Close the modal
      setEditQuantityModalOpen(false);

      // Refresh the orders
      fetchOrders();
    } catch (error) {
      console.error('Error updating quantity:', error);
      setSnackbar({
        open: true,
        message: 'Failed to update quantity.',
        severity: 'error',
      });
    }
  };

  const handleDeleteOrderItem = (orderItem) => {
    setOrderItemToDelete(orderItem);
    setDeleteConfirmModalOpen(true);
  };

  const handleDeleteOrderItemSubmit = async () => {
    try {
      const user = JSON.parse(localStorage.getItem('user'));

      if (!user) {
        setSnackbar({
          open: true,
          message: 'User not authenticated. Please log in again.',
          severity: 'error',
        });
        navigate('/');
        return;
      }

      const userEmail = user.email || 'No Email Provided';

      // DELETE request to delete the order item
      await axios.delete(
        `https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/order_items/${orderItemToDelete.id}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          data: {
            order_items_id: orderItemToDelete.id,
          },
        }
      );

      // Email the vendor about the deletion
      const vendorId = orderItemToDelete.product?._vendor?.id;

      if (!vendorId) {
        setSnackbar({
          open: true,
          message: 'Supplier information not available.',
          severity: 'error',
        });
      } else {
        // Find vendor email and name from vendors state
        const vendor = vendors.find((v) => v.vendor_id === vendorId);

        if (!vendor) {
          setSnackbar({
            open: true,
            message: 'Supplier not found for this item.',
            severity: 'error',
          });
        } else {
          const vendorEmail = vendor.vendor_email;
          const vendorName = vendor._vendor?.Short_Name || 'Supplier';

          const restaurantNameFromProps = restaurantName || user.restaurant_name || 'A restaurant';
          const restaurantLocationFromProps = restaurantLocation || 'Unknown Location';

          const subjectHeader = `${restaurantNameFromProps} has deleted an order item`;

          // Prepare email body
          let emailBody = `Dear ${vendorName},\n\n`;
          emailBody += `${restaurantNameFromProps} has requested to cancel an order item through FareFood.\n\n`;
          emailBody += `Restaurant Details:\n`;
          emailBody += `Name: ${restaurantNameFromProps}\n`;
          emailBody += `Location: ${restaurantLocationFromProps}\n\n`;
          emailBody += 'Deleted Order Item Details:\n\n';

          const catalogNumber =
            orderItemToDelete.product?.catalog_number ||
            orderItemToDelete.product?.FF_Catalog_Number ||
            'N/A';
          const shortDescription =
            orderItemToDelete.product?.short_description || 'Product Description';
          const quantity = `${orderItemToDelete.quantity} Case(s)`;

          emailBody += `Catalog Number: ${catalogNumber}\n`;
          emailBody += `Description: ${shortDescription}\n`;
          emailBody += `Quantity: ${quantity}\n\n`;

          emailBody += `If there are any issues with this request, please contact us as soon as possible at ${userEmail}, michael@fare.food, vincent@fare.food, or suppliers@fare.food. Please CC us on any communications.\n\n`;
          emailBody += 'Thank you for partnering with FareFood!\n\n';
          emailBody += 'Best regards,\nThe FareFood Team';

          // Send email via the email API
          await axios.post(
            'https://xawe-auye-zrgm.n7d.xano.io/api:E1YzybDk/order_notification',
            {
              admin_email: vendorEmail,
              subject_header: subjectHeader,
              email_body: emailBody,
            },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );

          setSnackbar({
            open: true,
            message: 'Order item deleted and vendor notified.',
            severity: 'success',
          });
        }
      }

      // Close the modal
      setDeleteConfirmModalOpen(false);

      // Refresh the orders
      fetchOrders();
    } catch (error) {
      console.error('Error deleting order item:', error);
      setSnackbar({
        open: true,
        message: 'Failed to delete order item.',
        severity: 'error',
      });
    }
  };

  // Handle Return Submission
  const handleReturnSubmit = async () => {
    setLoadingReturn(true);
    setReturnError(null);

    try {
      const user = JSON.parse(localStorage.getItem('user'));

      if (!user) {
        setReturnError('User information not found. Please log in again.');
        setLoadingReturn(false);
        return;
      }

      // Construct the subject using order item details
      const subject = `Return of ${orderItemToReturn.product?.short_description || 'Product'}, quantity ${orderItemToReturn.quantity || 0}`;

      // Construct the description using user input
      const description = comments || 'No additional comments provided.';

      // Prepare the payload with ticket_type_id hardcoded to 1
      const payload = {
        subject,
        description,
        user_id: user.id,
        ticket_type_id: 1,        // Hardcoded to 1 as per requirement
        ticket_status_id: 0,      // Assuming 0 represents 'Open' or 'New'
        admin_notes: "",
        last_updated: null
      };

      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        throw new Error('Authentication token not found. Please log in again.');
      }

      const response = await axios.post(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/help_tickets',
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
          },
        }
      );

      console.log('Return request submitted successfully:', response.data);
      setReturnSuccess(true);

      // Prepare Slack notification payload
      const slackPayload = {
        text: `📦 *New Return Submitted*\n
*User:* ${user.name || 'Unknown User'} (${user.email || 'No Email Provided'})\n
*Product:* ${orderItemToReturn.product?.short_description || 'N/A'}\n
*Quantity:* ${orderItemToReturn.quantity || 0}\n
*Total Price:* $${orderItemToReturn.total_price ? orderItemToReturn.total_price.toFixed(2) : '0.00'}\n
*Reason:* ${reason}\n
*Comments:* ${comments || 'None'}`
      };

      // Send Slack notification
      const slackResponse = await axios.post(
        'https://xawe-auye-zrgm.n7d.xano.io/api:XF3EVVma/slack_customers',
        slackPayload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (slackResponse.status !== 200) {
        console.error('Failed to send Slack notification:', slackResponse.data);
      } else {
        console.log('Slack notification sent successfully.');
      }

      // Email the vendor about the return
      const vendorId = orderItemToReturn.product?._vendor?.id;

      if (!vendorId) {
        setReturnError('Supplier information not available.');
      } else {
        // Find vendor email and name from vendors state
        const vendor = vendors.find((v) => v.vendor_id === vendorId);

        if (!vendor) {
          setReturnError('Supplier not found for this item.');
        } else {
          const vendorEmail = vendor.vendor_email;
          const vendorName = vendor._vendor?.Short_Name || 'Supplier';

          const restaurantNameFromProps = restaurantName || user.restaurant_name || 'A restaurant';
          const restaurantLocationFromProps = restaurantLocation || 'Unknown Location';

          const subjectHeader = `${restaurantNameFromProps} has requested a product return`;

          // Prepare email body
          let emailBody = `Dear ${vendorName},\n\n`;
          emailBody += `${restaurantNameFromProps} has requested to return a product through FareFood.\n\n`;
          emailBody += `Restaurant Details:\n`;
          emailBody += `Name: ${restaurantNameFromProps}\n`;
          emailBody += `Location: ${restaurantLocationFromProps}\n\n`;
          emailBody += 'Return Item Details:\n\n';

          const catalogNumber =
            orderItemToReturn.product?.catalog_number ||
            orderItemToReturn.product?.FF_Catalog_Number ||
            'N/A';
          const shortDescription =
            orderItemToReturn.product?.short_description || 'Product Description';
          const quantity = `${orderItemToReturn.quantity} Case(s)`;
          const totalPrice = `$${orderItemToReturn.total_price ? orderItemToReturn.total_price.toFixed(2) : '0.00'}`;
          const returnReason = reason || 'No reason provided.';
          const additionalComments = comments || 'No additional comments provided.';

          emailBody += `Catalog Number: ${catalogNumber}\n`;
          emailBody += `Description: ${shortDescription}\n`;
          emailBody += `Quantity: ${quantity}\n`;
          emailBody += `Total Price: ${totalPrice}\n`;
          emailBody += `Reason for Return: ${returnReason}\n`;
          emailBody += `Additional Comments: ${additionalComments}\n\n`;

          emailBody += `If there are any issues with this request, please contact us as soon as possible at ${user.email}, michael@fare.food, vincent@fare.food, or suppliers@fare.food. Please CC us on any communications.\n\n`;
          emailBody += 'Thank you for partnering with FareFood!\n\n';
          emailBody += 'Best regards,\nThe FareFood Team';

          // Send email via the email API
          await axios.post(
            'https://xawe-auye-zrgm.n7d.xano.io/api:E1YzybDk/order_notification',
            {
              admin_email: vendorEmail,
              cc_emails: 'vincent@fare.food,michael@fare.food', // Include CC emails
              subject_header: subjectHeader,
              email_body: emailBody,
            },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );

          console.log('Supplier notified about the return.');
        }
      }

      // Close the modal after a short delay
      setTimeout(() => {
        setReturnModalOpen(false);
        setReason('');
        setComments('');
        setOrderItemToReturn(null);
      }, 2000);
    } catch (err) {
      console.error('Error submitting return request:', err);
      setReturnError(err.message || 'An error occurred while submitting the return request.');
    } finally {
      setLoadingReturn(false);
    }
  };

  // Mobile-specific state for expanding order items
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleMobileExpandClick = () => {
    setMobileOpen(!mobileOpen);
  };

  // For formatting date and time in mobile view
  const formattedDateTime = order.date_placed
    ? moment(order.date_placed).format('MMMM Do, YYYY')
    : 'Date not available';

  // Compute total quantity by summing up quantities of order items
  const totalQuantity = order.orderitem
    ? order.orderitem.reduce((sum, item) => sum + (item.quantity || 0), 0)
    : 0;

  return (
    <>
      {/* For Desktop: Use Table Rows */}
      {isDesktop ? (
        <>
          <React.Fragment key={order.id}>
            <TableRow
              hover
              onClick={handleRowClick}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: theme.palette.action.hover,
                  transform: 'scale(1.01)',
                  transition: 'transform 0.2s ease-in-out',
                },
              }}
            >
              <TableCell sx={{ fontFamily: theme.typography.fontFamily }}>
                {formattedDate}
              </TableCell>
              <TableCell
                align="right"
                sx={{ fontFamily: theme.typography.fontFamily }}
              >
                {totalQuantity}
              </TableCell>
              <TableCell align="right" sx={{ fontFamily: theme.typography.fontFamily }}>
                ${totalAmount.toFixed(2)}
              </TableCell>
            </TableRow>
            {/* Collapsible Row for Order Items */}
            <TableRow>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <Box
                    margin={1}
                    sx={{
                      overflowX: 'auto',
                      padding: theme.spacing(0, 1),
                      width: '100%',
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      component="div"
                      sx={{ fontFamily: theme.typography.fontFamily }}
                    >
                      Order Items
                    </Typography>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              fontFamily: theme.typography.fontFamily,
                              color: 'black',
                              fontWeight: 'normal',
                            }}
                          >
                            Product Name
                          </TableCell>
                          {isDesktop && (
                            <TableCell
                              align="right"
                              sx={{
                                fontFamily: theme.typography.fontFamily,
                                color: 'black',
                                fontWeight: 'normal',
                              }}
                            >
                              Supplier
                            </TableCell>
                          )}
                          <TableCell
                            align="right"
                            sx={{
                              fontFamily: theme.typography.fontFamily,
                              color: 'black',
                              fontWeight: 'normal',
                            }}
                          >
                            Quantity
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              fontFamily: theme.typography.fontFamily,
                              color: 'black',
                              fontWeight: 'normal',
                            }}
                          >
                            Total Price
                          </TableCell>
                          {isDesktop && (
                            <TableCell
                              align="right"
                              sx={{
                                fontFamily: theme.typography.fontFamily,
                                color: 'black',
                                fontWeight: 'normal',
                              }}
                            >
                              Sales Tax
                            </TableCell>
                          )}
                          <TableCell
                            align="right"
                            sx={{
                              fontFamily: theme.typography.fontFamily,
                              color: 'black',
                              fontWeight: 'normal',
                            }}
                          >
                            Actions
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {order.orderitem && order.orderitem.length > 0 ? (
                          order.orderitem.map((item) => {
                            // Use moment for date comparison
                            const orderDate = moment(order.date_placed);
                            const today = moment();
                            const isOrderPlacedToday = orderDate.isSame(today, 'day');

                            return (
                              <TableRow
                                key={item.id}
                                sx={{
                                  '&:hover': {
                                    backgroundColor: theme.palette.action.hover,
                                    transform: 'scale(1.01)',
                                    transition: 'transform 0.2s ease-in-out',
                                  },
                                }}
                              >
                                <TableCell
                                  sx={{
                                    fontFamily: theme.typography.fontFamily,
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => handleItemClick(item.product_id)}
                                >
                                  {item.product?.short_description || 'Product not available'}
                                </TableCell>
                                {isDesktop && (
                                  <TableCell
                                    align="right"
                                    sx={{ fontFamily: theme.typography.fontFamily }}
                                  >
                                    {item.product?._vendor?.Short_Name || 'Unknown Supplier'}
                                  </TableCell>
                                )}
                                <TableCell
                                  align="right"
                                  sx={{ fontFamily: theme.typography.fontFamily }}
                                >
                                  {item.quantity || 0}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{ fontFamily: theme.typography.fontFamily }}
                                >
                                  ${item.total_price ? item.total_price.toFixed(2) : '0.00'}
                                </TableCell>
                                {isDesktop && (
                                  <TableCell
                                    align="right"
                                    sx={{ fontFamily: theme.typography.fontFamily }}
                                  >
                                    $
                                    {item.sales_tax_added
                                      ? item.sales_tax_added.toFixed(2)
                                      : '0.00'}
                                  </TableCell>
                                )}
                                <TableCell align="right">
                                  {!isOrderPlacedToday && (
                                    <Tooltip title="Initiate Return">
                                      <IconButton
                                        aria-label="return"
                                        size="small"
                                        sx={{
                                          color: theme.palette.primary.main,
                                          padding: 0,
                                        }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleStartReturn(item);
                                        }}
                                      >
                                        <ReplyIcon />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                  {isOrderPlacedToday && (
                                    <>
                                      <Tooltip title="Edit Quantity">
                                        <IconButton
                                          aria-label="edit-quantity"
                                          size="small"
                                          sx={{
                                            color: theme.palette.primary.main,
                                            padding: 0,
                                            marginLeft: theme.spacing(1),
                                          }}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleEditQuantity(item);
                                          }}
                                        >
                                          <EditIcon />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title="Delete Item">
                                        <IconButton
                                          aria-label="delete-item"
                                          size="small"
                                          sx={{
                                            color: theme.palette.error.main,
                                            padding: 0,
                                            marginLeft: theme.spacing(1),
                                          }}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleDeleteOrderItem(item);
                                          }}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </>
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })
                        ) : (
                          <TableRow>
                            <TableCell
                              colSpan={6}
                              align="center"
                              sx={{ fontFamily: theme.typography.fontFamily }}
                            >
                              No items in this order.
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </React.Fragment>
        </>
      ) : (
        // For Mobile: Use Cards
        <>
          <Card
            sx={{
              marginBottom: 0,
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              },
              borderRadius: 0,
            }}
            onClick={handleRowClick}
          >
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="subtitle1" sx={{ fontFamily: theme.typography.fontFamily }}>
                  {formattedDateTime}
                </Typography>
                <IconButton onClick={handleRowClick} size="small">
                  {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Box>
              <Typography variant="body2" sx={{ fontFamily: theme.typography.fontFamily }}>
                {totalQuantity} items
              </Typography>
              <Typography variant="body2" sx={{ fontFamily: theme.typography.fontFamily }}>
                Total: ${totalAmount.toFixed(2)}
              </Typography>
            </CardContent>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <CardContent>
                <Typography
                  variant="subtitle1"
                  sx={{ fontFamily: theme.typography.fontFamily, marginBottom: theme.spacing(1) }}
                >
                  Order Items
                </Typography>
                {order.orderitem && order.orderitem.length > 0 ? (
                  order.orderitem.map((item) => {
                    const orderDate = moment(order.date_placed);
                    const today = moment();
                    const isOrderPlacedToday = orderDate.isSame(today, 'day');

                    return (
                      <Box
                        key={item.id}
                        sx={{
                          marginBottom: theme.spacing(2),
                          padding: theme.spacing(1),
                          border: `1px solid ${theme.palette.divider}`,
                          borderRadius: theme.shape.borderRadius,
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 'bold',
                            cursor: 'pointer',
                          }}
                          onClick={() => handleItemClick(item.product_id)}
                        >
                          {item.product?.short_description || 'Product not available'}
                        </Typography>
                        <Typography variant="body2" sx={{ fontFamily: theme.typography.fontFamily }}>
                          Supplier: {item.product?._vendor?.Short_Name || 'Unknown Supplier'}
                        </Typography>
                        <Typography variant="body2" sx={{ fontFamily: theme.typography.fontFamily }}>
                          Quantity: {item.quantity || 0}
                        </Typography>
                        <Typography variant="body2" sx={{ fontFamily: theme.typography.fontFamily }}>
                          Total Price: ${item.total_price ? item.total_price.toFixed(2) : '0.00'}
                        </Typography>
                        <Typography variant="body2" sx={{ fontFamily: theme.typography.fontFamily }}>
                          Sales Tax: $
                          {item.sales_tax_added ? item.sales_tax_added.toFixed(2) : '0.00'}
                        </Typography>
                        <Box sx={{ marginTop: theme.spacing(1), display: 'flex' }}>
                          {!isOrderPlacedToday && (
                            <Tooltip title="Initiate Return">
                              <IconButton
                                aria-label="return"
                                size="small"
                                sx={{
                                  color: theme.palette.primary.main,
                                  padding: 0,
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleStartReturn(item);
                                }}
                              >
                                <ReplyIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          {isOrderPlacedToday && (
                            <>
                              <Tooltip title="Edit Quantity">
                                <IconButton
                                  aria-label="edit-quantity"
                                  size="small"
                                  sx={{
                                    color: theme.palette.primary.main,
                                    padding: 0,
                                    marginLeft: theme.spacing(1),
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleEditQuantity(item);
                                  }}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Delete Item">
                                <IconButton
                                  aria-label="delete-item"
                                  size="small"
                                  sx={{
                                    color: theme.palette.error.main,
                                    padding: 0,
                                    marginLeft: theme.spacing(1),
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteOrderItem(item);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </>
                          )}
                        </Box>
                      </Box>
                    );
                  })
                ) : (
                  <Typography
                    variant="body2"
                    sx={{ fontFamily: theme.typography.fontFamily, textAlign: 'center' }}
                  >
                    No items in this order.
                  </Typography>
                )}
              </CardContent>
            </Collapse>
          </Card>
        </>
      )}

      {/* Edit Quantity Modal */}
      <Dialog open={editQuantityModalOpen} onClose={() => setEditQuantityModalOpen(false)}>
        <DialogTitle>Edit Quantity</DialogTitle>
        <DialogContent>
          <Typography>
            Please enter the new quantity for this order item. We will notify the vendor of this request.
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            label="Quantity"
            type="number"
            fullWidth
            variant="outlined"
            value={quantityToUpdate}
            onChange={(e) => setQuantityToUpdate(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setEditQuantityModalOpen(false)}
            sx={{ color: theme.palette.error.main }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleQuantityUpdateSubmit}
            sx={{ color: theme.palette.success.main }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Modal */}
      <Dialog open={deleteConfirmModalOpen} onClose={() => setDeleteConfirmModalOpen(false)}>
        <DialogTitle>Delete Order Item</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this order item? We will notify the vendor of this request.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDeleteConfirmModalOpen(false)}
            sx={{ color: theme.palette.error.main }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteOrderItemSubmit}
            sx={{ color: theme.palette.success.main }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Return Modal */}
      <Dialog open={returnModalOpen} onClose={() => setReturnModalOpen(false)}>
        <DialogTitle>Initiate Return</DialogTitle>
        <DialogContent>
          {orderItemToReturn && (
            <>
              {/* Display Order Item Details */}
              <Box sx={{ marginBottom: theme.spacing(2) }}>
                <Typography variant="subtitle1">
                  <strong>Product:</strong> {orderItemToReturn.product?.short_description || 'N/A'}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>Quantity:</strong> {orderItemToReturn.quantity || 0}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>Total Price:</strong> $
                  {orderItemToReturn.total_price
                    ? orderItemToReturn.total_price.toFixed(2)
                    : '0.00'}
                </Typography>
              </Box>

              <TextField
                select
                label="Reason for Return"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                fullWidth
                required
                margin="normal"
              >
                <MenuItem value="Defective">Defective Product</MenuItem>
                <MenuItem value="Wrong Item">Received Wrong Item</MenuItem>
                <MenuItem value="Changed Mind">Changed Mind</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </TextField>

              <TextField
                label="Additional Comments"
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                fullWidth
                multiline
                rows={4}
                margin="normal"
              />

              {returnError && (
                <Typography color="error" sx={{ marginTop: theme.spacing(1) }}>
                  {returnError}
                </Typography>
              )}

              {returnSuccess && (
                <Typography color="primary" sx={{ marginTop: theme.spacing(1) }}>
                  Return request submitted successfully!
                </Typography>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setReturnModalOpen(false)}
            sx={{ color: theme.palette.error.main }}
            disabled={loadingReturn}
          >
            Cancel
          </Button>
          <Button
            onClick={handleReturnSubmit}
            sx={{ color: theme.palette.success.main }}
            disabled={loadingReturn}
          >
            {loadingReturn ? 'Submitting...' : 'Submit Return Request'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for Notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%', fontFamily: theme.typography.fontFamily }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default Orders;
