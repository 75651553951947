// src/hooks/usePersistedState.js
import { useState, useEffect } from 'react';

/**
 * Custom hook to persist state in localStorage with type handling.
 *
 * @param {string} key - The key under which to store the value in localStorage.
 * @param {*} defaultValue - The default value to use if none is found in localStorage.
 * @param {Function} [parser=JSON.parse] - Function to parse the stored value.
 * @returns {[any, Function]} - An array containing the state and the setter function.
 */
function usePersistedState(key, defaultValue, parser = JSON.parse) {
  // Initialize state from localStorage or use defaultValue
  const [state, setState] = useState(() => {
    const persisted = localStorage.getItem(key);
    if (persisted) {
      try {
        return parser(persisted);
      } catch (e) {
        console.error(`Error parsing stored value for key "${key}":`, e);
        return defaultValue;
      }
    }
    return defaultValue;
  });

  // Update localStorage whenever the state changes
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
}

export default usePersistedState;
