// Inventory.js
import React, { useState, useEffect } from 'react';
import TopNavBar from './TopNavBar';
import {
  Grid,
  Typography,
  Box,
  IconButton,
  Drawer,
  Container,
  useMediaQuery,
  CircularProgress,
  Button,
  Pagination,
} from '@mui/material';
import {
  ViewModule as ViewModuleIcon,
  ViewList as ViewListIcon,
  FilterList as FilterListIcon,
  Close as CloseIcon,
  Add as AddIcon,
  Search as SearchIcon,
} from '@mui/icons-material';
import { useTheme, styled, alpha } from '@mui/material/styles';
import axios from 'axios';
import InventoryFilterPanel from './InventoryFilterPanel';
import InventoryProductCard from './InventoryProductCard';
import AddInventoryItemModal from './AddInventoryItemModal';
import ProductDetailsModal from './ProductDetailsModal';
import { useNavigate } from 'react-router-dom';
import usePersistedState from './hooks/usePersistedState';
import { Helmet } from 'react-helmet';
import InventoryListItem from './InventoryListItem'; // Import the new component

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius * 5,
  border: `1px solid ${theme.palette.primary.main}`,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.25),
  },
  width: '100%',
  maxWidth: 500,
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled('input')(({ theme }) => ({
  color: theme.palette.text.primary,
  fontFamily: theme.typography.fontFamily,
  width: '100%',
  padding: theme.spacing(1, 1, 1, 0),
  paddingLeft: `calc(1em + ${theme.spacing(4)})`,
  border: 'none',
  outline: 'none',
  backgroundColor: 'transparent',
}));

function Inventory() {
  const [inventory, setInventory] = useState([]);
  const [filteredInventory, setFilteredInventory] = useState([]);
  const [loading, setLoading] = useState(false);

  // Use persisted states for filters
  const [searchTerm, setSearchTerm] = usePersistedState('inventory_searchTerm', '');
  const [selectedCategory, setSelectedCategory] = usePersistedState('inventory_selectedCategory', 0);
  const [selectedSubcategory, setSelectedSubcategory] = usePersistedState('inventory_selectedSubcategory', 0);
  const [selectedVendor, setSelectedVendor] = usePersistedState('inventory_selectedVendor', []);
  const [selectedBrands, setSelectedBrands] = usePersistedState('inventory_selectedBrands', []);
  const [sortOption, setSortOption] = usePersistedState('inventory_sortOption', 'default');

  const [viewMode, setViewMode] = useState('grid'); // 'grid' or 'list'
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openProductModal, setOpenProductModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const navigate = useNavigate();

  // Multiplier State
  const [multiplier, setMultiplier] = useState(1);

  // Filter States
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);

  // State for Unique Brands
  const [uniqueBrands, setUniqueBrands] = useState([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Pagination States
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 25; // Adjust as needed

  // State for Total Results
  const [totalResults, setTotalResults] = useState(0);

  // Fetch multiplier on component mount
  useEffect(() => {
    const fetchUserData = () => {
      const userData = localStorage.getItem('user');
      if (userData) {
        try {
          const parsedUser = JSON.parse(userData);
          const storedMultiplier = parseFloat(parsedUser.multiplier) || 1;
          setMultiplier(storedMultiplier);
        } catch (e) {
          console.error('Error parsing user data from local storage:', e);
          setMultiplier(1);
        }
      } else {
        console.error('User data not found in localStorage.');
        setMultiplier(1);
      }
    };

    fetchUserData();
  }, []);

  // Fetch Filter Data (Categories)
  const fetchFilterData = async () => {
    try {
      const authToken = localStorage.getItem('authToken');
      const headers = { Authorization: `Bearer ${authToken}` };

      // Fetch Categories
      const categoriesResponse = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/category',
        { headers }
      );
      setCategories(categoriesResponse.data);
    } catch (error) {
      console.error('Error fetching filter data:', error);
      setCategories([]);
    }
  };

  // Fetch Subcategories based on selectedCategory
  const fetchSubcategories = async (categoryId) => {
    if (categoryId === 0) {
      setSubcategories([]);
      setSelectedSubcategory(0);
      return;
    }
    try {
      const authToken = localStorage.getItem('authToken');
      const headers = { Authorization: `Bearer ${authToken}` };
      const response = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/subcategory',
        {
          headers,
          params: { filter: categoryId },
        }
      );
      setSubcategories(response.data);
    } catch (error) {
      console.error('Error fetching subcategories:', error);
      setSubcategories([]);
    }
  };

  // Fetch Inventory Data
  const fetchInventory = async () => {
    setLoading(true);
    try {
      const authToken = localStorage.getItem('authToken');
      const user = JSON.parse(localStorage.getItem('user'));

      if (!authToken || !user || !user.restaurant_id) {
        console.error('Authentication token or user data not found.');
        navigate('/');
        return;
      }

      const params = {
        restaurantidfilter: user.restaurant_id,
        limit: 1000,
        offset: 0,
      };

      const headers = { Authorization: `Bearer ${authToken}` };

      const response = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/inventoryreact',
        { params, headers }
      );

      const fetchedInventory = response.data.items || [];

      setInventory(fetchedInventory);
      setFilteredInventory(fetchedInventory);
      setTotalResults(fetchedInventory.length);
    } catch (error) {
      console.error('Error fetching inventory:', error);
      setInventory([]);
      setFilteredInventory([]);
      setTotalResults(0);
    } finally {
      setLoading(false);
    }
  };

  // Extract Unique Brands from Inventory
  useEffect(() => {
    if (inventory.length > 0) {
      const brands = Array.from(
        new Set(
          inventory
            .map((item) => item._inventory_items_product_info?.brand)
            .filter((brand) => brand)
        )
      );
      setUniqueBrands(brands);
    } else {
      setUniqueBrands([]);
    }
  }, [inventory]);

  // Initial Fetches
  useEffect(() => {
    fetchFilterData();
    fetchInventory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch subcategories whenever selectedCategory changes
  useEffect(() => {
    fetchSubcategories(selectedCategory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  // Apply Filters
  useEffect(() => {
    let filteredData = [...inventory];

    // Filter by search term
    if (searchTerm) {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();
      filteredData = filteredData.filter((item) =>
        item.item_description.toLowerCase().includes(lowerCaseSearchTerm)
      );
    }

    // Filter by category
    if (selectedCategory !== 0) {
      filteredData = filteredData.filter(
        (item) =>
          item._inventory_items_product_info?.category_id === selectedCategory
      );
    }

    // Filter by subcategory
    if (selectedSubcategory !== 0) {
      filteredData = filteredData.filter(
        (item) =>
          item._inventory_items_product_info?.subcategory_id ===
          selectedSubcategory
      );
    }

    // Filter by vendor
    if (selectedVendor.length > 0) {
      filteredData = filteredData.filter((item) =>
        selectedVendor.includes(item._inventory_items_product_info?.vendor_id)
      );
    }

    // Filter by brand
    if (selectedBrands.length > 0) {
      filteredData = filteredData.filter((item) =>
        selectedBrands.includes(item._inventory_items_product_info?.brand)
      );
    }

    // Apply sorting
    if (sortOption !== 'default') {
      filteredData = sortInventory(filteredData, sortOption, multiplier);
    }

    setFilteredInventory(filteredData);
    setTotalResults(filteredData.length);
    setCurrentPage(1); // Reset to first page on filter change
  }, [
    inventory,
    searchTerm,
    selectedCategory,
    selectedSubcategory,
    selectedVendor,
    selectedBrands,
    sortOption,
    multiplier,
  ]);

  // Function to sort inventory
  const sortInventory = (data, sortOption, multiplier) => {
    const sorted = [...data];
    switch (sortOption) {
      case 'price_low_high':
        sorted.sort(
          (a, b) =>
            (a._inventory_items_product_info.purchase_price || 0) * multiplier -
            (b._inventory_items_product_info.purchase_price || 0) * multiplier
        );
        break;
      case 'price_high_low':
        sorted.sort(
          (a, b) =>
            (b._inventory_items_product_info.purchase_price || 0) * multiplier -
            (a._inventory_items_product_info.purchase_price || 0) * multiplier
        );
        break;
      case 'stock_low_high':
        sorted.sort((a, b) => (a.On_Hand || 0) - (b.On_Hand || 0));
        break;
      case 'stock_high_low':
        sorted.sort((a, b) => (b.On_Hand || 0) - (a.On_Hand || 0));
        break;
      default:
        // Default sorting by item description
        sorted.sort((a, b) =>
          a.item_description.localeCompare(b.item_description)
        );
        break;
    }
    return sorted;
  };

  // Clear All Filters
  const clearAllFilters = () => {
    setSelectedCategory(0);
    setSelectedSubcategory(0);
    setSelectedVendor([]);
    setSelectedBrands([]);
    setSearchTerm('');
    setSortOption('default');
    setFilteredInventory(inventory);
    setTotalResults(inventory.length);
    if (isMobile) {
      setIsDrawerOpen(false);
    }
  };

  // Toggle Drawer for Mobile Filters
  const toggleDrawer = (open) => () => {
    setIsDrawerOpen(open);
  };

  // Handle View Mode Toggle
  const handleViewToggle = () => {
    setViewMode(viewMode === 'grid' ? 'list' : 'grid');
  };

  // Handle Page Change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Get current page inventory items
  const indexOfLastItem = currentPage * productsPerPage;
  const indexOfFirstItem = indexOfLastItem - productsPerPage;
  const currentInventory = filteredInventory.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        overflow: 'hidden',
      }}
    >
      {/* Helmet for setting the page title */}
      <Helmet>
        <title>FareFood | Inventory</title>
      </Helmet>

      {/* Sticky TopNavBar */}
      <Box sx={{ position: 'sticky', top: 0, zIndex: theme.zIndex.appBar }}>
        <TopNavBar />
      </Box>

      {/* Main Content Area */}
      <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
        <Container
          maxWidth="lg"
          sx={{
            paddingTop: theme.spacing(8),
          }}
        >
          {/* Main Title */}
          <Typography
            variant="h5"
            component="h2"
            sx={{
              marginTop: isMobile ? theme.spacing(1) : theme.spacing(4),
              marginBottom: isMobile ? theme.spacing(1) : theme.spacing(2),
            }}
          >
            Inventory
          </Typography>

          <Grid container spacing={2} alignItems="flex-start">
            {/* Filter Sidebar (Desktop) */}
            <Grid
              item
              xs={12}
              sm={3}
              md={2}
              sx={{
                paddingTop: theme.spacing(2),
                paddingBottom: theme.spacing(2),
                display: { xs: 'none', sm: 'block' },
                height: 'calc(100vh - 64px)',
                position: 'sticky',
                top: '64px',
                overflowY: 'auto',
                backgroundColor: theme.palette.background.paper,
              }}
            >
              <InventoryFilterPanel
                categories={categories}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                subcategories={subcategories}
                selectedSubcategory={selectedSubcategory}
                selectedVendor={selectedVendor}
                setSelectedVendor={setSelectedVendor}
                selectedBrands={selectedBrands}
                setSelectedBrands={setSelectedBrands}
                uniqueBrands={uniqueBrands}
                clearAllFilters={clearAllFilters}
              />
            </Grid>

            {/* Products Grid/List */}
            <Grid
              item
              xs={12}
              sm={9}
              md={10}
              sx={{
                paddingTop: isMobile ? theme.spacing(1) : theme.spacing(0),
                paddingBottom: isMobile ? theme.spacing(1) : theme.spacing(2),
              }}
            >
              {/* Header Controls */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: isMobile ? 'column' : 'row',
                  justifyContent: 'space-between',
                  alignItems: isMobile ? 'flex-start' : 'center',
                  marginBottom: theme.spacing(2),
                  gap: theme.spacing(1),
                }}
              >
                {/* Search Bar */}
                <Search sx={{ width: isMobile ? '100%' : 'auto' }}>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search Inventory…"
                    inputProps={{ 'aria-label': 'search' }}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </Search>

                {/* Right Side Controls */}
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: theme.spacing(1),
                  }}
                >
                  {/* New Button */}
                  {isMobile ? (
                    <IconButton
                      onClick={() => setOpenModal(true)}
                      sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                        '&:hover': {
                          backgroundColor: theme.palette.primary.dark,
                        },
                      }}
                      aria-label="add inventory item"
                    >
                      <AddIcon />
                    </IconButton>
                  ) : (
                    <Button
                      variant="contained"
                      startIcon={<AddIcon />}
                      onClick={() => setOpenModal(true)}
                      sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                        '&:hover': {
                          backgroundColor: theme.palette.primary.dark,
                        },
                        fontFamily: theme.typography.fontFamily,
                        textTransform: 'capitalize',
                        borderRadius: '50px',
                      }}
                      aria-label="add inventory item"
                    >
                      New
                    </Button>
                  )}

                  {/* View Toggle */}
                  <IconButton onClick={handleViewToggle}>
                    {viewMode === 'grid' ? (
                      <ViewListIcon />
                    ) : (
                      <ViewModuleIcon />
                    )}
                  </IconButton>

                  {/* Filter Button (Mobile Only) */}
                  {isMobile && (
                    <IconButton onClick={toggleDrawer(true)}>
                      <FilterListIcon />
                    </IconButton>
                  )}
                </Box>
              </Box>

              {/* Inventory Items */}
              {loading ? (
                <Box sx={{ textAlign: 'center', margin: theme.spacing(2) }}>
                  <CircularProgress />
                </Box>
              ) : filteredInventory.length === 0 ? (
                <Box sx={{ textAlign: 'center', margin: theme.spacing(2) }}>
                  <Typography
                    variant="h6"
                    sx={{ marginBottom: theme.spacing(2) }}
                  >
                    No inventory items found. Add an item from the shop or press
                    new.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate('/shop')}
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                      '&:hover': {
                        backgroundColor: theme.palette.primary.dark,
                      },
                      fontFamily: theme.typography.fontFamily,
                      textTransform: 'capitalize',
                      borderRadius: '50px',
                    }}
                  >
                    Discover Items
                  </Button>
                </Box>
              ) : viewMode === 'grid' ? (
                <Grid
                  container
                  spacing={2}
                  sx={{
                    paddingBottom: isMobile ? theme.spacing(8) : 0, // Increased bottom padding on mobile
                  }}
                >
                  {currentInventory.map((item) => {
                    const productInfo = item._inventory_items_product_info;
                    const hasProductId = Boolean(
                      item.product_id && productInfo && productInfo.id
                    );

                    return (
                      <Grid
                        item
                        xs={6} // 2 columns on mobile
                        sm={6} // 2 columns on small screens
                        md={2} // 6 columns on desktop (similar to Shop.js)
                        key={item.id}
                      >
                        <InventoryProductCard
                          productInfo={productInfo}
                          item={item}
                          multiplier={multiplier}
                          hasProductId={hasProductId}
                          onClick={() => {
                            setSelectedItem(item);
                            setOpenProductModal(true);
                          }}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              ) : (
                // List View
                <Box
                  sx={{
                    paddingBottom: isMobile ? theme.spacing(8) : 0, // Increased bottom padding on mobile
                  }}
                >
                  {/* Header Row */}
                  <Box
                    sx={{
                      display: 'flex',
                      padding: 1,
                      backgroundColor: theme.palette.background.paper,
                      borderBottom: `1px solid ${theme.palette.divider}`,
                    }}
                  >
                    {/* Conditionally render Image column on desktop only */}
                    {!isMobile && (
                      <Box sx={{ width: '10%', textAlign: 'center' }}>
                        <Typography variant="subtitle2">Image</Typography>
                      </Box>
                    )}
                    <Box sx={{ width: isMobile ? '40%' : '40%' }}>
                      <Typography variant="subtitle2">Product Info</Typography>
                    </Box>
                    <Box
                      sx={{
                        width: isMobile ? '25%' : '15%',
                        textAlign: 'center',
                      }}
                    >
                      <Typography variant="subtitle2">
                        {isMobile ? 'Add' : 'Add to Cart'}
                      </Typography>
                    </Box>
                    <Box sx={{ width: '15%', textAlign: 'center' }}>
                      <Typography variant="subtitle2">Stock</Typography>
                    </Box>
                    <Box sx={{ width: '15%', textAlign: 'center' }}>
                      <Typography variant="subtitle2">PAR</Typography>
                    </Box>
                  </Box>

                  {/* Inventory List Items */}
                  {currentInventory.map((item) => (
                    <InventoryListItem
                      key={item.id}
                      item={item}
                      multiplier={multiplier}
                      isMobile={isMobile}
                      onClick={(selectedItem) => {
                        setSelectedItem(selectedItem);
                        setOpenProductModal(true);
                      }}
                    />
                  ))}
                </Box>
              )}

              {/* Pagination */}
              {totalResults > productsPerPage && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: theme.spacing(4),
                    marginBottom: isMobile ? theme.spacing(8) : 0, // Add bottom margin on mobile
                  }}
                >
                  <Pagination
                    count={Math.ceil(totalResults / productsPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                    showFirstButton
                    showLastButton
                  />
                </Box>
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Drawer for Mobile Filters */}
      <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{
            width: 250,
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            backgroundColor: theme.palette.background.paper,
          }}
          role="presentation"
        >
          {/* Close Button */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton
              onClick={toggleDrawer(false)}
              aria-label="close filters"
            >
              <CloseIcon />
            </IconButton>
          </Box>

          {/* InventoryFilterPanel */}
          <InventoryFilterPanel
            categories={categories}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            subcategories={subcategories}
            selectedSubcategory={selectedSubcategory}
            selectedVendor={selectedVendor}
            setSelectedVendor={setSelectedVendor}
            selectedBrands={selectedBrands}
            setSelectedBrands={setSelectedBrands}
            uniqueBrands={uniqueBrands}
            clearAllFilters={clearAllFilters}
          />
        </Box>
      </Drawer>

      {/* Modal for adding new inventory item */}
      {openModal && (
        <AddInventoryItemModal
          open={openModal}
          handleClose={() => setOpenModal(false)}
          fetchInventory={fetchInventory}
        />
      )}

      {/* Modal for product details */}
      {openProductModal && selectedItem && (
        <ProductDetailsModal
          open={openProductModal}
          handleClose={() => setOpenProductModal(false)}
          inventoryItem={selectedItem}
          fetchInventory={fetchInventory}
          multiplier={multiplier}
        />
      )}
    </Box>
  );
}

export default Inventory;
