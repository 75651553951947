// src/components/Settings.js
import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Container,
  Card,
  Divider,
  Alert,
  Button,
  TextField,
  CircularProgress,
  Autocomplete,
  IconButton,
  Grid,
  Avatar,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  CheckCircle as CheckCircleIcon,
  Add as AddIcon,
  Remove as RemoveIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  LockOpen as AddLoginIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import TopNavBar from './TopNavBar';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

function Settings() {
  const navigate = useNavigate();

  // Retrieve user data from localStorage
  const userData = JSON.parse(localStorage.getItem('user')) || {};
  const { email, restaurant_id: restaurantId, id: userId, name: userName } = userData;

  // State variables for restaurant data
  const [restaurantData, setRestaurantData] = useState(null);
  const [restaurantName, setRestaurantName] = useState('Unknown Restaurant');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // State variables for Resale Tax Certificate
  const [resaleTaxCertNumber, setResaleTaxCertNumber] = useState('');
  const [isSubmittingTaxCert, setIsSubmittingTaxCert] = useState(false);
  const [showResaleTaxCertForm, setShowResaleTaxCertForm] = useState(false);
  const [taxCertError, setTaxCertError] = useState(null);

  // Vendor Request State
  const [vendors, setVendors] = useState([]);
  const [vendorSelections, setVendorSelections] = useState([
    { id: Date.now(), value: null, hasAccount: false },
  ]);
  const [otherVendors, setOtherVendors] = useState('');
  const [isVendorSubmitting, setIsVendorSubmitting] = useState(false);
  const [vendorSubmitSuccess, setVendorSubmitSuccess] = useState(false);
  const [vendorSubmitError, setVendorSubmitError] = useState(null);

  // Existing Connected Vendors State
  const [connectedVendors, setConnectedVendors] = useState([]);
  const [loadingConnectedVendors, setLoadingConnectedVendors] = useState(true);
  const [errorConnectedVendors, setErrorConnectedVendors] = useState(null);

  // State variables for editing login info
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [currentVendorLogin, setCurrentVendorLogin] = useState(null);
  const [editUsername, setEditUsername] = useState('');
  const [editUsernameConfirm, setEditUsernameConfirm] = useState('');
  const [editPassword, setEditPassword] = useState('');
  const [editPasswordConfirm, setEditPasswordConfirm] = useState('');
  const [isUpdatingLoginInfo, setIsUpdatingLoginInfo] = useState(false);
  const [editLoginError, setEditLoginError] = useState(null);

  // State variables for deleting login info
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [vendorLoginToDelete, setVendorLoginToDelete] = useState(null);

  // State variables for deleting restaurant vendor
  const [vendorToDelete, setVendorToDelete] = useState(null);
  const [deleteVendorConfirmOpen, setDeleteVendorConfirmOpen] = useState(false);

  // State variables for adding login info
  const [addLoginModalOpen, setAddLoginModalOpen] = useState(false);
  const [currentVendorToAddLogin, setCurrentVendorToAddLogin] = useState(null);
  const [addLoginUsername, setAddLoginUsername] = useState('');
  const [addLoginUsernameConfirm, setAddLoginUsernameConfirm] = useState('');
  const [addLoginPassword, setAddLoginPassword] = useState('');
  const [addLoginPasswordConfirm, setAddLoginPasswordConfirm] = useState('');
  const [isAddingLoginInfo, setIsAddingLoginInfo] = useState(false);
  const [addLoginError, setAddLoginError] = useState(null);

  const theme = useTheme();

  // Snackbar State
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // Logout Dialog State
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);

  // Fetch the list of vendors
  useEffect(() => {
    const fetchVendors = async () => {
      try {
        const response = await fetch(
          'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/vendor',
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (!response.ok) {
          throw new Error('Failed to fetch vendors.');
        }

        const data = await response.json();
        setVendors(data);
      } catch (error) {
        console.error('Error fetching vendors:', error);
        setError('Failed to load vendors.');
      }
    };

    fetchVendors();
  }, []);

  // Fetch restaurant data using restaurantId
  useEffect(() => {
    const fetchRestaurantData = async () => {
      try {
        if (!restaurantId) throw new Error('Restaurant ID not found');

        const response = await fetch(
          `https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/restaurant/${restaurantId}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (!response.ok) {
          throw new Error('Failed to fetch restaurant data.');
        }

        const data = await response.json();
        setRestaurantData(data);
        setRestaurantName(data.name || 'Unknown Restaurant');
        setResaleTaxCertNumber(data.resale_tax_certificate || '');
      } catch (error) {
        console.error('Error fetching restaurant data:', error);
        setError('Failed to load restaurant data.');
      } finally {
        setLoading(false);
      }
    };

    fetchRestaurantData();
  }, [restaurantId]);

  // Function to refresh connected vendors after adding/updating
  const fetchConnectedVendors = async () => {
    setLoadingConnectedVendors(true);
    try {
      if (!restaurantId) throw new Error('Restaurant ID not found');

      // Fetch restaurant vendors (vendors connected to the restaurant) with expanded vendor data
      const responseVendors = await fetch(
        `https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/restaurant_vendors?restaurant_id=${restaurantId}&_vendor=*`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (!responseVendors.ok) {
        throw new Error('Failed to fetch connected vendors.');
      }

      const restaurantVendors = await responseVendors.json();

      // Fetch vendor logins (login info for vendors)
      const responseLogins = await fetch(
        `https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/restaurant_vendor_log_ins?restaurant_id=${restaurantId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (!responseLogins.ok) {
        throw new Error('Failed to fetch vendor login info.');
      }

      const vendorLogins = await responseLogins.json();

      // Create a map of vendor logins keyed by vendor_id
      const loginMap = new Map();
      vendorLogins.forEach((login) => {
        loginMap.set(login.vendor_id, login);
      });

      // For each restaurant vendor, see if they have login info
      const connectedVendorsCombined = restaurantVendors.map((restaurantVendor) => {
        const loginInfo = loginMap.get(restaurantVendor.vendor_id);
        const vendorData = restaurantVendor._vendor; // store the vendor data separately
        return {
          ...restaurantVendor,
          vendorData, // include vendor data under 'vendorData'
          loginInfo, // this will be undefined if no login info
        };
      });

      setConnectedVendors(connectedVendorsCombined);
    } catch (error) {
      console.error('Error fetching connected vendors:', error);
      setErrorConnectedVendors('Failed to load connected vendors.');
    } finally {
      setLoadingConnectedVendors(false);
    }
  };

  // Fetch connected vendors (both vendors and their logins)
  useEffect(() => {
    if (restaurantId) {
      fetchConnectedVendors();
    }
  }, [restaurantId]);

  // Handle showing the resale tax certificate form
  const handleAddResaleTaxCert = () => {
    setShowResaleTaxCertForm(true);
  };

  // Handle submitting the resale tax certificate number
  const handleSubmitResaleTaxCert = async () => {
    if (!resaleTaxCertNumber.trim()) {
      setTaxCertError('Resale Tax Certificate Number cannot be empty.');
      return;
    }

    setIsSubmittingTaxCert(true);
    setTaxCertError(null);
    try {
      if (!restaurantId) throw new Error('Restaurant ID not found');

      const response = await fetch(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/add_resale_tax_cert',
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            restaurant_id: restaurantId,
            resale_tax_certificate: resaleTaxCertNumber.trim(),
          }),
        }
      );

      if (response.ok) {
        setShowResaleTaxCertForm(false);
        setRestaurantData({
          ...restaurantData,
          resale_tax_certificate: resaleTaxCertNumber.trim(),
          tax_cert_on_file: false,
        });

        // Send Slack Notification
        await sendSlackNotificationForTaxCert(
          restaurantName,
          resaleTaxCertNumber.trim()
        );
      } else {
        const data = await response.json();
        throw new Error(
          data.message || 'Failed to update resale tax certificate number.'
        );
      }
    } catch (error) {
      console.error('Error updating resale tax certificate:', error);
      setTaxCertError(
        error.message || 'Failed to update resale tax certificate number.'
      );
    } finally {
      setIsSubmittingTaxCert(false);
    }
  };

  // Function to send Slack notification for Tax Cert
  const sendSlackNotificationForTaxCert = async (
    restaurantName,
    resaleTaxCertNumber
  ) => {
    try {
      const notificationMessage = `${restaurantName} has updated their resale tax certificate number to ${resaleTaxCertNumber}.`;

      const slackPayload = {
        text: notificationMessage,
      };

      const slackResponse = await fetch(
        'https://xawe-auye-zrgm.n7d.xano.io/api:XF3EVVma/slack_customers',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(slackPayload),
        }
      );

      if (slackResponse.ok) {
        console.log('Slack notification sent successfully.');
      } else {
        console.warn('Failed to send Slack notification.');
      }
    } catch (slackError) {
      console.error('Error sending Slack notification:', slackError);
    }
  };

  // Handle vendor selection changes
  const handleVendorChange = (id, newValue) => {
    setVendorSelections((prevSelections) =>
      prevSelections.map((selection) =>
        selection.id === id
          ? { ...selection, value: newValue, hasAccount: false }
          : selection
      )
    );
  };

  // Handle adding a new vendor dropdown
  const handleAddVendorRow = () => {
    setVendorSelections((prevSelections) => [
      ...prevSelections,
      { id: Date.now(), value: null, hasAccount: false },
    ]);
  };

  // Handle removing a vendor dropdown
  const handleRemoveVendorRow = (id) => {
    setVendorSelections((prevSelections) =>
      prevSelections.filter((selection) => selection.id !== id)
    );
  };

  // Handle changes for "Has Account" checkbox
  const handleHasAccountChange = (id, hasAccount) => {
    setVendorSelections((prevSelections) =>
      prevSelections.map((selection) =>
        selection.id === id ? { ...selection, hasAccount } : selection
      )
    );
  };

  // Handle vendor email change
  const handleVendorEmailChange = (id, vendorEmail) => {
    setVendorSelections((prevSelections) =>
      prevSelections.map((selection) =>
        selection.id === id ? { ...selection, vendorEmail } : selection
      )
    );
  };

  // Handle sales rep change
  const handleSalesRepChange = (id, salesRep) => {
    setVendorSelections((prevSelections) =>
      prevSelections.map((selection) =>
        selection.id === id ? { ...selection, salesRep } : selection
      )
    );
  };

  // Handle MOQ Cases change
  const handleMOQCasesChange = (id, MOQ_Cases) => {
    setVendorSelections((prevSelections) =>
      prevSelections.map((selection) =>
        selection.id === id ? { ...selection, MOQ_Cases } : selection
      )
    );
  };

  // Handle MOQ Dollar change
  const handleMOQDollarChange = (id, MOQ_Dollar) => {
    setVendorSelections((prevSelections) =>
      prevSelections.map((selection) =>
        selection.id === id ? { ...selection, MOQ_Dollar } : selection
      )
    );
  };

  // Handle vendor username change
  const handleVendorUsernameChange = (id, vendorUsername) => {
    setVendorSelections((prevSelections) =>
      prevSelections.map((selection) =>
        selection.id === id ? { ...selection, vendorUsername } : selection
      )
    );
  };

  // Handle vendor username confirm change
  const handleVendorUsernameConfirmChange = (id, vendorUsernameConfirm) => {
    setVendorSelections((prevSelections) =>
      prevSelections.map((selection) =>
        selection.id === id ? { ...selection, vendorUsernameConfirm } : selection
      )
    );
  };

  // Handle vendor password change
  const handleVendorPasswordChange = (id, vendorPassword) => {
    setVendorSelections((prevSelections) =>
      prevSelections.map((selection) =>
        selection.id === id ? { ...selection, vendorPassword } : selection
      )
    );
  };

  // Handle vendor password confirm change
  const handleVendorPasswordConfirmChange = (id, vendorPasswordConfirm) => {
    setVendorSelections((prevSelections) =>
      prevSelections.map((selection) =>
        selection.id === id ? { ...selection, vendorPasswordConfirm } : selection
      )
    );
  };

  // Handle submitting the vendor request form
  const handleSubmitVendorRequest = async () => {
    // Extract selected vendors
    const selectedVendors = vendorSelections.filter(
      (selection) => selection.value
    );

    if (selectedVendors.length === 0 && !otherVendors.trim()) {
      setVendorSubmitError(
        'Please select at least one vendor or enter other vendors.'
      );
      return;
    }

    setIsVendorSubmitting(true);
    setVendorSubmitError(null);

    try {
      if (!restaurantId) throw new Error('Restaurant ID not found');

      if (!userId) {
        throw new Error('User ID not found.');
      }

      // Handle vendors with accounts
      for (const selection of selectedVendors) {
        if (selection.hasAccount) {
          // Validate required fields
          if (
            !selection.vendorEmail?.trim() ||
            !selection.vendorUsername?.trim() ||
            !selection.vendorPassword ||
            !selection.vendorPasswordConfirm ||
            !selection.vendorUsernameConfirm
          ) {
            throw new Error(
              `Please fill in all required fields for ${selection.value.Long_Name}.`
            );
          }
          if (selection.vendorUsername !== selection.vendorUsernameConfirm) {
            throw new Error(
              `Usernames do not match for ${selection.value.Long_Name}.`
            );
          }
          if (selection.vendorPassword !== selection.vendorPasswordConfirm) {
            throw new Error(
              `Passwords do not match for ${selection.value.Long_Name}.`
            );
          }

          // Use the new API to add the restaurant vendor
          const vendorPayload = {
            restaurant_id: restaurantId,
            vendor_id: selection.value.id,
            vendor_email: selection.vendorEmail.trim(),
            sales_rep: selection.salesRep?.trim() || '',
            MOQ_Cases: selection.MOQ_Cases
              ? parseInt(selection.MOQ_Cases, 10)
              : null,
            MOQ_Dollar: selection.MOQ_Dollar
              ? parseFloat(selection.MOQ_Dollar)
              : null,
          };

          const vendorResponse = await fetch(
            'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/add_restaurant_vendor',
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(vendorPayload),
            }
          );

          if (!vendorResponse.ok) {
            const data = await vendorResponse.json();
            throw new Error(
              data.message ||
                `Failed to add vendor: ${selection.value.Long_Name}`
            );
          }

          // After adding the vendor, add the login credentials
          const loginPayload = {
            restaurant_id: restaurantId,
            vendor_id: selection.value.id,
            username: selection.vendorUsername.trim(),
            password: selection.vendorPassword, // Ensure secure handling
          };

          const loginResponse = await fetch(
            'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/restaurant_vendor_log_ins',
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(loginPayload),
            }
          );

          if (!loginResponse.ok) {
            const data = await loginResponse.json();
            throw new Error(
              data.message ||
                `Failed to add vendor login: ${selection.value.Long_Name}`
            );
          }
        } else {
          // Trigger help ticket logic for vendors without accounts
          const subject = 'Supplier Account Setup Request';
          const description = `Requested Vendor: ${selection.value.Long_Name}`;

          const helpTicketData = {
            subject,
            description,
            user_id: userId,
            ticket_type_id: 2,
            ticket_status_id: 0,
            admin_notes: '',
            last_updated: null,
          };

          const helpTicketResponse = await fetch(
            'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/help_tickets',
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(helpTicketData),
            }
          );

          if (!helpTicketResponse.ok) {
            const data = await helpTicketResponse.json();
            throw new Error(
              data.message ||
                `Failed to submit vendor request for ${selection.value.Long_Name}.`
            );
          }
        }
      }

      // Handle other vendors
      if (otherVendors.trim()) {
        const subject = 'Supplier Account Setup Request';
        const description = `Other Vendors: ${otherVendors.trim()}`;

        const helpTicketData = {
          subject,
          description,
          user_id: userId,
          ticket_type_id: 2,
          ticket_status_id: 0,
          admin_notes: '',
          last_updated: null,
        };

        const helpTicketResponse = await fetch(
          'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/help_tickets',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(helpTicketData),
          }
        );

        if (!helpTicketResponse.ok) {
          const data = await helpTicketResponse.json();
          throw new Error(
            data.message || 'Failed to submit vendor request for other vendors.'
          );
        }
      }

      // Send Slack notification
      await sendSlackNotificationForVendors(
        restaurantName,
        selectedVendors,
        otherVendors
      );

      // Show success message
      setVendorSubmitSuccess(true);
      // Clear the form
      setVendorSelections([{ id: Date.now(), value: null, hasAccount: false }]);
      setOtherVendors('');

      // Refresh the list of connected vendors
      fetchConnectedVendors();
    } catch (error) {
      console.error('Error submitting vendor request:', error);
      setVendorSubmitError(
        error.message || 'Failed to submit vendor request.'
      );
    } finally {
      setIsVendorSubmitting(false);
    }
  };

  // Function to send Slack notification for Vendor Request
  const sendSlackNotificationForVendors = async (
    restaurantName,
    selectedVendors,
    otherVendors
  ) => {
    try {
      let description = selectedVendors
        .map((selection) => {
          const vendorName = selection.value.Long_Name || 'Unknown Vendor';
          return `${vendorName} (Has Account: ${
            selection.hasAccount ? 'Yes' : 'No'
          })`;
        })
        .join(', ');

      if (otherVendors.trim()) {
        description += `. Other Vendors: ${otherVendors.trim()}`;
      }

      const notificationMessage = `${restaurantName} has submitted a supplier request.\nRequested Vendors: ${description}`;

      const slackPayload = {
        text: notificationMessage,
      };

      const slackResponse = await fetch(
        'https://xawe-auye-zrgm.n7d.xano.io/api:XF3EVVma/slack_customers',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(slackPayload),
        }
      );

      if (slackResponse.ok) {
        console.log('Slack notification sent successfully.');
      } else {
        console.warn('Failed to send Slack notification.');
      }
    } catch (slackError) {
      console.error('Error sending Slack notification:', slackError);
    }
  };

  // Handle closing the Snackbar
  const handleCloseSnackbar = () => {
    setVendorSubmitSuccess(false);
  };

  // Handle Logout (Confirm)
  const handleLogout = () => {
    // Clear user data from localStorage
    localStorage.removeItem('user');

    // Clear persisted states used by PurchaseHistory (or other pages)
    localStorage.removeItem('inputSearchTerm');
    localStorage.removeItem('searchTerm');
    localStorage.removeItem('selectedCategory');
    localStorage.removeItem('selectedSubcategory');
    localStorage.removeItem('selectedVendors');
    localStorage.removeItem('selectedBrands');
    localStorage.removeItem('currentPage');
    localStorage.removeItem('viewMode');
    localStorage.removeItem('userRestaurantId');
    localStorage.removeItem('userId');

    // Navigate to login page
    navigate('/login');
  };

  // Open Logout Confirmation Dialog
  const openLogoutDialog = () => {
    setLogoutDialogOpen(true);
  };

  // Close Logout Confirmation Dialog
  const closeLogoutDialog = () => {
    setLogoutDialogOpen(false);
  };

  // Handle opening the edit modal
  const handleOpenEditModal = (vendor) => {
    setCurrentVendorLogin(vendor);
    setEditUsername(vendor.loginInfo.username || '');
    setEditUsernameConfirm('');
    setEditPassword('');
    setEditPasswordConfirm('');
    setEditLoginError(null);
    setEditModalOpen(true);
  };

  // Handle closing the edit modal
  const handleCloseEditModal = () => {
    setEditModalOpen(false);
    setCurrentVendorLogin(null);
    setEditUsername('');
    setEditUsernameConfirm('');
    setEditPassword('');
    setEditPasswordConfirm('');
    setEditLoginError(null);
  };

  // Handle updating login info
  const handleUpdateLoginInfo = async () => {
    // Validate inputs
    if (
      !editUsername.trim() ||
      !editUsernameConfirm.trim() ||
      !editPassword ||
      !editPasswordConfirm
    ) {
      setEditLoginError('Please fill in all required fields.');
      return;
    }
    if (editUsername !== editUsernameConfirm) {
      setEditLoginError('Usernames do not match.');
      return;
    }
    if (editPassword !== editPasswordConfirm) {
      setEditLoginError('Passwords do not match.');
      return;
    }

    setIsUpdatingLoginInfo(true);
    setEditLoginError(null);

    try {
      const payload = {
        restaurant_vendor_log_ins_id: currentVendorLogin.loginInfo.id,
        username: editUsername.trim(),
        password: editPassword,
      };

      const response = await fetch(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/edit_login_info',
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.message || 'Failed to update login info.');
      }

      // Success, refresh the connected vendors
      fetchConnectedVendors();
      handleCloseEditModal();
    } catch (error) {
      console.error('Error updating login info:', error);
      setEditLoginError(error.message || 'Failed to update login info.');
    } finally {
      setIsUpdatingLoginInfo(false);
    }
  };

  // Handle opening the delete login confirmation dialog
  const handleOpenDeleteConfirm = (vendor) => {
    setVendorLoginToDelete(vendor);
    setDeleteConfirmOpen(true);
  };

  // Handle closing the delete login confirmation dialog
  const handleCloseDeleteConfirm = () => {
    setVendorLoginToDelete(null);
    setDeleteConfirmOpen(false);
  };

  // Handle deleting login info
  const handleDeleteLoginInfo = async () => {
    setIsUpdatingLoginInfo(true);
    try {
      const { loginInfo } = vendorLoginToDelete;
      const response = await fetch(
        `https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/restaurant_vendor_log_ins/${loginInfo.id}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.message || 'Failed to delete login info.');
      }

      // Success, refresh the connected vendors
      fetchConnectedVendors();
      handleCloseDeleteConfirm();
    } catch (error) {
      console.error('Error deleting login info:', error);
    } finally {
      setIsUpdatingLoginInfo(false);
    }
  };

  // Handle opening the delete vendor confirmation dialog
  const handleOpenDeleteVendorConfirm = (vendor) => {
    setVendorToDelete(vendor);
    setDeleteVendorConfirmOpen(true);
  };

  // Handle closing the delete vendor confirmation dialog
  const handleCloseDeleteVendorConfirm = () => {
    setVendorToDelete(null);
    setDeleteVendorConfirmOpen(false);
  };

  // Handle deleting restaurant vendor
  const handleDeleteVendor = async () => {
    setIsUpdatingLoginInfo(true);
    try {
      const response = await fetch(
        `https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/restaurant_vendors/${vendorToDelete.id}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.message || 'Failed to delete vendor.');
      }

      // Success, refresh the connected vendors
      fetchConnectedVendors();
      handleCloseDeleteVendorConfirm();
    } catch (error) {
      console.error('Error deleting vendor:', error);
    } finally {
      setIsUpdatingLoginInfo(false);
    }
  };

  // Handle opening the add login modal
  const handleOpenAddLoginModal = (vendor) => {
    setCurrentVendorToAddLogin(vendor);
    setAddLoginUsername('');
    setAddLoginUsernameConfirm('');
    setAddLoginPassword('');
    setAddLoginPasswordConfirm('');
    setAddLoginError(null);
    setAddLoginModalOpen(true);
  };

  // Handle closing the add login modal
  const handleCloseAddLoginModal = () => {
    setAddLoginModalOpen(false);
    setCurrentVendorToAddLogin(null);
    setAddLoginUsername('');
    setAddLoginUsernameConfirm('');
    setAddLoginPassword('');
    setAddLoginPasswordConfirm('');
    setAddLoginError(null);
  };

  // Handle adding login info
  const handleAddLoginInfo = async () => {
    // Validate inputs
    if (
      !addLoginUsername.trim() ||
      !addLoginUsernameConfirm.trim() ||
      !addLoginPassword ||
      !addLoginPasswordConfirm
    ) {
      setAddLoginError('Please fill in all required fields.');
      return;
    }
    if (addLoginUsername !== addLoginUsernameConfirm) {
      setAddLoginError('Usernames do not match.');
      return;
    }
    if (addLoginPassword !== addLoginPasswordConfirm) {
      setAddLoginError('Passwords do not match.');
      return;
    }

    setIsAddingLoginInfo(true);
    setAddLoginError(null);

    try {
      const payload = {
        restaurant_id: restaurantId,
        vendor_id: currentVendorToAddLogin.vendor_id,
        username: addLoginUsername.trim(),
        password: addLoginPassword,
      };

      const response = await fetch(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/restaurant_vendor_log_ins',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.message || 'Failed to add login info.');
      }

      // Success, refresh the connected vendors
      fetchConnectedVendors();
      handleCloseAddLoginModal();
    } catch (error) {
      console.error('Error adding login info:', error);
      setAddLoginError(error.message || 'Failed to add login info.');
    } finally {
      setIsAddingLoginInfo(false);
    }
  };

  if (loading) {
    return (
      <>
        <TopNavBar sticky />
        <Container
          maxWidth="sm"
          sx={{
            textAlign: 'center',
            mt: 4,
            paddingBottom: theme.spacing(10),
          }}
        >
          <CircularProgress />
        </Container>
      </>
    );
  }

  if (error) {
    return (
      <>
        <TopNavBar sticky />
        <Container
          maxWidth="sm"
          sx={{
            textAlign: 'center',
            mt: 4,
            paddingBottom: theme.spacing(10),
          }}
        >
          <Typography color="error">Error: {error}</Typography>
        </Container>
      </>
    );
  }

  const { tax_cert_on_file, location } = restaurantData;

  // Get connected vendor IDs to exclude them from the vendor selection dropdown
  const connectedVendorIds = connectedVendors.map((vendor) => vendor.vendor_id);

  return (
    <>
      <Helmet>
        <title>FareFood | Settings</title>
      </Helmet>

      <TopNavBar sticky />

      <Container
        maxWidth="lg"
        sx={{
          paddingTop: theme.spacing(8),
          backgroundColor: theme.palette.common.white,
          minHeight: '100vh',
          paddingBottom: theme.spacing(4),
        }}
      >
        <Typography
          variant="h5"
          component="h2"
          sx={{
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(2),
          }}
        >
          Settings
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Card
              sx={{ padding: theme.spacing(3), marginBottom: theme.spacing(4) }}
            >
              <Typography variant="h6" sx={{ marginBottom: theme.spacing(2) }}>
                User Information
              </Typography>
              <Divider sx={{ marginBottom: theme.spacing(2) }} />
              <Box sx={{ marginBottom: theme.spacing(3) }}>
                <Typography
                  variant="body1"
                  sx={{ marginBottom: theme.spacing(1) }}
                >
                  <strong>Email:</strong> {email || 'Not Provided'}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ marginBottom: theme.spacing(1) }}
                >
                  <strong>Restaurant Name:</strong> {restaurantName}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ marginBottom: theme.spacing(1) }}
                >
                  <strong>Location:</strong> {location || 'Not Provided'}
                </Typography>
              </Box>
            </Card>

            <Card
              sx={{ padding: theme.spacing(3), marginBottom: theme.spacing(4) }}
            >
              <Typography variant="h6" sx={{ marginBottom: theme.spacing(2) }}>
                Resale Tax Certificate
              </Typography>
              <Divider sx={{ marginBottom: theme.spacing(2) }} />
              <Box sx={{ marginBottom: theme.spacing(2) }}>
                {!resaleTaxCertNumber ? (
                  <>
                    <Alert
                      severity="warning"
                      sx={{ marginBottom: theme.spacing(2) }}
                    >
                      <Typography variant="body1">
                        <strong>You're Almost There!</strong> To unlock
                        exclusive benefits, please add your restaurant's resale
                        tax certificate number.
                      </Typography>
                    </Alert>
                    <Button
                      variant="contained"
                      onClick={handleAddResaleTaxCert}
                      sx={{ mt: 2 }}
                    >
                      Add Resale Tax Certificate Number
                    </Button>
                  </>
                ) : (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: theme.spacing(2),
                      }}
                    >
                      <Typography variant="body1">
                        <strong>Resale Tax Certificate Number:</strong>{' '}
                        {resaleTaxCertNumber}
                      </Typography>
                      <Button
                        variant="outlined"
                        onClick={handleAddResaleTaxCert}
                        sx={{ ml: 2 }}
                      >
                        Update
                      </Button>
                    </Box>

                    {!tax_cert_on_file ? (
                      <Alert severity="info">
                        <Typography variant="body1">
                          We have received your resale tax certificate number and
                          are actively verifying it.
                        </Typography>
                      </Alert>
                    ) : (
                      <Alert
                        severity="success"
                        icon={<CheckCircleIcon fontSize="inherit" />}
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        <Typography variant="body1">
                          <strong>Resale tax certificate verified!</strong>
                        </Typography>
                      </Alert>
                    )}
                  </>
                )}
              </Box>

              {showResaleTaxCertForm && (
                <>
                  <Divider sx={{ marginBottom: theme.spacing(3) }} />
                  <Box component="form" sx={{ mt: 2 }}>
                    <TextField
                      label="Resale Tax Certificate Number"
                      variant="outlined"
                      fullWidth
                      value={resaleTaxCertNumber}
                      onChange={(e) => setResaleTaxCertNumber(e.target.value)}
                      sx={{ marginBottom: theme.spacing(2) }}
                      error={Boolean(taxCertError)}
                      helperText={taxCertError}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmitResaleTaxCert}
                        disabled={isSubmittingTaxCert}
                      >
                        {isSubmittingTaxCert ? 'Submitting...' : 'Submit'}
                      </Button>
                    </Box>
                  </Box>
                </>
              )}
            </Card>

            <Card
              sx={{ padding: theme.spacing(3), marginBottom: theme.spacing(4) }}
            >
              <Typography variant="h6" sx={{ marginBottom: theme.spacing(2) }}>
                Add or Connect Suppliers
              </Typography>
              <Divider sx={{ marginBottom: theme.spacing(2) }} />
              <Typography
                variant="body1"
                sx={{ marginBottom: theme.spacing(2) }}
              >
                Select suppliers you'd like to work with. If you already have an
                account, you can provide your details to connect. If not, we can
                help set one up for you.
              </Typography>

              <Box sx={{ marginBottom: theme.spacing(4) }}>
                <Typography variant="h6" sx={{ marginBottom: theme.spacing(2) }}>
                  Existing Connected Vendors
                </Typography>
                <Divider sx={{ marginBottom: theme.spacing(2) }} />
                {loadingConnectedVendors ? (
                  <Box sx={{ textAlign: 'center', margin: theme.spacing(2) }}>
                    <CircularProgress />
                  </Box>
                ) : errorConnectedVendors ? (
                  <Alert severity="error">{errorConnectedVendors}</Alert>
                ) : connectedVendors.length === 0 ? (
                  <Typography variant="body1">
                    You have not connected any vendor accounts yet.
                  </Typography>
                ) : (
                  <Grid container spacing={2}>
                    {connectedVendors.map((vendor) => (
                      <Grid item xs={12} sm={6} md={4} key={vendor.id}>
                        <Card
                          sx={{
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'column',
                            padding: theme.spacing(2),
                            backgroundColor: theme.palette.background.paper,
                          }}
                        >
                          <IconButton
                            sx={{
                              position: 'absolute',
                              top: theme.spacing(1),
                              right: theme.spacing(1),
                              color: theme.palette.grey[500],
                            }}
                            onClick={() => handleOpenDeleteVendorConfirm(vendor)}
                            title="Delete Vendor"
                          >
                            <CloseIcon />
                          </IconButton>

                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            {vendor.vendorData?.Logo_URL ? (
                              <Avatar
                                src={vendor.vendorData.Logo_URL}
                                alt={vendor.vendorData.Short_Name}
                                sx={{
                                  width: 56,
                                  height: 56,
                                  marginRight: theme.spacing(2),
                                }}
                              />
                            ) : (
                              <Avatar
                                sx={{
                                  width: 56,
                                  height: 56,
                                  marginRight: theme.spacing(2),
                                  backgroundColor: theme.palette.grey[300],
                                }}
                              >
                                {vendor.vendorData?.Short_Name
                                  ? vendor.vendorData.Short_Name.charAt(0)
                                  : 'V'}
                              </Avatar>
                            )}
                            <Box sx={{ flexGrow: 1 }}>
                              <Typography variant="body1">
                                {vendor.vendorData?.Long_Name ||
                                  vendor.vendorData?.Short_Name ||
                                  'Unknown Vendor'}
                              </Typography>
                              {vendor.loginInfo ? (
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  Username: {vendor.loginInfo.username}
                                </Typography>
                              ) : (
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  No login info provided
                                </Typography>
                              )}
                            </Box>
                          </Box>
                          <Box sx={{ mt: 2 }}>
                            <Typography variant="body2">
                              <strong>Sales Rep Email:</strong>{' '}
                              {vendor.vendor_email || 'N/A'}
                            </Typography>
                            <Typography variant="body2">
                              <strong>Sales Rep Name:</strong>{' '}
                              {vendor.sales_rep || 'N/A'}
                            </Typography>
                            <Typography variant="body2">
                              <strong>MOQ Cases:</strong> {vendor.MOQ_Cases || 'N/A'}
                            </Typography>
                            <Typography variant="body2">
                              <strong>MOQ Dollar:</strong> {vendor.MOQ_Dollar || 'N/A'}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              mt: 'auto',
                              display: 'flex',
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                            }}
                          >
                            {vendor.loginInfo ? (
                              <>
                                <IconButton
                                  color="primary"
                                  onClick={() => handleOpenEditModal(vendor)}
                                >
                                  <EditIcon />
                                </IconButton>
                                <IconButton
                                  color="secondary"
                                  onClick={() => handleOpenDeleteConfirm(vendor)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </>
                            ) : (
                              <IconButton
                                color="primary"
                                onClick={() => handleOpenAddLoginModal(vendor)}
                                title="Add Login Info"
                              >
                                <AddLoginIcon />
                              </IconButton>
                            )}
                          </Box>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </Box>

              <Box component="form" sx={{ mt: 2 }}>
                <Grid container spacing={2}>
                  {vendorSelections.map((selection, index) => (
                    <Grid item xs={12} key={selection.id}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Autocomplete
                          options={vendors.filter(
                            (vendor) => !connectedVendorIds.includes(vendor.id)
                          )}
                          getOptionLabel={(option) =>
                            option.Long_Name || 'Unknown Vendor'
                          }
                          value={selection.value}
                          onChange={(event, newValue) =>
                            handleVendorChange(selection.id, newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={`Vendor ${index + 1}`}
                              variant="outlined"
                              fullWidth
                            />
                          )}
                          sx={{ flexGrow: 1 }}
                        />
                        <IconButton
                          color="primary"
                          onClick={() => handleAddVendorRow()}
                          sx={{ ml: 1 }}
                        >
                          <AddIcon />
                        </IconButton>
                        {vendorSelections.length > 1 && (
                          <IconButton
                            color="secondary"
                            onClick={() => handleRemoveVendorRow(selection.id)}
                            sx={{ ml: 1 }}
                          >
                            <RemoveIcon />
                          </IconButton>
                        )}
                      </Box>

                      {selection.value && (
                        <Box sx={{ mt: 2 }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selection.hasAccount || false}
                                onChange={(e) =>
                                  handleHasAccountChange(
                                    selection.id,
                                    e.target.checked
                                  )
                                }
                              />
                            }
                            label="I already have an account with this vendor"
                          />

                          {selection.hasAccount && (
                            <Box sx={{ mt: 2 }}>
                              <TextField
                                label="Sales Rep Email*"
                                variant="outlined"
                                fullWidth
                                value={selection.vendorEmail || ''}
                                onChange={(e) =>
                                  handleVendorEmailChange(
                                    selection.id,
                                    e.target.value
                                  )
                                }
                                sx={{ marginBottom: theme.spacing(2) }}
                              />
                              <TextField
                                label="Sales Rep Name*"
                                variant="outlined"
                                fullWidth
                                value={selection.salesRep || ''}
                                onChange={(e) =>
                                  handleSalesRepChange(
                                    selection.id,
                                    e.target.value
                                  )
                                }
                                sx={{ marginBottom: theme.spacing(2) }}
                              />
                              <TextField
                                label="Account Username*"
                                variant="outlined"
                                fullWidth
                                value={selection.vendorUsername || ''}
                                onChange={(e) =>
                                  handleVendorUsernameChange(
                                    selection.id,
                                    e.target.value
                                  )
                                }
                                sx={{ marginBottom: theme.spacing(2) }}
                              />
                              <TextField
                                label="Confirm Username*"
                                variant="outlined"
                                fullWidth
                                value={selection.vendorUsernameConfirm || ''}
                                onChange={(e) =>
                                  handleVendorUsernameConfirmChange(
                                    selection.id,
                                    e.target.value
                                  )
                                }
                                sx={{ marginBottom: theme.spacing(2) }}
                              />
                              <TextField
                                label="Account Password*"
                                variant="outlined"
                                type="password"
                                fullWidth
                                value={selection.vendorPassword || ''}
                                onChange={(e) =>
                                  handleVendorPasswordChange(
                                    selection.id,
                                    e.target.value
                                  )
                                }
                                sx={{ marginBottom: theme.spacing(2) }}
                              />
                              <TextField
                                label="Confirm Password*"
                                variant="outlined"
                                type="password"
                                fullWidth
                                value={selection.vendorPasswordConfirm || ''}
                                onChange={(e) =>
                                  handleVendorPasswordConfirmChange(
                                    selection.id,
                                    e.target.value
                                  )
                                }
                                sx={{ marginBottom: theme.spacing(2) }}
                              />
                              <TextField
                                label="Minimum Order Quantity (Cases)"
                                variant="outlined"
                                fullWidth
                                value={selection.MOQ_Cases || ''}
                                onChange={(e) =>
                                  handleMOQCasesChange(
                                    selection.id,
                                    e.target.value
                                  )
                                }
                                sx={{ marginBottom: theme.spacing(2) }}
                              />
                              <TextField
                                label="Minimum Order Quantity (Dollar)"
                                variant="outlined"
                                fullWidth
                                value={selection.MOQ_Dollar || ''}
                                onChange={(e) =>
                                  handleMOQDollarChange(
                                    selection.id,
                                    e.target.value
                                  )
                                }
                                sx={{ marginBottom: theme.spacing(2) }}
                              />
                            </Box>
                          )}
                        </Box>
                      )}
                    </Grid>
                  ))}
                </Grid>

                <TextField
                  label="Other Vendors"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={2}
                  value={otherVendors}
                  onChange={(e) => setOtherVendors(e.target.value)}
                  sx={{ marginTop: theme.spacing(2) }}
                  placeholder="If you don't see a vendor you use, please let us know here."
                />

                {vendorSubmitError && (
                  <Alert severity="error" sx={{ marginTop: theme.spacing(2) }}>
                    {vendorSubmitError}
                  </Alert>
                )}

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: theme.spacing(2),
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmitVendorRequest}
                    disabled={isVendorSubmitting}
                  >
                    {isVendorSubmitting ? 'Submitting...' : 'Submit'}
                  </Button>
                </Box>
              </Box>
            </Card>

            <Card
              sx={{ padding: theme.spacing(3), marginBottom: theme.spacing(4) }}
            >
              <Typography variant="h6" sx={{ marginBottom: theme.spacing(2) }}>
                Logout
              </Typography>
              <Divider sx={{ marginBottom: theme.spacing(2) }} />
              <Typography
                variant="body1"
                sx={{ marginBottom: theme.spacing(2) }}
              >
                Click the button below to logout of your account.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={openLogoutDialog}
              >
                Logout
              </Button>
            </Card>
          </Grid>
        </Grid>
      </Container>

      <Snackbar
        open={vendorSubmitSuccess}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message="Your request has been submitted successfully."
      />

      <Dialog
        open={logoutDialogOpen}
        onClose={closeLogoutDialog}
        aria-labelledby="logout-dialog-title"
        aria-describedby="logout-dialog-description"
      >
        <DialogTitle id="logout-dialog-title">Confirm Logout</DialogTitle>
        <DialogContent>
          <DialogContentText id="logout-dialog-description">
            Are you sure you want to log out?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeLogoutDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleLogout} color="primary" autoFocus>
            Logout
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={editModalOpen}
        onClose={handleCloseEditModal}
        aria-labelledby="edit-login-dialog-title"
      >
        <DialogTitle id="edit-login-dialog-title">Edit Login Info</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the new username and password.
          </DialogContentText>
          {editLoginError && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {editLoginError}
            </Alert>
          )}
          <TextField
            label="Username"
            variant="outlined"
            fullWidth
            value={editUsername}
            onChange={(e) => setEditUsername(e.target.value)}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Confirm Username"
            variant="outlined"
            fullWidth
            value={editUsernameConfirm}
            onChange={(e) => setEditUsernameConfirm(e.target.value)}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Password"
            variant="outlined"
            type="password"
            fullWidth
            value={editPassword}
            onChange={(e) => setEditPassword(e.target.value)}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Confirm Password"
            variant="outlined"
            type="password"
            fullWidth
            value={editPasswordConfirm}
            onChange={(e) => setEditPasswordConfirm(e.target.value)}
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditModal} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleUpdateLoginInfo}
            color="primary"
            disabled={isUpdatingLoginInfo}
          >
            {isUpdatingLoginInfo ? 'Updating...' : 'Update'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteConfirmOpen}
        onClose={handleCloseDeleteConfirm}
        aria-labelledby="delete-login-dialog-title"
      >
        <DialogTitle id="delete-login-dialog-title">Delete Login Info</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the login information for{' '}
            {vendorLoginToDelete?.vendorData?.Long_Name || 'this vendor'}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteConfirm} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleDeleteLoginInfo}
            color="secondary"
            disabled={isUpdatingLoginInfo}
          >
            {isUpdatingLoginInfo ? 'Deleting...' : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteVendorConfirmOpen}
        onClose={handleCloseDeleteVendorConfirm}
        aria-labelledby="delete-vendor-dialog-title"
      >
        <DialogTitle id="delete-vendor-dialog-title">Delete Vendor</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove{' '}
            {vendorToDelete?.vendorData?.Long_Name || 'this vendor'} from your
            connected vendors?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteVendorConfirm} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleDeleteVendor}
            color="secondary"
            disabled={isUpdatingLoginInfo}
          >
            {isUpdatingLoginInfo ? 'Deleting...' : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={addLoginModalOpen}
        onClose={handleCloseAddLoginModal}
        aria-labelledby="add-login-dialog-title"
      >
        <DialogTitle id="add-login-dialog-title">Add Login Info</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter your username and password for{' '}
            {currentVendorToAddLogin?.vendorData?.Long_Name || 'this vendor'}.
          </DialogContentText>
          {addLoginError && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {addLoginError}
            </Alert>
          )}
          <TextField
            label="Username"
            variant="outlined"
            fullWidth
            value={addLoginUsername}
            onChange={(e) => setAddLoginUsername(e.target.value)}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Confirm Username"
            variant="outlined"
            fullWidth
            value={addLoginUsernameConfirm}
            onChange={(e) => setAddLoginUsernameConfirm(e.target.value)}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Password"
            variant="outlined"
            type="password"
            fullWidth
            value={addLoginPassword}
            onChange={(e) => setAddLoginPassword(e.target.value)}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Confirm Password"
            variant="outlined"
            type="password"
            fullWidth
            value={addLoginPasswordConfirm}
            onChange={(e) => setAddLoginPasswordConfirm(e.target.value)}
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddLoginModal} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleAddLoginInfo}
            color="primary"
            disabled={isAddingLoginInfo}
          >
            {isAddingLoginInfo ? 'Adding...' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Settings;
