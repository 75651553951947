// src/Shop.js

import React, { useState, useEffect } from 'react';
import TopNavBar from './TopNavBar';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Button,
  Grid,
  Typography,
  CircularProgress,
  Box,
  IconButton,
  Drawer,
  Container,
  Pagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import FilterListIcon from '@mui/icons-material/FilterList';
import axios from 'axios';
import FilterPanel from './FilterPanel';
import ProductCard from './ProductCard';
import { Helmet } from 'react-helmet';
import ProductListItem from './ProductListItem';
import qs from 'qs'; // Import qs for query string serialization

function Shop() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  // Extract query parameters or set defaults
  const initialSearchTerm = searchParams.get('query') || '';
  const initialCategory = parseInt(searchParams.get('category'), 10) || 0;
  const initialSubcategory = parseInt(searchParams.get('subcategory'), 10) || 0;
  const initialVendor = searchParams.get('vendor')
    ? searchParams.get('vendor').split(',').map(Number)
    : [];
  const initialSort = searchParams.get('sort') || 'default';

  // Product States
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [loadingProducts, setLoadingProducts] = useState(false);

  // Filter States
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(initialCategory);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState(
    initialSubcategory
  );
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(initialVendor);
  const [sortOption, setSortOption] = useState(initialSort);
  const [viewMode, setViewMode] = useState('grid'); // 'grid' or 'list'

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // State for Drawer
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // State for Multiplier
  const [multiplier, setMultiplier] = useState(1);

  // State for Products
  const [allProducts, setAllProducts] = useState([]);
  const [sortedBaseProducts, setSortedBaseProducts] = useState([]); // New state for sorted base products
  const [displayProducts, setDisplayProducts] = useState([]);

  // State for total results and pages
  const [totalResults, setTotalResults] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  // Pagination States
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 24;

  // State to keep track of expanded products
  const [expandedProducts, setExpandedProducts] = useState({});

  // State for Restaurant ID
  const [restaurantId, setRestaurantId] = useState(null);

  // Fetch the multiplier and restaurant ID from localStorage when the component mounts
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      try {
        const parsedUser = JSON.parse(storedUser);
        const storedMultiplier = parseFloat(parsedUser.multiplier) || 1;
        setMultiplier(storedMultiplier);
        if (parsedUser.restaurant_id) {
          setRestaurantId(parsedUser.restaurant_id);
        }
      } catch (error) {
        console.error('Error parsing user data from localStorage:', error);
        setMultiplier(1);
      }
    } else {
      setMultiplier(1);
    }
  }, []);

  // Function to calculate Price Per Unit
  const calculatePricePerUnit = (product, multiplier) => {
    const { purchase_price, _product } = product || {};
    const { product_volume, priced_by } = _product || {};
    const adjusted_purchase_price = purchase_price * multiplier;

    if (
      adjusted_purchase_price &&
      product_volume > 0 &&
      priced_by &&
      !isNaN(adjusted_purchase_price)
    ) {
      const pricePerUnitValue = adjusted_purchase_price / product_volume;
      return {
        pricePerUnit: `$${pricePerUnitValue.toFixed(2)}/${priced_by}`,
        unitPriceValue: pricePerUnitValue,
      };
    } else {
      return null;
    }
  };

  // Function to determine the best deal among a group of products
  const determineBestDealInGroup = (products, multiplier) => {
    let bestDealProductId = null;
    let lowestUnitPrice = Infinity;

    products.forEach((product) => {
      if (
        product &&
        product.purchase_price &&
        product._product &&
        product._product.product_volume &&
        product._product.priced_by
      ) {
        const priceData = calculatePricePerUnit(product, multiplier);

        if (priceData && priceData.unitPriceValue < lowestUnitPrice) {
          lowestUnitPrice = priceData.unitPriceValue;
          bestDealProductId = product._product.id;
        }
      }
    });

    return bestDealProductId;
  };

  // Fetch Categories
  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/category',
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
      setCategories([]);
    }
  };

  // Fetch Subcategories based on selectedCategory
  const fetchSubcategories = async (categoryId) => {
    if (categoryId === 0) {
      setSubcategories([]);
      setSelectedSubcategory(0);
      return;
    }
    try {
      const response = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/subcategory',
        {
          headers: { 'Content-Type': 'application/json' },
          params: { filter: categoryId },
        }
      );
      setSubcategories(response.data);
    } catch (error) {
      console.error('Error fetching subcategories:', error);
      setSubcategories([]);
    }
  };

  // Fetch Vendors
  const fetchVendors = async () => {
    try {
      const userJson = localStorage.getItem('user');
      if (!userJson) {
        throw new Error('User data not found in local storage.');
      }

      const user = JSON.parse(userJson);
      const restaurantId = user.restaurant_id;
      if (!restaurantId) {
        throw new Error('Restaurant ID not found in user data.');
      }

      const response = await axios.get(
        `https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/restaurant_vendors`,
        {
          params: { restaurant_id: restaurantId },
          headers: { 'Content-Type': 'application/json' },
        }
      );

      if (response.status !== 200) {
        throw new Error('Failed to fetch restaurant vendors');
      }
      const data = response.data;
      const fetchedVendors = data.map((item) => item._vendor);
      setVendors(fetchedVendors);
    } catch (error) {
      console.error('Error fetching vendors:', error);
      setVendors([]);
    }
  };

  // Function to toggle Drawer
  const toggleDrawer = (open) => () => {
    setIsDrawerOpen(open);
  };

  // Updated Fetch Products using the new restaurant_specific_search_4 API
  const fetchProducts = async () => {
    setLoadingProducts(true);
    try {
      const userJson = localStorage.getItem('user');
      if (!userJson) {
        throw new Error('User data not found in local storage.');
      }

      const user = JSON.parse(userJson);
      const restaurantId = user.restaurant_id;
      if (!restaurantId) {
        throw new Error('Restaurant ID not found in user data.');
      }

      // Build the params
      const params = {
        restaurant_id: restaurantId,
        category_id: selectedCategory,
        subcategory_id: selectedSubcategory,
        vendor_id: selectedVendor.length > 0 ? selectedVendor : [],
        productnamefilter: searchTerm,
        limit: productsPerPage,
        offset: (currentPage - 1) * productsPerPage,
      };

      // Make the GET request to the updated API
      const response = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/restaurant_specific_search_5',
        {
          params,
          paramsSerializer: (params) => {
            // Serialize the params using qs
            return qs.stringify(params, { arrayFormat: 'repeat' });
          },
          headers: { 'Content-Type': 'application/json' },
        }
      );

      if (response.status !== 200) {
        throw new Error('Failed to fetch products');
      }

      const dataResponse = response.data;

      // Create a mapping of vendor_id to vendor data
      const vendorMap = vendors.reduce((acc, vendor) => {
        acc[vendor.id] = vendor;
        return acc;
      }, {});

      // Initialize a Set to keep track of unique product IDs
      const uniqueProductIds = new Set();

      // Extract unique products
      const products = dataResponse.items.reduce((accumulator, item) => {
        const productId = item._product.id;

        // Check if the product ID is already in the Set
        if (!uniqueProductIds.has(productId)) {
          uniqueProductIds.add(productId);

          // Attach vendor data to the product
          const productWithVendor = {
            ...item,
            _product: {
              ...item._product,
              _vendor: vendorMap[item._product.vendor_id] || null,
            },
          };

          accumulator.push(productWithVendor);
        }

        return accumulator;
      }, []);

      setAllProducts(products);
      setTotalResults(dataResponse.itemsTotal);
      setTotalPages(dataResponse.pageTotal);
    } catch (error) {
      console.error(
        'Error fetching products:',
        error.response ? error.response.data : error.message
      );
      setAllProducts([]);
      setTotalResults(0);
      setTotalPages(0);
    } finally {
      setLoadingProducts(false);
    }
  };

  // Function to fetch alternate products for a given product
  const fetchAlternateProducts = async (product) => {
    try {
      const alternates = [];
      if (
        product._product &&
        product._product._alternate_products_of_product2 &&
        Array.isArray(product._product._alternate_products_of_product2)
      ) {
        for (const altGroup of product._product._alternate_products_of_product2) {
          if (
            altGroup._alternate_products_of_altproductsgroups &&
            Array.isArray(altGroup._alternate_products_of_altproductsgroups)
          ) {
            for (const altProductData of altGroup._alternate_products_of_altproductsgroups) {
              const altProductPricing =
                altProductData._restaurant_products_pricing_of_product_test;
              if (
                altProductPricing &&
                altProductPricing._product &&
                altProductPricing._product.id &&
                altProductPricing.restaurant_id === restaurantId && // Filter by restaurant_id
                altProductPricing._product.id !== product._product.id // Exclude main product
              ) {
                const altProduct = {
                  ...altProductPricing,
                  _product: {
                    ...altProductPricing._product,
                    _vendor: vendors.find(
                      (v) => v.id === altProductPricing._product.vendor_id
                    ),
                  },
                };
                alternates.push(altProduct);
              }
            }
          }
        }
      }
      return alternates;
    } catch (error) {
      console.error('Error fetching alternate products:', error);
      return [];
    }
  };

  // Function to fetch alternates for a list of products
  const fetchAlternatesForProducts = async (products) => {
    const alternatesMap = {};

    await Promise.all(
      products.map(async (product) => {
        const alternates = await fetchAlternateProducts(product);
        alternatesMap[product._product.id] = alternates;
      })
    );

    return alternatesMap;
  };

  // Function to prepare products for rendering, including alternates
  const prepareProductsForDisplay = async () => {
    // Fetch alternates for all products
    const alternatesMap = await fetchAlternatesForProducts(sortedBaseProducts);

    const productsWithAlternates = [];

    for (const product of sortedBaseProducts) {
      if (!product._product || !product._product.id) {
        console.warn('Main product missing _product or _product.id:', product);
        continue; // Skip this product
      }

      let alternates = alternatesMap[product._product.id] || [];

      // Exclude main product from alternates
      alternates = alternates.filter(
        (alt) => alt._product.id !== product._product.id
      );

      let isBestDeal = false;
      let bestDealId = null;

      if (alternates.length > 0) {
        // Determine the best deal among the group (main product + alternates)
        const groupProducts = [product, ...alternates];
        bestDealId = determineBestDealInGroup(groupProducts, multiplier);
        isBestDeal = product._product.id === bestDealId;
      }

      // Set isAlternate and isBestDeal on alternates
      const alternatesForDisplay = alternates.map((alt) => ({
        ...alt,
        isBestDeal: alt._product.id === bestDealId,
        isAlternate: true,
        groupId: product._product.id,
      }));

      // Add main product to productsWithAlternates
      productsWithAlternates.push({
        ...product,
        isBestDeal: isBestDeal,
        isAlternate: false,
        isExpanded: !!expandedProducts[product._product.id],
        hasAlternates: alternatesForDisplay.length > 0,
        groupId: product._product.id,
        alternates: alternatesForDisplay, // Add alternates to product
      });

      // If expanded, add alternates
      if (expandedProducts[product._product.id]) {
        productsWithAlternates.push(...alternatesForDisplay);
      }
    }

    return productsWithAlternates;
  };

  // Update displayProducts when sortedBaseProducts or expandedProducts change
  useEffect(() => {
    const updateDisplayProducts = async () => {
      const productsWithAlternates = await prepareProductsForDisplay();
      setDisplayProducts(productsWithAlternates);
    };
    updateDisplayProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedBaseProducts, expandedProducts]);

  // Initial Fetches
  useEffect(() => {
    fetchCategories();
    fetchVendors();
    fetchSubcategories(selectedCategory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiplier]);

  // Fetch subcategories whenever selectedCategory changes
  useEffect(() => {
    fetchSubcategories(selectedCategory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  // Update URL when filters change
  useEffect(() => {
    const params = {};

    if (searchTerm) params.query = searchTerm;
    if (selectedCategory !== 0) params.category = selectedCategory;
    if (selectedSubcategory !== 0) params.subcategory = selectedSubcategory;
    if (selectedVendor.length > 0) params.vendor = selectedVendor.join(',');
    if (sortOption !== 'default') params.sort = sortOption;

    setSearchParams(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedCategory,
    selectedSubcategory,
    selectedVendor,
    sortOption,
    searchTerm,
  ]);

  // Clear All Filters
  const clearAllFilters = () => {
    setSelectedCategory(0);
    setSelectedSubcategory(0);
    setSelectedVendor([]);
    setSortOption('default');

    // Keep the searchTerm in the URL parameters
    const params = {};
    if (searchTerm) params.query = searchTerm;
    setSearchParams(params);
  };

  // Fetch Products whenever filters or searchTerm change
  useEffect(() => {
    setCurrentPage(1);
    fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory, selectedSubcategory, searchTerm, selectedVendor]);

  // Fetch Products when currentPage changes
  useEffect(() => {
    fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  // Handle Page Change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Handle View Mode Toggle
  const handleViewToggle = () => {
    setViewMode(viewMode === 'grid' ? 'list' : 'grid');
  };

  // Function to handle expand/collapse
  const handleExpand = (productId) => {
    setExpandedProducts((prevState) => {
      const isExpanded = !!prevState[productId];
      const newState = { ...prevState };
      if (isExpanded) {
        delete newState[productId];
      } else {
        newState[productId] = true;
      }
      return newState;
    });
  };

  // Synchronize state with URL parameters
  useEffect(() => {
    const newSearchTerm = searchParams.get('query') || '';
    const newCategory = parseInt(searchParams.get('category'), 10) || 0;
    const newSubcategory = parseInt(searchParams.get('subcategory'), 10) || 0;
    const newVendor = searchParams.get('vendor')
      ? searchParams.get('vendor').split(',').map(Number)
      : [];
    const newSort = searchParams.get('sort') || 'default';

    // Update state only if different
    if (newSearchTerm !== searchTerm) setSearchTerm(newSearchTerm);
    if (newCategory !== selectedCategory) setSelectedCategory(newCategory);
    if (newSubcategory !== selectedSubcategory)
      setSelectedSubcategory(newSubcategory);
    if (JSON.stringify(newVendor) !== JSON.stringify(selectedVendor))
      setSelectedVendor(newVendor);
    if (newSort !== sortOption) setSortOption(newSort);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  // Sorting function
  const sortProducts = (products, sortOption, multiplier) => {
    const sorted = [...products];

    switch (sortOption) {
      case 'price_low_high':
        sorted.sort(
          (a, b) =>
            a.purchase_price * multiplier - b.purchase_price * multiplier
        );
        break;
      case 'price_high_low':
        sorted.sort(
          (a, b) =>
            b.purchase_price * multiplier - a.purchase_price * multiplier
        );
        break;
      case 'ppu_low_high':
        sorted.sort((a, b) => {
          const priceA =
            a.purchase_price && a._product.product_volume > 0
              ? (a.purchase_price * multiplier) / a._product.product_volume
              : Infinity;
          const priceB =
            b.purchase_price && b._product.product_volume > 0
              ? (b.purchase_price * multiplier) / b._product.product_volume
              : Infinity;
          return priceA - priceB;
        });
        break;
      case 'ppu_high_low':
        sorted.sort((a, b) => {
          const priceA =
            a.purchase_price && a._product.product_volume > 0
              ? (a.purchase_price * multiplier) / a._product.product_volume
              : 0;
          const priceB =
            b.purchase_price && b._product.product_volume > 0
              ? (b.purchase_price * multiplier) / b._product.product_volume
              : 0;
          return priceB - priceA;
        });
        break;
      default:
        // Default sorting by product_id
        sorted.sort((a, b) => a._product.id - b._product.id);
        break;
    }

    return sorted;
  };

  // UseEffect to sort products whenever sortOption, multiplier, or allProducts changes
  useEffect(() => {
    const validProducts = allProducts.filter(
      (product) => product._product && product._product.id
    );
    const sortedProducts = sortProducts(validProducts, sortOption, multiplier);
    setSortedBaseProducts(sortedProducts);
  }, [allProducts, sortOption, multiplier]);

  // Render Products
  const renderProducts = () => {
    if (loadingProducts) {
      return (
        <Box sx={{ textAlign: 'center', margin: theme.spacing(2) }}>
          <CircularProgress />
        </Box>
      );
    }

    if (displayProducts.length === 0) {
      return (
        <Box sx={{ textAlign: 'center', margin: theme.spacing(2) }}>
          <Typography variant="h6">
            No products found. Connect your vendor accounts in settings.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/settings')}
            sx={{ marginTop: theme.spacing(2) }}
          >
            Connect Your Accounts
          </Button>
        </Box>
      );
    }

    // For grouping styles
    let currentGroupId = null;

    return viewMode === 'grid' ? (
      <Grid container spacing={2}>
        {displayProducts.map((product, index) => {
          if (!product._product || !product._product.id) {
            console.warn('Product missing _product or _product.id:', product);
            return null; // Skip this product
          }

          const isGroupStart = product.groupId !== currentGroupId;
          const isExpanded = product.isExpanded;
          const groupId = product.groupId;
          const isFirstInGroup = isGroupStart;
          const isLastInGroup =
            index === displayProducts.length - 1 ||
            displayProducts[index + 1].groupId !== groupId;

          // Update currentGroupId
          currentGroupId = product.groupId;

          return (
            <Grid
              item
              xs={6}
              sm={4}
              md={2}
              key={`${product._product.id}-${product.isAlternate ? 'alt' : 'main'}`}
              sx={{
                border: isExpanded ? `1px solid ${theme.palette.white}` : 'none',
                borderTopLeftRadius: isFirstInGroup ? theme.shape.borderRadius : 0,
                borderTopRightRadius: isFirstInGroup ? theme.shape.borderRadius : 0,
                borderBottomLeftRadius: isLastInGroup ? theme.shape.borderRadius : 0,
                borderBottomRightRadius: isLastInGroup ? theme.shape.borderRadius : 0,
                padding: isExpanded ? theme.spacing(1) : 0,
              }}
            >
              <ProductCard
                product={product}
                multiplier={multiplier}
                isExpanded={product.isExpanded}
                onExpand={() => handleExpand(product._product.id)}
                canExpand={!product.isAlternate && product.hasAlternates}
              />
            </Grid>
          );
        })}
      </Grid>
    ) : (
      // List View
      <Box>
        {/* Header Row */}
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            padding: 1,
            backgroundColor: theme.palette.background.paper,
            borderBottom: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Box sx={{ width: '10%', textAlign: 'center' }}>
            <Typography variant="subtitle2">Image</Typography>
          </Box>
          <Box sx={{ width: '40%' }}>
            <Typography variant="subtitle2">Product Info</Typography>
          </Box>
          <Box sx={{ width: '15%', textAlign: 'center' }}>
            <Typography variant="subtitle2">Add to Cart</Typography>
          </Box>
          <Box sx={{ width: '15%', textAlign: 'center' }}>
            <Typography variant="subtitle2">Price per Case</Typography>
          </Box>
          <Box sx={{ width: '20%', textAlign: 'center' }}>
            <Typography variant="subtitle2">Price per Unit</Typography>
          </Box>
        </Box>

        {/* Product List Items */}
        {displayProducts.map((product) => {
          if (!product._product || !product._product.id) {
            console.warn('Product missing _product or _product.id:', product);
            return null; // Skip this product
          }

          return (
            <ProductListItem
              key={`${product._product.id}-${product.isAlternate ? 'alt' : 'main'}`}
              product={product}
              multiplier={multiplier}
              canExpand={!product.isAlternate && product.hasAlternates}
              onExpand={handleExpand}
            />
          );
        })}
      </Box>
    );
  };

  return (
    <div>
      {/* Helmet for setting page title */}
      <Helmet>
        <title>FareFood | Shop</title>
      </Helmet>

      {/* Sticky TopNavBar */}
      <Box sx={{ position: 'sticky', top: 0, zIndex: theme.zIndex.appBar }}>
        <TopNavBar onSearch={setSearchTerm} initialSearchTerm={searchTerm} />
      </Box>

      <Container
        maxWidth="lg"
        sx={{
          paddingTop: theme.spacing(8),
        }}
      >
        {/* Main Title */}
        <Typography
          variant="h5"
          component="h2"
          sx={{
            marginTop: isMobile ? theme.spacing(1) : theme.spacing(4),
            marginBottom: isMobile ? theme.spacing(1) : theme.spacing(2),
          }}
        >
          {searchTerm ? `Results for "${searchTerm}"` : 'All Products'}
        </Typography>

        {/* On Mobile, move Result Count here */}
        {isMobile && (
          <Typography
            variant="subtitle2"
            color="text.secondary"
            sx={{ marginBottom: theme.spacing(1) }}
          >
            {`Showing ${(currentPage - 1) * productsPerPage + 1}-${
              (currentPage - 1) * productsPerPage + displayProducts.length
            } out of ${totalResults} results`}
          </Typography>
        )}

        <Grid container spacing={2} alignItems="flex-start">
          {/* Filter Sidebar (Desktop) */}
          <Grid
            item
            xs={12}
            sm={3}
            md={2}
            sx={{
              paddingTop: theme.spacing(2),
              paddingBottom: theme.spacing(2),
              display: { xs: 'none', sm: 'block' },
              height: 'calc(100vh - 64px)',
              position: 'sticky',
              top: '64px',
              overflowY: 'auto',
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <FilterPanel
              categories={categories}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              subcategories={subcategories}
              selectedSubcategory={selectedSubcategory}
              setSelectedSubcategory={setSelectedSubcategory}
              selectedVendor={selectedVendor}
              setSelectedVendor={setSelectedVendor}
              clearAllFilters={clearAllFilters}
            />
          </Grid>

          {/* Products Grid/List */}
          <Grid
            item
            xs={12}
            sm={9}
            md={10}
            sx={{
              paddingTop: isMobile ? theme.spacing(1) : theme.spacing(0),
              paddingBottom: isMobile ? theme.spacing(1) : theme.spacing(2),
            }}
          >
            {/* Result Count, Sort By, View Toggle, and Filter Icon (Mobile) */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: theme.spacing(2),
                marginTop: theme.spacing(0),
              }}
            >
              {/* Result Count */}
              {!isMobile && (
                <Typography variant="subtitle2" color="text.secondary">
                  {`Showing ${(currentPage - 1) * productsPerPage + 1}-${
                    (currentPage - 1) * productsPerPage + displayProducts.length
                  } out of ${totalResults} results`}
                </Typography>
              )}

              {/* Sort By and View Toggle */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: theme.spacing(1),
                }}
              >
                {/* Filter Icon (Mobile Only) */}
                {isMobile && (
                  <IconButton
                    onClick={toggleDrawer(true)}
                    sx={{ marginRight: theme.spacing(1) }}
                  >
                    <FilterListIcon />
                  </IconButton>
                )}

                {/* Sort By Dropdown */}
                <FormControl sx={{ minWidth: 120 }}>
                  <InputLabel
                    id="sort-select-label"
                    sx={{ fontFamily: theme.typography.fontFamily }}
                  >
                    Sort By
                  </InputLabel>
                  <Select
                    labelId="sort-select-label"
                    id="sort-select"
                    value={sortOption}
                    label="Sort By"
                    onChange={(e) => setSortOption(e.target.value)}
                    sx={{
                      fontFamily: theme.typography.fontFamily,
                    }}
                  >
                    <MenuItem value="default">Default</MenuItem>
                    <MenuItem value="price_low_high">
                      Price: Low to High
                    </MenuItem>
                    <MenuItem value="price_high_low">
                      Price: High to Low
                    </MenuItem>
                    <MenuItem value="ppu_low_high">
                      Price per Unit: Low to High
                    </MenuItem>
                    <MenuItem value="ppu_high_low">
                      Price per Unit: High to Low
                    </MenuItem>
                  </Select>
                </FormControl>

                {/* View Toggle Button */}
                {!isMobile && (
                  <IconButton onClick={handleViewToggle}>
                    {viewMode === 'grid' ? (
                      <ViewListIcon />
                    ) : (
                      <ViewModuleIcon />
                    )}
                  </IconButton>
                )}
              </Box>
            </Box>

            {/* Products */}
            {renderProducts()}

            {/* Pagination */}
            {totalPages > 1 && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: theme.spacing(4),
                  marginBottom: isMobile ? theme.spacing(8) : 0,
                  flexWrap: 'nowrap', // Prevent wrapping
                }}
              >
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="primary"
                  size={isMobile ? 'small' : 'medium'} // Adjust size for mobile
                  showFirstButton={!isMobile} // Hide first/last buttons on mobile
                  showLastButton={!isMobile}
                  siblingCount={isMobile ? 0 : 1} // Reduce buttons on mobile
                  boundaryCount={isMobile ? 1 : 2}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>

      {/* Drawer for Mobile Filters */}
      <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{
            width: 250,
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            backgroundColor: theme.palette.background.paper,
          }}
          role="presentation"
        >
          {/* Close Button */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton onClick={toggleDrawer(false)} aria-label="close filters">
              <CloseIcon />
            </IconButton>
          </Box>

          {/* FilterPanel */}
          <FilterPanel
            categories={categories}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            subcategories={subcategories}
            selectedSubcategory={selectedSubcategory}
            setSelectedSubcategory={setSelectedSubcategory}
            selectedVendor={selectedVendor}
            setSelectedVendor={setSelectedVendor}
            clearAllFilters={clearAllFilters}
          />
        </Box>
      </Drawer>
    </div>
  );
}

export default Shop;
