// src/StartReturn.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  MenuItem,
  Container,
  Paper,
  CircularProgress
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import TopNavBar from './TopNavBar';
import { useMediaQuery, useTheme } from '@mui/material'; // Import useMediaQuery and useTheme
// import ProductDetailsModal from './ProductDetailsModal'; // Ensure this path is correct

function StartReturn() {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme(); // Initialize theme

  // Extract order item details passed via state
  const { orderItem } = location.state || {};

  // Redirect to Orders if no orderItem is provided
  useEffect(() => {
    if (!orderItem) {
      navigate('/orders');
    }
  }, [orderItem, navigate]);

  const [reason, setReason] = useState('');
  const [comments, setComments] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm')); // Use useMediaQuery

  // **Added State Variables for Modal Functionality**
  // const [selectedItem, setSelectedItem] = useState(null);
  // const [openProductModal, setOpenProductModal] = useState(false);

  // Calculate total amount including sales tax
  const totalAmount = (orderItem.total_price || 0) + (orderItem.sales_tax_added || 0);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    // Construct the subject using order item details
    const subject = `Return of ${orderItem.product?.short_description || 'Product'}, quantity ${orderItem.quantity || 0}}`;

    // Construct the description using user input
    const description = comments || 'No additional comments provided.';

    // Retrieve user data from localStorage
    const user = JSON.parse(localStorage.getItem('user'));
    const userId = user?.id;
    const userName = user?.name || 'Unknown User';
    const userEmail = user?.email || 'No Email Provided';

    // Validate userId
    if (!userId) {
      setError('User information not found. Please log in again.');
      setLoading(false);
      return;
    }

    // Prepare the payload with ticket_type_id hardcoded to 1
    const payload = {
      subject,
      description,
      user_id: userId,          // Use actual user_id
      ticket_type_id: 1,        // Hardcoded to 1 as per requirement
      ticket_status_id: 0,      // Assuming 0 represents 'Open' or 'New'
      admin_notes: "",          // Can be left empty or filled as needed
      last_updated: null        // Can be set to current timestamp if required
    };

    try {
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        throw new Error('Authentication token not found. Please log in again.');
      }

      const response = await fetch('https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/help_tickets', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}` // If your API requires authentication
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to submit return request.');
      }

      // Handle the response data if needed
      const responseData = await response.json();
      console.log('Return request submitted successfully:', responseData);

      setSuccess(true);

      // Prepare Slack notification payload
      const slackPayload = {
        text: `📦 *New Return Submitted*\n
*User:* ${userName} (${userEmail})\n
*Product:* ${orderItem.product?.short_description || 'N/A'}\n
*Quantity:* ${orderItem.quantity || 0}\n
*Total Amount:* $${totalAmount.toFixed(2)}\n
*Reason:* ${reason}\n
*Comments:* ${comments || 'None'}`
      };

      // Send Slack notification
      const slackResponse = await fetch('https://xawe-auye-zrgm.n7d.xano.io/api:XF3EVVma/slack_customers', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(slackPayload)
      });

      if (!slackResponse.ok) {
        const slackErrorData = await slackResponse.json();
        console.error('Failed to send Slack notification:', slackErrorData);
        // Optionally, set an error message or log it
      } else {
        console.log('Slack notification sent successfully.');
      }

      // Navigate to the Returns page after a short delay
      setTimeout(() => {
        navigate('/returns');
      }, 2000);
    } catch (err) {
      console.error('Error submitting return request:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* Top Navigation Bar */}
      <TopNavBar title="Start Return" />

      {/* Main Content Area */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 0, // Reduced padding
          mt: theme.spacing(0), // Adjust based on TopNavBar height
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start', // Align items to the start vertically
          minHeight: 'calc(100vh - 64px)', // Adjust based on TopNavBar height (e.g., 64px)
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Container maxWidth="sm" sx={{ padding: 0, margin: 0 }}>
          <Paper elevation={0} sx={{ padding: theme.spacing(2), margin: 0, backgroundColor: theme.palette.background.paper }}>
            <Typography
              variant="h5"

              sx={{ color: 'primary.main', marginBottom: theme.spacing(2), marginTop: theme.spacing(8), }} // Set marginBottom manually
            >
              Initiate a Return
            </Typography>

            {/* Display Order Item Details */}
            <Box sx={{ marginBottom: theme.spacing(2) }}>
              <Typography variant="subtitle1">
                <strong>Product:</strong> {orderItem.product?.short_description || 'N/A'}
              </Typography>
              <Typography variant="subtitle1">
                <strong>Quantity:</strong> {orderItem.quantity || 0}
              </Typography>
              <Typography variant="subtitle1">
                <strong>Total Amount:</strong> ${totalAmount.toFixed(2)}
              </Typography>
            </Box>

            {/* Return Form */}
            <form onSubmit={handleSubmit}>
              <TextField
                select
                label="Reason for Return"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                fullWidth
                required
                margin="normal"
              >
                <MenuItem value="Defective">Defective Product</MenuItem>
                <MenuItem value="Wrong Item">Received Wrong Item</MenuItem>
                <MenuItem value="Changed Mind">Changed Mind</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </TextField>

              <TextField
                label="Additional Comments"
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                fullWidth
                multiline
                rows={4}
                margin="normal"
              />

              {error && (
                <Typography color="error" sx={{ marginTop: theme.spacing(1) }}>
                  {error}
                </Typography>
              )}

              {success && (
                <Typography color="primary" sx={{ marginTop: theme.spacing(1) }}>
                  Return request submitted successfully! Redirecting to Returns...
                </Typography>
              )}

              <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: theme.spacing(3) }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? 'Submitting...' : 'Submit Return Request'}
                </Button>
              </Box>
            </form>
          </Paper>
        </Container>
      </Box>
    </>
  );
}

export default StartReturn;
