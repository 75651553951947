// InventoryListItem.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme, alpha } from '@mui/material/styles';
import AddToCartButton from './AddToCartButton';

const InventoryListItem = ({ item, multiplier, isMobile, onClick }) => {
  const theme = useTheme();

  const productInfo = item._inventory_items_product_info;
  const hasProductId = Boolean(
    item.product_id && productInfo && productInfo.id
  );

  // Get stock and PAR levels
  const stock =
    item.on_hand !== undefined
      ? item.on_hand
      : item.On_Hand !== undefined
      ? item.On_Hand
      : null;

  const par =
    item.par !== undefined
      ? item.par
      : item.PAR !== undefined
      ? item.PAR
      : null;

  // Determine if stock is low
  const isStockLow = stock !== null && par !== null && stock <= par;

  // Set background color based on stock level
  const itemBackgroundColor = isStockLow
    ? alpha(theme.palette.warning.light, 0.2) // Light, non-intrusive color
    : 'transparent';

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: 1,
        borderBottom: `1px solid ${theme.palette.divider}`,
        '&:hover': {
          backgroundColor: alpha(theme.palette.primary.light, 0.1),
        },
        cursor: 'pointer',
        backgroundColor: itemBackgroundColor,
      }}
      onClick={() => onClick(item)}
    >
      {/* Conditionally render Image column on desktop only */}
      {!isMobile && (
        <Box sx={{ width: '10%', textAlign: 'center' }}>
          {productInfo?.Image_URL ? (
            <Box
              component="img"
              src={productInfo.Image_URL}
              alt={item.item_description}
              sx={{
                width: 60,
                height: 60,
                objectFit: 'cover',
                borderRadius: 1,
              }}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = '/path/to/default/image.png';
              }}
            />
          ) : (
            <Box
              sx={{
                width: 60,
                height: 60,
                backgroundColor: '#f0f0f0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 1,
              }}
            >
              <Typography variant="subtitle2">No Image</Typography>
            </Box>
          )}
        </Box>
      )}

      {/* Product Info */}
      <Box
        sx={{
          width: isMobile ? '40%' : '40%',
          paddingLeft: theme.spacing(1),
        }}
      >
        <Typography
          variant="body2"
          sx={{
            fontWeight: 'normal',
            color: 'black',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            cursor: 'pointer',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
        >
          {item.item_description}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ marginTop: theme.spacing(0.5) }}
        >
          {productInfo?._vendor?.Short_Name || 'N/A'}
        </Typography>
      </Box>

      {/* Add to Cart Button */}
      <Box
        sx={{
          width: isMobile ? '25%' : '15%',
          textAlign: 'center',
          marginLeft: isMobile ? theme.spacing(1) : 0,
        }}
        onClick={(e) => e.stopPropagation()}
      >
        {hasProductId && productInfo ? (
          <AddToCartButton
            product={productInfo}
            multiplier={multiplier}
            buttonText={isMobile ? 'Add' : undefined}
          />
        ) : null}
      </Box>

      {/* Stock */}
      <Box sx={{ width: '15%', textAlign: 'center' }}>
        <Typography variant="body1">
          {stock !== null ? stock : 'N/A'}
        </Typography>
      </Box>

      {/* PAR */}
      <Box sx={{ width: '15%', textAlign: 'center' }}>
        <Typography variant="body1">
          {par !== null ? par : 'N/A'}
        </Typography>
      </Box>
    </Box>
  );
};

export default InventoryListItem;
