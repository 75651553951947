import React, { useState } from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Tooltip,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import AddToCartButton from './AddToCartButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DescriptionIcon from '@mui/icons-material/Description';
import { useNavigate } from 'react-router-dom';
import { useTheme, alpha } from '@mui/material/styles';
import fallbackLogo from './assets/small-logo.avif'; // Updated import path

function ProductCard({
  product,
  multiplier,
  canExpand = true,
  isExpanded = false,
  onExpand,
}) {
  const [imageError, setImageError] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const priceData = calculatePricePerUnit(
    product.purchase_price * multiplier,
    product._product?.product_volume,
    product._product?.priced_by
  );

  const showBestDealBanner = product.isBestDeal;
  const showAlternateBanner = product.isAlternate && !showBestDealBanner;
  const hasAlternateProducts = product.hasAlternates;

  const handleExpandClick = (e) => {
    e.stopPropagation();
    if (onExpand) {
      onExpand(product._product.id);
    }
  };

  function calculatePricePerUnit(purchasePrice, productVolume, pricedBy) {
    if (purchasePrice && productVolume > 0 && pricedBy) {
      const pricePerUnit = purchasePrice / productVolume;
      return {
        pricePerUnit: `$${pricePerUnit.toFixed(2)}/${pricedBy}`,
        unitPriceValue: pricePerUnit,
      };
    }
    return null;
  }

  const handleImageError = () => {
    setImageError(true);
  };

  const cardBorderStyle =
    isExpanded || product.isAlternate
      ? `2px solid ${theme.palette.grey[500]}`
      : 'none';

  const productImage =
    imageError || !product._product?.Image_URL
      ? fallbackLogo
      : product._product?.Image_URL;

  return (
    <Card
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        position: 'relative',
        transition: 'box-shadow 0.2s',
        '&:hover': {
          boxShadow: theme.shadows[4],
          backgroundColor: alpha(theme.palette.background.paper, 0.1),
        },
        height: '100%',
        overflow: 'hidden',
        backgroundColor: showBestDealBanner
          ? alpha(theme.palette.success.light, 0.2)
          : product.isAlternate
          ? theme.palette.grey[200]
          : 'transparent',
        border: cardBorderStyle,
      }}
      onClick={() => navigate(`/product/${product._product.id}`)}
    >
      {showBestDealBanner && (
        <Box
          sx={{
            position: 'absolute',
            top: 15,
            left: -30,
            width: '120px',
            height: '24px',
            backgroundColor: '#F87906',
            transform: 'rotate(-45deg)',
            zIndex: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="caption"
            sx={{
              color: 'white',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              fontSize: '0.75rem',
              letterSpacing: '0.5px',
              lineHeight: 1,
              textAlign: 'center',
            }}
          >
            Best Deal
          </Typography>
        </Box>
      )}

      {showAlternateBanner && (
        <Box
          sx={{
            position: 'absolute',
            top: 15,
            left: -30,
            width: '120px',
            height: '24px',
            backgroundColor: theme.palette.primary.main,
            transform: 'rotate(-45deg)',
            zIndex: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="caption"
            sx={{
              color: 'white',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              fontSize: '0.75rem',
              letterSpacing: '0.5px',
              lineHeight: 1,
              textAlign: 'center',
            }}
          >
            Alternate
          </Typography>
        </Box>
      )}

      <Box sx={{ position: 'relative' }}>
        <CardMedia
          component="img"
          height="80"
          image={productImage}
          alt={product._product?.short_description || 'Product image'}
          loading="lazy"
          onError={handleImageError}
          sx={{
            objectFit: 'contain',
            backgroundColor:
              imageError || !product._product?.Image_URL ? 'white' : 'transparent',
          }}
        />

        {product._product?.FF_Catalog_Number && (
          <Box
            sx={{
              position: 'absolute',
              bottom: theme.spacing(0.5),
              left: theme.spacing(0.5),
              backgroundColor: 'white',
              padding: theme.spacing(0.125, 0.25),
              borderRadius: theme.shape.borderRadius,
            }}
          >
            <Typography
              variant="caption"
              sx={{ color: theme.palette.grey[600], fontSize: '0.6rem' }}
            >
              {product._product.FF_Catalog_Number}
            </Typography>
          </Box>
        )}

        {product._product?._vendor?.Logo_URL && (
          <Box
            sx={{
              position: 'absolute',
              top: theme.spacing(0.5),
              right: theme.spacing(0.5),
              width: 40,
              height: 40,
              borderRadius: '50%',
              backgroundColor: theme.palette.background.paper,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              boxShadow: theme.shadows[1],
            }}
          >
            <img
              src={product._product._vendor.Logo_URL}
              alt={product._product._vendor.Short_Name}
              style={{
                width: '80%',
                height: '80%',
                objectFit: 'contain',
                borderRadius: '50%',
              }}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = fallbackLogo;
              }}
            />
          </Box>
        )}

        {canExpand && hasAlternateProducts && (
          <IconButton
            onClick={handleExpandClick}
            sx={{
              position: 'absolute',
              top: theme.spacing(0.5),
              left: theme.spacing(0.5),
              zIndex: 2,
              backgroundColor: alpha(theme.palette.background.paper, 0.7),
            }}
            aria-label="show alternate products"
            size="small"
          >
            <ExpandMoreIcon
              sx={{
                transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: theme.transitions.create('transform', {
                  duration: theme.transitions.duration.shortest,
                }),
              }}
            />
          </IconButton>
        )}
      </Box>

      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          padding: theme.spacing(0.25),
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            marginBottom: theme.spacing(1),
          }}
        >
          {product._product?.brand && (
            <Typography
              variant="subtitle2"
              sx={{
                color: theme.palette.text.secondary,
                fontWeight: theme.typography.subtitle1.fontWeight,
                fontFamily: theme.typography.fontFamily,
                fontSize: '0.6rem',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                marginBottom: theme.spacing(0.1),
              }}
            >
              {product._product.brand}
            </Typography>
          )}

          <Typography
            variant="body2"
            sx={{
              fontFamily: theme.typography.fontFamily,
              fontWeight: 'normal',
              color: 'black',
              fontSize: '0.7rem',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              marginBottom: 0,
            }}
          >
            {product._product?.short_description || ''}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginTop: theme.spacing(0.2),
            marginBottom: theme.spacing(1),
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: '0.75rem',
              fontWeight: theme.typography.h6.fontWeight,
              fontFamily: theme.typography.fontFamily,
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            {product.purchase_price === 0
              ? 'MARKET'
              : `$${(product.purchase_price * multiplier).toFixed(2)}`}
            {priceData && (
              <Typography
                component="span"
                sx={{
                  fontSize: '0.65rem',
                  color: theme.palette.text.secondary,
                  marginLeft: theme.spacing(0.2),
                }}
              >
                ({priceData.pricePerUnit})
              </Typography>
            )}
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
            {isMobile ? (
              priceData?.unitPriceValue < 0.01 && (
                <Tooltip
                  title="This price seems unusually low and might be per unit rather than per case. The final price will be confirmed before purchase."
                  placement="top"
                >
                  <WarningAmberIcon
                    sx={{
                      color: theme.palette.warning.main,
                      marginLeft: theme.spacing(0.2),
                      fontSize: '0.8rem',
                    }}
                  />
                </Tooltip>
              )
            ) : (
              <>
                {priceData?.unitPriceValue < 0.01 && (
                  <Tooltip
                    title="This price seems unusually low and might be per unit rather than per case. The final price will be confirmed before purchase."
                    placement="top"
                  >
                    <WarningAmberIcon
                      sx={{
                        color: theme.palette.warning.main,
                        marginLeft: theme.spacing(0.2),
                        fontSize: '0.8rem',
                      }}
                    />
                  </Tooltip>
                )}
                {product._product?.Lead_Time > 0 && (
                  <Tooltip
                    title={`${product._product.Lead_Time} days Lead Time`}
                    placement="top"
                  >
                    <AccessTimeIcon
                      sx={{
                        color: theme.palette.info.main,
                        marginLeft: theme.spacing(0.2),
                        fontSize: '0.8rem',
                      }}
                    />
                  </Tooltip>
                )}
                {product._product?.special_notes &&
                  !/no/i.test(product._product.special_notes) && (
                    <Tooltip
                      title={product._product.special_notes}
                      placement="top"
                    >
                      <DescriptionIcon
                        sx={{
                          color: theme.palette.text.secondary,
                          marginLeft: theme.spacing(0.2),
                          fontSize: '0.8rem',
                        }}
                      />
                    </Tooltip>
                  )}
              </>
            )}
          </Box>
        </Box>

        <Box
          sx={{ marginTop: 'auto' }}
          onClick={(e) => e.stopPropagation()}
        >
          <AddToCartButton
            product={product._product}
            multiplier={multiplier}
            restaurantPrice={product.purchase_price}
          />
        </Box>
      </CardContent>
    </Card>
  );
}

export default ProductCard;
