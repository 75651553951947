import React, { useEffect, useState, useContext } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Alert,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Tooltip,
  IconButton,
  Button,
} from '@mui/material';
import { alpha } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import WarningIcon from '@mui/icons-material/Warning';
import { useNavigate } from 'react-router-dom';
import { CartContext } from './CartContext'; // Import CartContext

const MOQComponent = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [vendorsData, setVendorsData] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [loadingVendors, setLoadingVendors] = useState(false);
  const [errorVendors, setErrorVendors] = useState(null);

  const { cartItems } = useContext(CartContext); // Access cartItems from CartContext

  useEffect(() => {
    const fetchVendors = async () => {
      setLoadingVendors(true);
      try {
        // Retrieve restaurant_id from local storage
        const userJson = localStorage.getItem('user');
        const user = JSON.parse(userJson);
        const restaurantId = user.restaurant_id;

        if (!restaurantId) {
          throw new Error('Restaurant ID not found in user data.');
        }

        const response = await fetch(
          `https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/restaurant_vendors?restaurant_id=${restaurantId}`
        );
        if (!response.ok) {
          throw new Error('Failed to fetch vendors data.');
        }
        const data = await response.json();
        setVendorsData(data);
      } catch (error) {
        setErrorVendors(error.message);
      } finally {
        setLoadingVendors(false);
      }
    };

    fetchVendors();
  }, []);

  useEffect(() => {
    if (vendorsData.length > 0) {
      // Map the fetched vendor data to the format needed for rendering
      const newVendors = vendorsData.map((vendorData) => {
        let casesBought = 0;
        let dollarsBought = 0;

        cartItems.forEach((item) => {
          const itemVendorId = item._product?._vendor?.id;
          if (itemVendorId === vendorData.vendor_id) {
            casesBought += item.quantity || 0;
            dollarsBought += item.total_price || 0;
          }
        });

        return {
          id: vendorData.vendor_id,
          name: vendorData._vendor.Short_Name,
          logoUrl: vendorData._vendor.Logo_URL,
          casesBought,
          dollarsBought,
          casesLimit: vendorData.MOQ_Cases,
          dollarsLimit: vendorData.MOQ_Dollar,
          established: vendorData.established || true,
        };
      });
      setVendors(newVendors);
    }
  }, [vendorsData, cartItems]);

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.background.paper,
        boxShadow: 0,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(2),
        minHeight: '300px',
        border: '1px solid #e0e0e0',
      }}
    >
      {loadingVendors ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '250px',
          }}
        >
          <CircularProgress />
        </Box>
      ) : errorVendors ? (
        <Alert severity="error">
          {`There was an error fetching your MOQ Tracker data: ${errorVendors}. Please try again later.`}
        </Alert>
      ) : vendors.length > 0 ? (
        <CardContent sx={{ padding: theme.spacing(1) }}>
          <Typography
            variant="h5"
            component="div"
            sx={{
              fontWeight: theme.typography.h5.fontWeight,
              textTransform: 'capitalize',
              marginBottom: theme.spacing(1),
            }}
          >
            Order Minimums Tracker
          </Typography>
          <Grid container spacing={1} alignItems="stretch">
            {vendors.map((vendor) => (
              <Grid item xs={6} key={vendor.id}>
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    border: `1px solid ${theme.palette.divider}`,
                  }}
                >
                  <CardContent
                    sx={{
                      flexGrow: 1,
                      padding: theme.spacing(1),
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    {/* Header with logo and vendor name */}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box
                        sx={{
                          width: 40,
                          height: 40,
                          marginRight: theme.spacing(1),
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor: vendor.logoUrl
                            ? 'transparent'
                            : theme.palette.grey[300],
                          borderRadius: '50%',
                          overflow: 'hidden',
                        }}
                      >
                        {vendor.logoUrl ? (
                          <img
                            src={vendor.logoUrl}
                            alt={`${vendor.name} Logo`}
                            style={{ width: '100%', height: '100%' }}
                          />
                        ) : (
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontSize: '0.9rem',
                              textAlign: 'center',
                            }}
                          >
                            {vendor.name.charAt(0)}
                          </Typography>
                        )}
                      </Box>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontSize: '0.9rem', flexGrow: 1 }}
                      >
                        {vendor.name}
                      </Typography>
                      {!vendor.established && (
                        <Tooltip title={`${vendor.name} is not established`}>
                          <IconButton color="warning" sx={{ padding: 0 }}>
                            <WarningIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>

                    {/* Spacer to push progress bars to bottom */}
                    <Box sx={{ flexGrow: 1 }}></Box>

                    {/* Progress bars */}
                    <Box>
                      {vendor.casesLimit > 0 && (
                        <Box sx={{ marginTop: theme.spacing(1) }}>
                          <Typography
                            variant="body2"
                            sx={{
                              fontFamily: theme.typography.fontFamily,
                              fontSize: '0.8rem',
                            }}
                          >
                            {`${vendor.casesBought}/${vendor.casesLimit} Cases`}
                          </Typography>
                          <LinearProgress
                            variant="determinate"
                            value={Math.min(
                              (vendor.casesBought / vendor.casesLimit) * 100,
                              100
                            )}
                            sx={{
                              height: 6,
                              borderRadius: 3,
                              marginY: theme.spacing(0.5),
                            }}
                          />
                          {vendor.casesBought >= vendor.casesLimit && (
                            <Typography
                              variant="caption"
                              color="primary"
                              sx={{
                                fontFamily: theme.typography.fontFamily,
                                fontSize: '0.7rem',
                              }}
                            >
                              Minimum met
                            </Typography>
                          )}
                        </Box>
                      )}
                      {vendor.dollarsLimit > 0 && (
                        <Box sx={{ marginTop: theme.spacing(1) }}>
                          <Typography
                            variant="body2"
                            sx={{
                              fontFamily: theme.typography.fontFamily,
                              fontSize: '0.8rem',
                            }}
                          >
                            ${vendor.dollarsBought.toFixed(2)} / $
                            {vendor.dollarsLimit}
                          </Typography>
                          <LinearProgress
                            variant="determinate"
                            value={Math.min(
                              (vendor.dollarsBought / vendor.dollarsLimit) * 100,
                              100
                            )}
                            sx={{
                              height: 6,
                              borderRadius: 3,
                              marginY: theme.spacing(0.5),
                            }}
                          />
                          {vendor.dollarsBought >= vendor.dollarsLimit && (
                            <Typography
                              variant="caption"
                              color="primary"
                              sx={{
                                fontFamily: theme.typography.fontFamily,
                                fontSize: '0.7rem',
                              }}
                            >
                              Minimum met
                            </Typography>
                          )}
                        </Box>
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      ) : (
        // Customized Empty State for MOQ Tracker when no vendors
        <Box
          sx={{
            textAlign: 'center',
            padding: theme.spacing(4),
            backgroundColor: alpha(theme.palette.info.light, 0.1),
            borderRadius: theme.shape.borderRadius,
          }}
        >
          <Typography variant="h6" gutterBottom>
            No MOQ to Track
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Start placing an order to track your order minimums. Your spend and
            order minimums will appear here once you begin ordering.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/shop')}
            sx={{
              marginTop: theme.spacing(2),
              textTransform: 'none',
            }}
          >
            Start an Order
          </Button>
        </Box>
      )}
    </Card>
  );
};

export default MOQComponent;
